<template>
  <div class="ManagerLogistics container-fluid">
    <div v-if="Object.keys(guest).length === 0" class="d-flex justify-center align-content-center white py-12">
      <v-progress-circular indeterminate color="congrex-primary" size="64" />
    </div>
    <template v-else>
      <base-banner
        :imageUrl="require('@/assets/manager-background.png')"
        :title="`${findField('TITLE')} ${guest.firstName} ${guest.lastName}`"
        :returnRoute="backLink"
      />
      <base-horizontal-time-line :title="$t`following-hcp`" :items="timeLineValues" />
      <div class="ManagerLogistics-cards">
        <base-preview-card
          v-if="hasRight($const.right.exportSolicitation) && guest.solicitation"
          @clickPreview="displaySolicitation"
          :file="require('@/assets/preview-solicitation.png')"
        />
        <base-preview-card
          v-if="hasRight($const.right.exportHcpSignature) && guest.healthCareProfessional"
          @clickPreview="displayEmargement"
          :file="require('@/assets/preview-emargement.png')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import _get from 'lodash/get'

import { dateRange } from '@/services/date'
import { GET_GUEST } from '@/graphql/Guest/GetGuest'
import axios from 'axios'

export default {
  name: 'ManagerLogistics',

  data() {
    return {
      guest: {}
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    confirmationDateFormated() {
      if (!_get(this.guest, 'solicitation.confirmationDate')) return ''

      return moment(this.guest.solicitation.confirmationDate).format('DD MMMM YYYY')
    },
    backLink() {
      return {
        name: 'CongressTabs',
        params: { gatheringUuid: this.$route.params.gatheringUuid },
        query: { tab: 'logistic' }
      }
    },
    signedPresence() {
      return this.guest.healthCareProfessional ? this.guest.healthCareProfessional.hcpGatheringDates.length : 0
    },
    timeLineValues() {
      if (!this.guest) return []

      const now = new Date()
      now.setHours(20)

      return [
        {
          id: '1',
          topLabel: this.confirmationDateFormated,
          bottomLabel: this.$t`accepted-solicitation`,
          completed: Boolean(this.confirmationDateFormated)
        },
        {
          id: '2',
          topLabel: '',
          bottomLabel: this.guest.transportStatus,
          completed: Boolean(this.guest.transportStatus)
        },
        {
          id: '3',
          topLabel: this.transportWaytoDate,
          bottomLabel: this.$t('manager-logistics.wayto-label', {
            city: this.$get(this.guest, 'event.gathering.city', '--'),
            time:
              this.findField('TRANSPORT_WORKFLOW_INFOS_WAYTO_HOUR') &&
              this.findField('TRANSPORT_WORKFLOW_INFOS_WAYTO_HOUR')
                .replace(/:/, 'h')
                .slice(0, 5)
          }),
          logo: this.getLogo('TRANSPORT_WORKFLOW_INFOS_WAYBACK_HOUR', this.guest.transportWaytoType),
          completed: now >= new Date(this.guest.transportWaytoDate)
        },
        {
          id: '4',
          topLabel: '',
          bottomLabel: this.$t('presence-ratio', { value: `${this.signedPresence}/${this.totalPresence}` }),
          completed: this.signedPresence === this.totalPresence
        },
        {
          id: '5',
          topLabel: this.transportWaybackDate,
          bottomLabel: this.$t('manager-logistics.wayback-label', {
            city: this.$get(this.guest, 'event.gathering.city', '--'),
            time:
              this.findField('TRANSPORT_WORKFLOW_INFOS_WAYBACK_HOUR') &&
              this.findField('TRANSPORT_WORKFLOW_INFOS_WAYBACK_HOUR')
                .replace(/:/, 'h')
                .slice(0, 5)
          }),
          logo: this.getLogo('TRANSPORT_WORKFLOW_INFOS_WAYTO_HOUR', this.guest.transportWaytoType),
          completed: now >= new Date(this.guest.transportWaybackDate)
        }
      ]
    },
    totalPresence() {
      return dateRange(this.guest.event.gathering.beginDate, this.guest.event.gathering.endDate).length
    },
    transportWaybackDate() {
      if (!this.guest.transportWaybackDate) return null
      return moment(this.guest.transportWaybackDate).format('DD MMMM YYYY')
    },
    transportWaytoDate() {
      if (!this.guest.transportWaytoDate) return null
      return moment(this.guest.transportWaytoDate).format('DD MMMM YYYY')
    }
  },

  apollo: {
    guest: {
      query: GET_GUEST,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid,
          eveniumEventId: this.$route.params.eveniumEventId,
          eveniumGuestId: this.$route.params.eveniumGuestId
        }
      }
    }
  },

  methods: {
    async displayEmargement() {
      const { data } = await axios.post(
        `${this.$baseUrl}/congress/export-signatures-pdf`,
        {
          gatheringUuid: this.$route.params.gatheringUuid,
          hcps: [this.guest.healthCareProfessional.healthCareProfessionalUuid]
        },
        {
          responseType: 'blob',
          headers: {
            'x-impersonificated-user': this.$store.state.impersonificatedUser
          }
        }
      )

      const url = URL.createObjectURL(data)
      open(url, '_blank')
    },
    async displaySolicitation() {
      const { data } = await axios.post(
        `${this.$baseUrl}/solicitation/export-pdf`,
        {
          solicitationUuid: this.guest.solicitation.solicitationUuid
        },
        {
          responseType: 'blob',
          headers: {
            'x-impersonificated-user': this.$store.state.impersonificatedUser
          }
        }
      )

      const url = URL.createObjectURL(data)
      open(url, '_blank')
    },
    findField(fieldName) {
      if (!this.guest.fields) return ''

      const field = this.guest.fields.find(field => field.name === fieldName)

      return field ? field.value : ''
    },
    getLogo(fieldName, transportType) {
      switch (transportType) {
        case 'Avion':
          if (fieldName === 'TRANSPORT_WORKFLOW_INFOS_WAYTO_HOUR') return 'mdi-airplane-takeoff'
          return 'mdi-airplane-landing'
        case 'Train':
          return 'mdi-train'
        default:
          return null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ManagerLogistics-returnLink {
  position: absolute;
  top: 50%;
  left: 35px;
  font-size: 35px;
  color: white;
  transform: translateY(-50%);
}

.ManagerLogistics-timeLine {
  background: white;
  height: 223px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.04);
}

.ManagerLogistics-cards {
  padding: 88px 16px 42px 16px;
  text-align: left;
}
</style>
