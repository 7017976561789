<template>
  <v-dialog @click:outside="$emit('close')" max-width="700px" :value="open">
    <v-card>
      <!-- Close button -->
      <v-btn top right icon class="v-btn--absolute" @click.stop="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-title>{{ $t('import-participations-dialog.title') }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-row>
            <v-col :cols="hasAttendees ? 12 : 9" justify="center">
              <span>{{ text }}</span>
            </v-col>
            <v-col cols="3" v-if="!hasAttendees">
              <v-btn @click="$emit('download')" color="primary">
                {{ $t('import-participations-dialog.empty-attendee.button') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="!hasAttendees">
            <v-col cols="12">
              <div class="caption font-italic text-center">
                {{ $t('import-participations-dialog.empty-attendee.subline') }}
              </div>
            </v-col>
          </v-row>
        </v-row>

        <v-row>
          <v-col>
            <span>{{ subline }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="text-center v-card-dashed" elevation="0" v-if="!file">
              <input
                type="file"
                ref="file"
                v-show="false"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                @change="handleFile"
              />

              <v-btn
                elevation="0"
                fab
                color="grey darken-2"
                outlined
                width="84"
                height="84"
                @click.stop="triggerImport"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card>

            <v-card v-else>
              <v-row justify="center">
                <v-col align="center">
                  <a :href="fileDownloadUrl" target="_blank" download>
                    <v-icon large>mdi-microsoft-excel</v-icon>
                  </a>
                </v-col>
              </v-row>

              <v-card-actions class="py-3 px-4">
                <span class="caption grey--text text--darken-2--text font-weight-bold mr-4">
                  {{ file.filename }}
                </span>

                <a :href="fileDownloadUrl" download>
                  <v-icon color="primary">mdi-download</v-icon>
                </a>
              </v-card-actions>

              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    absolute
                    class="edit mt-5"
                    color="grey lighten-2 white--text"
                    elevation="0"
                    fab
                    top
                    right
                    x-small
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="deleteFile">delete</v-list-item>
                </v-list>
              </v-menu>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="showConfirmImport">{{ $t('import-participations-dialog.button') }}</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Confirmation dialog -->
    <v-dialog v-model="confirmImport" max-width="400px">
      <v-card>
        <v-card-title>{{ $t('import-participations-dialog.confirmation-dialog.title') }}</v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="confirmImport = false" color="error">{{
            $t('import-participations-dialog.confirmation-dialog.cancel')
          }}</v-btn>
          <v-btn @click="sendFile" color="success">{{
            $t('import-participations-dialog.confirmation-dialog.confirm')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Error dialog -->
    <v-dialog :value="errorImport.length > 0" @input="errorImport = []" max-wdith="700px">
      <v-card>
        <v-card-title>{{ $t('import-participations-dialog.error-dialog.title') }}</v-card-title>
        <v-card-text>
          <p
            class="error--text"
            v-html="
              $t('import-participations-dialog.error-dialog.text', {
                invitationStatus,
                participationStatus
              })
            "
          />
          <ul>
            <li v-for="error in errorImport" :key="error" class="error--text">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="errorImport = []">{{
            $t('import-participations-dialog.error-dialog.button')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: 'EventAttendeeImportDialog',

  data() {
    return {
      confirmImport: false,
      errorImport: [],
      file: null,
      fileDownloadUrl: null
    }
  },

  props: {
    open: Boolean,
    gatheringUuid: {
      type: String,
      required: true
    },
    attendees: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    text() {
      return this.hasAttendees
        ? this.$t('import-participations-dialog.with-attendee.text')
        : this.$t('import-participations-dialog.empty-attendee.text')
    },
    subline() {
      return this.hasAttendees
        ? this.$t('import-participations-dialog.with-attendee.second-text')
        : this.$t('import-participations-dialog.empty-attendee.second-text')
    },
    hasAttendees() {
      return this.attendees.length > 0
    },
    invitationStatus() {
      return [
        this.$t('imported-attendee.status.confirmed'),
        this.$t('imported-attendee.status.cancel'),
        this.$t('imported-attendee.status.waiting-list'),
        this.$t('imported-attendee.status.unanswered')
      ].join(', ')
    },
    participationStatus() {
      return [this.$t('imported-attendee.status.present'), this.$t('imported-attendee.status.no_show')].join(', ')
    }
  },

  methods: {
    async handleFile(event) {
      const [file] = event.target.files
      this.file = file
      this.fileDownloadUrl = URL.createObjectURL(file)

      // Reset file input
      event.target.type = ''
      event.target.type = 'file'
    },
    async sendFile() {
      try {
        const formData = new FormData()
        formData.append('file', this.file)

        const response = await axios.post(`/gathering/participation/${this.gatheringUuid}/import`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-impersonificated-user': this.$store.state.impersonificatedUser
          }
        })

        if (response.data.success) {
          this.$emit('refresh-attendees')
          this.$emit('close')
          this.file = null
          this.fileDownloadUrl = null
        } else {
          this.errorImport = response.data.lineErrors
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.confirmImport = false
      }
    },
    triggerImport() {
      this.$refs.file.click()
    },
    deleteFile() {
      this.file = null
      this.fileDownloadUrl = null
    },
    showConfirmImport() {
      this.confirmImport = true
    }
  },

  watch: {
    open(isVisible) {
      if (!isVisible) {
        this.file = null
        this.fileDownloadUrl = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.caption {
  width: 200px;
  height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-card-dashed {
  border: dashed 1px #979797;
  border-radius: 4px;

  button {
    margin: 20px 0;
    border: dashed 1px #979797;
  }
}
</style>
