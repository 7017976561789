/* eslint-disable graphql/template-strings */
import gql from 'graphql-tag'

export const GET_SOLICITATIONS_BY_GATHERING_ID = gql`
  query Gathering($gatheringUuid: GUID!) {
    gathering(gatheringUuid: $gatheringUuid) {
      gatheringUuid
      name
      beginDate
      gatheringBusinessUnits {
        gatheringBusinessUnitUuid
        businessUnitUuid
        solicitationEndDate
      }
      congress {
        congressUuid
        maximalQuota
        solicitations {
          businessUnitUuid
          solicitationUuid
          zone {
            zoneUuid
            businessUnitUuid
            label
          }
          status
          updatedAt
          healthCareProfessional {
            healthCareProfessionalUuid
            city
            firstName
            isMslTarget
            lastName
            mslResponsibleUuid
            specialty
            targetBbu
            targetMs
            targetSma
            targetKme
            targetAd
            title
            type
          }
        }
      }
      endDate
      gatheringType
      isVirtual
      location
      status
    }
  }
`
