<template>
  <v-card class="EventCard">
    <event-card-head :event="event" @click="openGatheringInformations" v-cy="$cy.congrex.list.card(event.name)" />

    <div class="mb-2">
      <!-- Congress -->
      <div v-if="isCongress">
        <!-- Business units -->
        <v-tabs v-if="gatheringBusinessUnits" v-model="tab">
          <v-tabs-slider />

          <v-tab v-for="gbu of gatheringBusinessUnits" :key="gbu.gatheringBusinessUnitUuid">
            <mybb-text weight="semi-bold">{{ gbu.businessUnit.externalId }}</mybb-text>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="gbu of gatheringBusinessUnits" :key="gbu.gatheringBusinessUnitUuid">
            <event-card-business-unit :event="event" :gathering-business-unit="gbu" />
          </v-tab-item>
        </v-tabs-items>
      </div>

      <!-- Biogen Event -->
      <div v-else-if="isBiogenEvent" class="px-3 mt-8">
        <div class="d-flex justify-center">
          <v-progress-linear :value="biogenEventPercentage" height="6" class="mt-2" rounded top />
          <mybb-text size="12" class="ml-2">
            {{ biogenEventParticipantCount }}/{{ event.biogenEvent.availablePlace }}
          </mybb-text>
        </div>
      </div>
    </div>

    <div class="d-flex flex-grow-1" />

    <!-- Subscription -->
    <div
      v-if="canSubscribe || isSubscribed || showCreateSolicitation"
      class="pa-2 d-flex justify-end text-right align-center"
    >
      <v-btn v-if="canSubscribe" color="primary" outlined @click="showSubscriptionForm = true">
        {{ $t('subscribe') }}
      </v-btn>
      <v-btn
        v-if="isSubscribed"
        color="congrex-primary"
        @click="showSubscriptionForm = true"
        dark
        dense
        depressed
        small
      >
        {{ $t('edit-register') }}
      </v-btn>
      <mybb-text v-else-if="refusedSubscription" color="mybb-grey" weight="semi-bold">
        {{ $t('refused-subscription') }}
      </mybb-text>

      <v-btn
        v-if="showCreateSolicitation"
        @click="openDialogHcpList"
        :class="{ disabled: disableCreateSolicitation, 'ml-3': true }"
        :disabled="disableCreateSolicitation"
        class="d-block add-solicitation"
        color="congrex-primary"
        :dark="!disableCreateSolicitation"
        depressed
        fab
        small
      >
        <v-icon>mdi-account-plus-outline</v-icon>
      </v-btn>
    </div>

    <subscription-form-dialog
      v-model="showSubscriptionForm"
      :gathering-uuid="event.gatheringUuid"
      :participant-uuid="currentParticipant ? currentParticipant.participantUuid : null"
      @refresh="$emit('refresh')"
    />

    <business-unit-picker-modal
      v-if="showCreateSolicitation"
      v-model="showBuPicker"
      :gathering-uuid="event.gatheringUuid"
      @submit="openDialogFromBuChoice"
    />
  </v-card>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import Categories from 'mybb-categories'

import EventCardHead from './EventCardHead'
import EventCardBusinessUnit from './EventCardBusinessUnit'
import SubscriptionFormDialog from '../participant/SubscriptionFormDialog'
import BusinessUnitPickerModal from '../dialogs/BusinessUnitPickerModal'

export default {
  name: 'EventCard',
  components: { EventCardHead, EventCardBusinessUnit, SubscriptionFormDialog, BusinessUnitPickerModal },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tab: null,
      showSubscriptionForm: false,
      showBuPicker: false
    }
  },
  computed: {
    ...mapState({ user: 'myUser' }),
    ...mapGetters(['hasRight', 'userBusinessUnitUuid']),
    gatheringBusinessUnits() {
      if (!Array.isArray(this.event.gatheringBusinessUnits) || this.event.gatheringBusinessUnits.length <= 0)
        return null

      const mainBuUuid = this.event.mainBusinessUnitUuid

      return Array.from(this.event.gatheringBusinessUnits).sort((gbu1, gbu2) => {
        if (gbu1.businessUnitUuid === mainBuUuid) return -1
        if (gbu2.businessUnitUuid === mainBuUuid) return 1
        return 0
      })
    },
    isCongress() {
      return Boolean(this.event.congress)
    },
    isBiogenEvent() {
      return Boolean(this.event.biogenEvent)
    },
    biogenEventPercentage() {
      if (!this.isBiogenEvent) return 0

      return (this.biogenEventParticipantCount / this.event.biogenEvent.availablePlace) * 100
    },
    biogenEventParticipantCount() {
      if (!this.isBiogenEvent || !this.event.participants) return 0

      return this.event.participants.reduce((acc, participant) => {
        if (
          participant.invitationStatus === this.$const.invitationStatus.confirmed &&
          participant.healthCareProfessionalUuid !== null
        ) {
          return acc + 1
        }

        return acc
      }, 0)
    },
    showCreateSolicitation() {
      if (!this.isCongress) return false
      if (!this.hasRight(this.$const.right.collectSolicitation)) return false

      const { PUBLISHED, PUBLISHED_FRONT } = this.$const.gatheringStatus

      if (![PUBLISHED, PUBLISHED_FRONT].includes(this.event.status)) return false

      if (this.userBusinessUnitUuid) {
        const businessUnitUuids = this.gatheringBusinessUnits.map(gbu => gbu.businessUnitUuid)

        return this.userBusinessUnitUuid && businessUnitUuids.includes(this.userBusinessUnitUuid)
      } else {
        return true
      }
    },
    disableCreateSolicitation() {
      const now = new Date()

      if (this.userBusinessUnitUuid) {
        const gatheringBusinessUnit = this.event.gatheringBusinessUnits.find(
          gbu => gbu.businessUnitUuid === this.userBusinessUnitUuid
        )

        if (!gatheringBusinessUnit) return true

        const solicitationEndDate = gatheringBusinessUnit.solicitationEndDate

        const endDate = new Date(solicitationEndDate)

        return !this.$moment(now).isSameOrBefore(endDate, 'day')
      } else {
        return this.event.gatheringBusinessUnits.every(gbu => {
          const endDate = new Date(gbu.solicitationEndDate)

          return !this.$moment(now).isSameOrBefore(endDate, 'day')
        })
      }
    },
    currentParticipant() {
      return this.event.participants.find(participant => participant.userUuid === this.user.userUuid)
    },
    canSubscribe() {
      if (!this.event || !this.currentParticipant) return false

      const { PUBLISHED, PUBLISHED_FRONT } = this.$const.gatheringStatus

      return (
        [PUBLISHED, PUBLISHED_FRONT].includes(this.event.status) &&
        this.currentParticipant.invitationStatus === this.$const.invitationStatus.pending &&
        this.currentParticipant.category !== Categories.noCategoryForVersion(this.event.categoryVersion).id
      )
    },
    isSubscribed() {
      return (
        this.currentParticipant && this.currentParticipant.invitationStatus === this.$const.invitationStatus.confirmed
      )
    },
    refusedSubscription() {
      return (
        this.currentParticipant && this.currentParticipant.invitationStatus === this.$const.invitationStatus.refused
      )
    }
  },
  methods: {
    ...mapMutations({
      setDialogHcpList: 'SET_DIALOG_HCP_LIST'
    }),
    openDialogHcpList() {
      if (!this.userBusinessUnitUuid) {
        const now = new Date()
        const year = now.getFullYear()
        const month = now.getMonth()
        const day = now.getDate()

        const gatheringBusinessUnit = this.event.gatheringBusinessUnits.filter(gbu => {
          const endDate = new Date(gbu.solicitationEndDate)

          return !(endDate.getFullYear() >= year && endDate.getMonth() >= month && endDate.getDate() > day)
        })
        if (gatheringBusinessUnit.length === 1) {
          this.openDialogFromBuChoice(gatheringBusinessUnit[0].businessUnitUuid)
          return
        }

        this.showBuPicker = true
        return
      }

      this.openDialogFromBuChoice()
    },
    openDialogFromBuChoice(businessUnitUuid) {
      this.setDialogHcpList({
        beginDate: this.event.beginDate,
        businessUnitUuid,
        congressUuid: this.event.congress.congressUuid,
        display: true,
        endDate: this.event.endDate,
        gatheringUuid: this.event.gatheringUuid,
        name: this.event.name,
        status: this.event.status
      })
    },
    openGatheringInformations() {
      const route = {
        name: 'CongressTabs',
        params: { gatheringUuid: this.event.gatheringUuid }
      }

      if (this.event.status === this.$const.gatheringStatus.TO_VALIDATE) {
        route.query = { tab: 'generalInformations' }
      }

      this.$router.push(route)
    }
  }
}
</script>

<style lang="scss">
.EventCard {
  position: relative;
  overflow: hidden;
  z-index: 0;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 355px;
}
</style>
