<template>
  <div class="HcpHistorical">
    <LoaderTab v-if="!healthCareProfessional" />
    <base-banner
      v-else
      :imageUrl="require('@/assets/solicitation-background.jpg')"
      :loading="hcpLoading"
      :returnRoute="{ name: 'SearchHcp' }"
      :title="`${healthCareProfessional.lastName} ${healthCareProfessional.firstName}`"
      :subTitle="$t(`specialtyValues.${healthCareProfessional.specialty}`)"
    >
      <div class="mt-3 body-2">
        <div>
          <div class="d-inline-flex align-center mr-4">
            <v-icon color="white" left small>mdi-email-outline</v-icon
            ><span>{{ healthCareProfessional.emailAddress || '- -' }}</span>
          </div>
          <div class="d-inline-flex align-center">
            <v-icon color="white" left small>mdi-phone</v-icon><span>{{ hcpPhoneNumber }}</span>
          </div>
        </div>
        <div class="mt-1">
          <div class="d-inline-flex align-center mr-4">
            <v-icon color="white" left small>mdi-hospital-building</v-icon>
            <span>{{ healthCareProfessional.healthCareOrganization }}</span>
          </div>
          <div class="d-inline-flex align-center">
            <v-icon color="white" left small>mdi-map-marker</v-icon><span>{{ healthCareProfessional.city }}</span>
          </div>
        </div>
      </div>
    </base-banner>

    <v-tabs v-model="activeTab" grow>
      <v-tab class="text-none">{{ $t('hcp-historical.future-event-tab-name') }}</v-tab>
      <v-tab class="text-none">{{ $t('hcp-historical.past-event-tab-name') }}</v-tab>

      <v-tab-item>
        <HcpHistoricalTab :items="afterHistoricalsFormatted" :loading="tabAfterLoading" v-model="afterFilters" />
      </v-tab-item>
      <v-tab-item>
        <HcpHistoricalTab :items="beforeHistoricalsFormatted" :loading="tabBeforeLoading" v-model="beforeFilters" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import HcpHistoricalTab from '@/components/congrex/hcpHistoricalTabs/HcpHistoricalTab'
import { GET_HCP_HISTORICAL, GET_HCP_FOR_HISTORICAL } from '@/graphql/Hcp/GetHcp'
import { dateRange } from '@/services/date'
import LoaderTab from '@/components/congrex/tabs/LoaderTab'
import { GET_GUESTS_BY_EVENT_FOR_TRANSPORT_STATUS } from '@/graphql/Guests/GetGuests'

export default {
  name: 'HcpHistorical',
  components: { LoaderTab, HcpHistoricalTab },

  data() {
    return {
      activeTab: 0,
      afterFilters: {
        gatheringTypes: [this.$const.gatheringType.BIOGEN_EVENT, this.$const.gatheringType.CONGRESS]
      },
      beforeFilters: {
        gatheringTypes: [this.$const.gatheringType.BIOGEN_EVENT, this.$const.gatheringType.CONGRESS]
      },
      hcpLoading: false,
      healthCareProfessional: null,
      tabAfterLoading: false,
      tabBeforeLoading: false
    }
  },

  computed: {
    afterHistoricalsFormatted() {
      if (!this.afterHistoricals || !this.healthCareProfessional) return []

      return this.afterHistoricals.map(event => {
        return {
          beginDate: event.gathering ? this.$moment(event.gathering.beginDate).format('DD MMMM YYYY') : '- -',
          city: this.$get(event, 'gathering.city'),
          countSignature: this.getPresenceRatio(event),
          eveniumEventId: this.$get(event, 'eveniumEvent.id'),
          eveniumGuestId: event.eveniumGuestId,
          eventName: this.$get(event, 'gathering.name'),
          eventType: this.$get(event, 'gathering.gatheringType'),
          gatheringUuid: this.$get(event, 'gathering.gatheringUuid'),
          invitation: this.$get(event, 'eveniumEvent.contactStatus')
            ? {
                text: this.$t(`guest-status.${event.eveniumEvent.contactStatus}`),
                class: this.getGuestStatusColor(event.eveniumEvent.contactStatus)
              }
            : { text: '- -', color: '' },
          postStatus: event.postStatus,
          solicitation:
            event.gathering.gatheringType === this.$const.gatheringType.CONGRESS && event.solicitationStatus
              ? this.$t(`solicitation-status.${event.solicitationStatus}`)
              : null,
          solicitationUuid: this.$get(event, 'solicitation.solicitationUuid'),
          transportStatus: event.transportStatus
        }
      })
    },
    beforeHistoricalsFormatted() {
      if (!this.beforeHistoricals) return []

      return this.beforeHistoricals.map(event => {
        return {
          beginDate: event.gathering ? this.$moment(event.gathering.beginDate).format('DD MMMM YYYY') : '- -',
          city: this.$get(event, 'gathering.city'),
          countSignature: this.getPresenceRatio(event),
          eveniumEventId: this.$get(event, 'eveniumEvent.id'),
          eveniumGuestId: event.eveniumGuestId,
          eventName: this.$get(event, 'gathering.name'),
          eventType: this.$get(event, 'gathering.gatheringType'),
          gatheringUuid: this.$get(event, 'gathering.gatheringUuid'),
          invitation: this.$get(event, 'eveniumEvent.contactStatus')
            ? {
                text: this.$t(`guest-status.${event.eveniumEvent.contactStatus}`),
                class: this.getGuestStatusColor(event.eveniumEvent.contactStatus)
              }
            : { text: '- -', color: '' },
          postStatus: event.postStatus,
          solicitation:
            event.gathering.gatheringType === this.$const.gatheringType.CONGRESS && event.solicitationStatus
              ? this.$t(`solicitation-status.${event.solicitationStatus}`)
              : null,
          solicitationUuid: this.$get(event, 'solicitation.solicitationUuid'),
          transportStatus: event.transportStatus
        }
      })
    },
    hcpPhoneNumber() {
      return this.$get(this.healthCareProfessional, 'solicitations[0].phoneNumber', '')
    }
  },

  apollo: {
    healthCareProfessional: {
      query: GET_HCP_FOR_HISTORICAL,
      variables() {
        this.hcpLoading = true
        return {
          hcpUuid: this.$route.params.hcpUuid
        }
      },
      result() {
        this.hcpLoading = false
      }
    },
    afterHistoricals: {
      query: GET_HCP_HISTORICAL,
      variables() {
        this.tabAfterLoading = true

        return {
          filterDate: new Date(),
          gatheringTypes: this.afterFilters.gatheringTypes,
          getAfter: true,
          hcpUuid: this.$route.params.hcpUuid
        }
      },
      skip() {
        return this.activeTab !== 0
      },
      update: ({ healthCareProfessional }) => healthCareProfessional.eventParticipations,
      result({ data }) {
        this.tabAfterLoading = false

        data.healthCareProfessional.eventParticipations.forEach(event => {
          this.$apollo
            .query({
              query: GET_GUESTS_BY_EVENT_FOR_TRANSPORT_STATUS,
              variables: {
                eveniumEventId: event.eveniumEvent.id,
                limit: 10000,
                status: this.$const.guestStatus.CONFIRMED
              }
            })
            .then(res => {
              if (res.data) this.updateEvent(true, event.eveniumEvent.id, res.data.eveniumGuests.guests)
            })
        })
      }
    },
    beforeHistoricals: {
      query: GET_HCP_HISTORICAL,
      variables() {
        this.tabBeforeLoading = true

        return {
          filterDate: new Date(),
          gatheringTypes: this.beforeFilters.gatheringTypes,
          getAfter: false,
          hcpUuid: this.$route.params.hcpUuid
        }
      },
      skip() {
        return this.activeTab !== 1
      },
      update: ({ healthCareProfessional }) => healthCareProfessional.eventParticipations,
      async result({ data }) {
        this.tabBeforeLoading = false

        data.healthCareProfessional.eventParticipations.forEach(event => {
          this.$apollo
            .query({
              query: GET_GUESTS_BY_EVENT_FOR_TRANSPORT_STATUS,
              variables: {
                eveniumEventId: event.eveniumEvent.id,
                limit: 10000,
                status: this.$const.guestStatus.CONFIRMED
              }
            })
            .then(res => {
              if (res.data) this.updateEvent(false, event.eveniumEvent.id, res.data.eveniumGuests.guests)
            })
        })
      }
    }
  },

  methods: {
    getGuestStatusColor(status) {
      switch (status) {
        case this.$const.guestStatus.CANCELED:
          return 'error'
        case this.$const.guestStatus.CONFIRMED:
          return 'success'
        default:
          return ''
      }
    },
    getPresenceRatio(event) {
      if (!event.gathering) return '- -'
      const totalSigned = event.gatheringDates.length
      const totalDays = dateRange(event.gathering.beginDate, event.gathering.endDate).length

      return `${totalSigned}/${totalDays}`
    },
    updateEvent(isAfter, eveniumEventId, guests) {
      const historicalType = isAfter ? 'afterHistoricals' : 'beforeHistoricals'
      const eventIndex = this[historicalType].findIndex(event => event.eveniumEvent.id === eveniumEventId)
      const guest = guests.find(
        guest =>
          guest.veevaId === this.healthCareProfessional.veevaId ||
          guest.email === this.healthCareProfessional.emailAddress
      )
      if (guest) {
        this.$set(this[historicalType][eventIndex], 'eveniumGuestId', guest.contactId)
      }

      this.$set(this[historicalType][eventIndex], 'transportStatus', (guest && guest.transportStatus) || '- -')
      this.$set(this[historicalType][eventIndex], 'postStatus', (guest && guest.postStatus) || '- -')
    }
  }
}
</script>

<style lang="scss">
.HcpHistorical {
  .v-data-table-header {
    th {
      font-weight: 500 !important;
      color: #6c6c6c !important;
      font-size: 14px !important;
    }
  }

  .v-data-table {
    td {
      font-size: 16px !important;
    }
  }
}
</style>
