import gql from 'graphql-tag'

export const UPDATE_BIOGEN_EVENT = gql`
  mutation updateBiogenEvent(
    $gathering: GatheringUpdateInput!
    $biogenEvent: BiogenEventUpdateInput!
    $comment: String
  ) {
    updateBiogenEvent(gathering: $gathering, biogenEvent: $biogenEvent, comment: $comment) {
      gatheringUuid
    }
  }
`
