<template>
  <div>
    <div class="mb-1 black--text">{{ $t('proof-sponsorship.title') }}</div>
    <v-row no-gutters align="center">
      <v-col cols="8" md="4">
        <v-text-field
          v-model="name"
          @keydown.enter="addSponsor"
          :placeholder="$t('proof-sponsorship.sponsor-placeholder')"
          class="text-field mr-2"
          data-e2e="text-field-add-sponsor"
          dense
          hide-details
          outlined
        />
      </v-col>
      <v-col cols="4" md="2">
        <v-btn @click="addSponsor" color="success" data-e2e="button-add-sponsor" depressed :disabled="!name">{{
          $t`add`
        }}</v-btn>
      </v-col>
    </v-row>
    <v-container fluid>
      <v-row>
        <v-chip
          v-for="(sponsor, i) in sponsorships"
          :key="i"
          @click:close="deleteSponsor(i)"
          class="mr-2 mb-2"
          close
          data-e2e="container-sponsorship"
          large
          outlined
        >
          {{ sponsor }}
        </v-chip>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ProofSponsorship',

  model: {
    prop: 'sponsorships',
    event: 'change'
  },

  props: {
    sponsorships: {
      type: Array
    }
  },

  data() {
    return {
      name: ''
    }
  },

  methods: {
    addSponsor() {
      if (!this.sponsorships) {
        this.$emit('change', [this.name])
        this.name = ''
        return
      }

      this.sponsorships.push(this.name)
      this.name = ''
    },
    deleteSponsor(index) {
      this.sponsorships.splice(index, 1)
    }
  }
}
</script>

<style scoped></style>
