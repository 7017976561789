<template>
  <v-card>
    <IncreaseGlobalQuota
      v-if="step === 1"
      @cancel="$emit('cancel')"
      @change="$emit('change', $event)"
      @confirm="$emit('confirm')"
      :comment="comment"
      :solicitation-uuid="solicitationUuid"
      :doctorContext="doctorContext"
    />
    <SelectZone
      v-else-if="step === 2"
      @cancel="$emit('cancel')"
      @change="$emit('change', $event)"
      @confirm="$emit('confirm', $event)"
      :comment="comment"
      :solicitation-uuid="solicitationUuid"
    />
    <ChooseStep
      v-else
      @step="chooseStep"
      :displaySelectZone="displaySelectZone && doctorContext"
      :doctorContext="doctorContext"
    />
  </v-card>
</template>

<script>
import ChooseStep from '@/components/congrex/forceSolicitationValidation/ChooseStep'
import IncreaseGlobalQuota from '@/components/congrex/forceSolicitationValidation/IncreaseGlobalQuota'
import SelectZone from '@/components/congrex/forceSolicitationValidation/SelectZone'
export default {
  name: 'ForceSolicitationValidation',

  components: { SelectZone, IncreaseGlobalQuota, ChooseStep },

  model: {
    prop: 'comment',
    event: 'change'
  },

  props: {
    doctorContext: {
      type: Boolean,
      default: true
    },
    displaySelectZone: {
      type: Boolean,
      default: true
    },
    solicitationUuid: {
      type: String,
      required: true
    },
    comment: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      step: 0
    }
  },

  methods: {
    chooseStep(step) {
      this.step = step
    }
  }
}
</script>
