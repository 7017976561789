import gql from 'graphql-tag'

export const GET_SOLICITATION = gql`
  query Solicitation($solicitationUuid: GUID!) {
    solicitation(solicitationUuid: $solicitationUuid) {
      actualAndNewTherapeutic
      businessUnitUuid
      clinicalStudyPresentation
      createdAt
      emailAddress
      initiatorUuid
      isTherapeuticCommittee
      newsSearchPath
      noBiogenObligation
      noOtherFunding
      noOtherIntercontinentalFunding
      others
      phoneNumber
      requestedSupport
      scan
      shareClinicalKnowledge
      signature
      solicitationUuid
      status
      supportAccommodation
      supportRegistration
      supportTransport
      therapeuticArea
      therapeuticCommitteeRole
      zoneUuid
      zone {
        label
      }
      healthCareProfessional {
        emailAddress
        firstName
        lastName
        healthCareOrganization
        healthCareProfessionalUuid
        phoneNumber
        specialty
        rppsNumber
        targetBbu
        targetMs
        targetSma
        type
        veevaId
      }
      congress {
        congressUuid
        hasZoneQuota
        maximalQuota
        nationalityType
        otherQuota
        gatheringUuid
        gathering {
          city
          name
          beginDate
          endDate
          location
          gatheringUuid
          gatheringBusinessUnits {
            hasZoneQuota
            gatheringBusinessUnitUuid
            businessUnitUuid
            businessUnit {
              label
            }
            hasAutomatedValidation
          }
          users {
            user {
              firstName
              lastName
            }
            gatheringUserType
          }
        }
        stats {
          otherFilled
          totalFilled
          zones {
            filledZoneQuota
            zoneQuota
            zoneUuid
          }
          businessUnits {
            businessUnitUuid
            doctorFilled
            doctorQuota
            otherDisciplinesQuota
            otherFilled
          }
        }
      }
    }
  }
`
export const GET_SOLICITATION_QUOTAS = gql`
  query getGlobalQuota($solicitationUuid: GUID!) {
    solicitation(solicitationUuid: $solicitationUuid) {
      businessUnitUuid
      congress {
        gathering {
          gatheringBusinessUnits {
            businessUnitUuid
            doctorQuota
            otherDisciplinesQuota
          }
        }
      }
    }
  }
`

export const GET_QUOTA_BY_ZONE = gql`
  query getQuotaByZone($solicitationUuid: GUID!) {
    solicitation(solicitationUuid: $solicitationUuid) {
      businessUnitUuid
      congress {
        stats {
          zones {
            filledZoneQuota
            zoneUuid
          }
        }
        zoneCongresses {
          zoneUuid
          zoneQuota
        }
      }
    }
  }
`
