<template>
  <div class="Sponsorship">
    <vue-loader-tab v-if="Object.keys(gathering).length === 0" />
    <v-container v-else class="mx-auto">
      <v-row>
        <v-col class="headline py-3 pl-5 mb-8">
          {{ $t`sponsorship-title` }}
        </v-col>
      </v-row>

      <div v-for="(group, index) in groups" class="group" :class="{ first: index === 0 }" :key="group.label">
        <v-row>
          <v-col cols="12" align="center">
            <v-chip :dark="true" color="black" class="px-4 white--text font-weight-medium text-capitalize">
              {{ group.label }}
            </v-chip>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="proof px-2" cols="3" v-for="proof in group.proofs" :key="proof.sponsorshipProofUuid">
            <v-card>
              <a :href="proof.url" target="_blank">
                <img class="mb-1" :src="proof.thumbnailUrl" />
              </a>

              <v-card-actions class="py-3 px-4">
                <v-chip class="font-weight-bold mr-4" label color="red" text-color="white" width="80">IMG</v-chip>

                <span class="caption grey--text text--darken-2--text font-weight-bold mr-4">
                  {{ proof.filename }}
                </span>

                <a
                  v-if="hasRight($const.right.downloadSponsorshipProofs)"
                  :href="proof.downloadUrl"
                  v-show="canDownload(proof)"
                  download
                >
                  <v-icon color="primary">mdi-download</v-icon>
                </a>
              </v-card-actions>

              <v-menu v-if="hasRight($const.right.deleteSponsorshipProof)" bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    absolute
                    class="edit mt-5"
                    color="grey lighten-2 white--text"
                    elevation="0"
                    fab
                    top
                    right
                    x-small
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="preDeleteSponsorshipProof(proof.sponsorshipProofUuid)">delete</v-list-item>
                </v-list>
              </v-menu>
            </v-card>
          </v-col>

          <v-col v-if="$store.getters.hasRight($const.right.createSponsorshipProof)" cols="3" class="proof px-2 new">
            <v-card class="text-center" elevation="0">
              <input
                type="file"
                ref="file"
                :data-group-index="index"
                v-show="false"
                accept="image/*"
                @change="upload"
              />

              <div>
                {{ $t`add-proof` }}
              </div>

              <v-btn
                elevation="0"
                fab
                color="grey darken-2"
                outlined
                width="84"
                height="84"
                @click.stop="$refs.file[index].click()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <v-btn
        v-if="hasRight($const.right.downloadSponsorshipProofs)"
        class="fixed-button"
        color="congrex-primary"
        dark
        elevation="3"
        fab
        :href="`${$baseUrl}/congress/${gatheringUuid}/proof/_downloadAll`"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>

      <base-confirm-modal
        :title="$t`sure-to-delete-sponsorship-proof`"
        :text="$t`sure-to-delete-sponsorship-proof-content`"
        v-model="showDeleteConfirm"
        @cancel="() => (showDeleteConfirm = false)"
        @confirm="deleteSponsorshipProof"
      />
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import LoaderTab from '@/components/congrex/tabs/LoaderTab'
import { CREATE_SPONSORSHIPPROOF } from '@/graphql/SponsorshipProof/CreateSponsorshipProof'
import { DELETE_SPONSORSHIPPROOF } from '@/graphql/SponsorshipProof/DeleteSponsorshipProof'
import { GET_GATHERING_CONGRESS_SPONSORSHIPS } from '@/graphql/Gatherings/GetGathering'
import { GET_SPONSORSHIPPROOFS } from '@/graphql/SponsorshipProof/GetSponsorshipProofs'

export default {
  components: {
    'vue-loader-tab': LoaderTab
  },

  data() {
    return {
      gathering: {},
      groups: [],
      labels: [],
      showDeleteConfirm: false,
      sponsorshipProofs: [],
      toDeleteUuid: null
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    gatheringUuid() {
      return this.$route.params.gatheringUuid
    }
  },

  apollo: {
    gathering: {
      query: GET_GATHERING_CONGRESS_SPONSORSHIPS,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      result({ data: { gathering } }) {
        this.labels = gathering.congress.sponsorships
      }
    },
    sponsorshipProofs: {
      query: GET_SPONSORSHIPPROOFS,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      }
    }
  },

  methods: {
    canDownload(sponsorshipProof) {
      const user = this.$store.state.myUser

      if (this.hasRight(this.$const.right.readAllSponsorshipProofs)) {
        return true
      }

      return sponsorshipProof.userUuid === user.userUuid
    },
    async deleteSponsorshipProof() {
      this.showDeleteConfirm = false

      const sponsorshipProofUuid = this.toDeleteUuid
      this.toDeleteUuid = null

      await this.$apollo.mutate({
        mutation: DELETE_SPONSORSHIPPROOF,
        variables: { sponsorshipProofUuid },
        update: (store, { data: { deleteSponsorshipProof } }) => {
          if (deleteSponsorshipProof) {
            const index = this.sponsorshipProofs.findIndex(sp => sp.sponsorshipProofUuid === sponsorshipProofUuid)
            if (index > -1) {
              this.sponsorshipProofs.splice(index, 1)
            }
          }
        }
      })
    },
    getGroups() {
      if (!this.labels || this.labels.length === 0) {
        return []
      }

      const groups = this.labels.reduce((acc, label) => {
        acc[label] = []
        return acc
      }, {})

      for (const sponsorshipProof of this.sponsorshipProofs) {
        if (groups[sponsorshipProof.label]) {
          groups[sponsorshipProof.label].push(sponsorshipProof)
        }
      }

      return Object.keys(groups).map(label => ({
        label,
        proofs: groups[label]
      }))
    },
    preDeleteSponsorshipProof(sponsorshipProofUuid) {
      this.toDeleteUuid = sponsorshipProofUuid
      this.showDeleteConfirm = true
    },
    async upload({ target }) {
      const file = target.files[0]
      const group = this.groups[target.getAttribute('data-group-index')]

      await this.$apollo.mutate({
        mutation: CREATE_SPONSORSHIPPROOF,
        variables: {
          sponsorshipProof: {
            file,
            gatheringUuid: this.$route.params.gatheringUuid,
            label: group.label
          }
        },
        update: (store, { data: { createSponsorshipProof: proof } }) => {
          this.sponsorshipProofs.push(proof)
        }
      })
    }
  },

  watch: {
    labels() {
      this.groups = this.getGroups()
    },
    sponsorshipProofs() {
      this.groups = this.getGroups()
    }
  }
}
</script>

<style scoped lang="scss">
.Sponsorship {
  .fixed-button {
    position: fixed;
    z-index: 8;
    bottom: 30px;
    right: 30px;
  }

  .headline {
    box-shadow: 0 10px 8px -6px #a0a0a033;
  }

  .group {
    &.first {
      border-top: none;
      margin-top: -8px;
    }
    border-top: solid 1px #cecece;
    padding-top: 32px;
    margin-top: 25px;

    .proof {
      max-width: 348px;
      width: 348px;

      .caption {
        width: 200px;
        height: 2em;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.new {
        width: 333px;

        .v-card {
          height: 308px;
          border: dashed 1px #979797;
          border-radius: 4px;
          padding-top: 63px;

          button {
            margin-top: 25px;
            border: dashed 1px #979797;
          }
        }
      }
    }
  }
}
</style>
