import gql from 'graphql-tag'

export const GET_ID_EVENIUM_EVENTS = gql`
  query eveniumEvents($includeCurrent: String, $alreadyUsed: Boolean, $startAfter: DateTime) {
    eveniumEvents(includeCurrent: $includeCurrent, alreadyUsed: $alreadyUsed, startAfter: $startAfter) {
      id
      displayTitle
      startDate
      endDate
      location {
        city
        country
        name
      }
    }
  }
`

export const GET_INVITATIONS_EVENIUM_EVENT = gql`
  query eveniumEvent($id: String!) {
    eveniumEvent(eveniumEventId: $id) {
      id
      title
      eveniumGuests {
        hasMore
        guests {
          lastName
          firstName
          status
          healthCareProfessional {
            city
            emailAddress
            targetBbu
            targetMs
            targetSma
            zone {
              zoneUuid
              label
            }
          }
        }
      }
    }
  }
`
