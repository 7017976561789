import gql from 'graphql-tag'

export const MATCH_CRITERIAS = gql`
  query matchCriterias($healthCareProfessionalUuid: GUID!, $congressUuid: GUID!, $businessUnitUuid: GUID!) {
    matchCriterias(
      healthCareProfessionalUuid: $healthCareProfessionalUuid
      congressUuid: $congressUuid
      businessUnitUuid: $businessUnitUuid
    )
  }
`
