<template>
  <div class="HcpHistoricalTab">
    <div class="py-4 pr-5 d-flex justify-end">
      <RadioButton v-model="radioButtonFilters" @change="value => (filters.gatheringTypes = value)" />
    </div>

    <v-data-table :headers="headers" :items="items" :loading="loading" hide-default-footer>
      <template v-slot:item.eventName="{ item }">
        <span>{{ item.eventName }}</span>
        <v-btn
          class="ml-1"
          icon
          color="congrex-primary"
          small
          :to="{ name: 'CongressTabs', params: { gatheringUuid: item.gatheringUuid } }"
          exact
        >
          <v-icon small dense>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.solicitation="{ item }">
        <span>{{ item.solicitation || '- -' }}</span>
        <v-btn
          v-if="item.solicitationUuid"
          class="ml-1"
          icon
          color="congrex-primary"
          small
          :to="{ name: 'SolicitationView', params: { solicitationUuid: item.solicitationUuid } }"
          exact
        >
          <v-icon small dense>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.eventType="{ item }">
        <span :class="item.eventType === 'congress' ? 'success--text' : 'primary--text'">{{
          $t(`gathering-type.${item.eventType}`)
        }}</span>
      </template>
      <template v-slot:item.invitation="{ item }">
        <span :class="`${item.invitation.class}--text`">
          {{ $log('value invitation -->', item.invitation.text) }}
          {{ item.invitation.text }}
        </span>
      </template>
      <template v-slot:item.transportStatus="{ item }">
        <v-progress-circular v-if="!item.transportStatus" indeterminate color="grey" />
        <template v-else>
          <span>{{ item.transportStatus }}</span>
          <v-btn
            v-if="item.eveniumEventId && item.eveniumGuestId && item.gatheringUuid"
            class="ml-1"
            icon
            color="congrex-primary"
            small
            :to="{
              name: 'GuestLogistics',
              params: {
                eveniumEventId: item.eveniumEventId,
                eveniumGuestId: item.eveniumGuestId,
                gatheringUuid: item.gatheringUuid
              }
            }"
            exact
          >
            <v-icon small dense>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
      </template>
      <template v-slot:item.countSignature="{ item }">
        <span>{{ item.countSignature }}</span>
        <v-btn
          class="ml-1"
          icon
          color="congrex-primary"
          small
          :to="{ name: 'PresenceList', params: { gatheringUuid: item.gatheringUuid } }"
          exact
        >
          <v-icon small dense>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.postStatus="{ value }">
        {{ $log('value postStatus -->', value) }}
        <v-progress-circular v-if="!value" indeterminate color="grey" />
        <span v-else :class="`${getGuestPostStatusColor(value)}--text font-weight-medium`">
          {{ $t(`guest-post-status.${value}`) }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import RadioButton from '@/components/congrex/RadioButton'

export default {
  name: 'HcpHistoricalTab',

  model: {
    prop: 'filters',
    event: 'change'
  },

  components: { RadioButton },

  props: {
    filters: Object,
    items: Array,
    loading: Boolean
  },

  data() {
    return {
      radioButtonFilters: [
        {
          id: 0,
          text: this.$t('hcp-historical-tab.event-type-filter.all'),
          value: [this.$const.gatheringType.BIOGEN_EVENT, this.$const.gatheringType.CONGRESS],
          selected: true
        },
        {
          id: 1,
          text: this.$t('hcp-historical-tab.event-type-filter.congress'),
          value: [this.$const.gatheringType.CONGRESS],
          selected: false
        },
        {
          id: 2,
          text: this.$t('hcp-historical-tab.event-type-filter.biogen'),
          value: [this.$const.gatheringType.BIOGEN_EVENT],
          selected: false
        }
      ]
    }
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('hcp-historical-tab.header.eventName'),
          value: 'eventName'
        },
        {
          text: this.$t('hcp-historical-tab.header.eventType'),
          value: 'eventType'
        },
        {
          text: this.$t('hcp-historical-tab.header.beginDate'),
          value: 'beginDate'
        },
        {
          text: this.$t('hcp-historical-tab.header.city'),
          value: 'city'
        },
        {
          text: this.$t('hcp-historical-tab.header.solicitation'),
          value: 'solicitation'
        },
        {
          text: this.$t('hcp-historical-tab.header.invitation'),
          value: 'invitation'
        },
        {
          text: this.$t('hcp-historical-tab.header.status'),
          value: 'transportStatus'
        },
        {
          text: this.$t('hcp-historical-tab.header.signature'),
          value: 'countSignature'
        },
        {
          text: this.$t('hcp-historical-tab.header.presence'),
          value: 'postStatus'
        }
      ]
    }
  },

  methods: {
    getGuestPostStatusColor(status) {
      switch (status) {
        case this.$const.guestPostStatus.NO_SHOW:
          return 'error'
        case this.$const.guestPostStatus.PRESENT:
          return 'success'
        default:
          return ''
      }
    }
  }
}
</script>
