<template>
  <div class="Participants">
    <v-card>
      <v-card-title>
        {{ $t`guests-list` }}
        <v-spacer />
        <v-btn
          v-if="!isVirtual && $store.getters.hasRight($const.right.signPresence)"
          :to="{ name: 'PresenceList', params: { gatheringUuid: $route.params.gatheringUuid } }"
          color="congrex-primary"
          dark
          small
          depressed
          class="mr-7 text-none"
        >
          {{ $t`presence-list` }}
        </v-btn>
        <v-text-field
          v-model="searchLastName"
          clearable
          :label="$t`search`"
          :placeholder="$t('logisticPage.searchFieldPlaceholder')"
          append-icon="mdi-magnify"
          class="search-field"
          hide-details
          single-line
        />
      </v-card-title>
      <v-data-table :headers="headers" :items="items" :search="searchLastName">
        <!-- Transport -->
        <template v-slot:[`item.transport`]="{ value }">
          <span v-if="value">
            <v-icon v-if="typeof value === 'object'" :color="value.color">
              {{ value.icon }}
            </v-icon>

            <mybb-text v-else>
              {{ value }}
            </mybb-text>
          </span>
        </template>
        <!-- Hosting -->
        <template v-slot:[`item.hosting`]="{ value }">
          <span v-if="value">
            <v-icon v-if="typeof value === 'object'" :color="value.color">
              {{ value.icon }}
            </v-icon>

            <mybb-text v-else>
              {{ value }}
            </mybb-text>
          </span>
        </template>
        <!-- Invitation Status -->
        <template v-slot:[`item.invitationStatus`]="{ value }">
          <v-icon v-if="value" :color="value.color">
            {{ value.icon }}
          </v-icon>
        </template>
        <!-- Presence -->
        <template v-slot:[`item.presence`]="{ value }">
          <v-icon v-if="value" :color="value.color">
            {{ value.icon }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'
import dateDiff from 'date-fns/differenceInCalendarDays'
import _capitalize from 'lodash/capitalize'

import { PARTICIPANTS_FOR_GATHERING_CONGREX } from '@/graphql/Participant'

export default {
  name: 'Participants',
  props: {
    gathering: {
      type: Object
    },
    virtualSession: {
      type: Object
    }
  },
  data() {
    return {
      searchLastName: ''
    }
  },
  computed: {
    isVirtual() {
      return (this.gathering && this.gathering.isVirtual && !this.gathering.isPhysical) || this.virtualSession
    },
    headers() {
      const headers = [
        {
          text: this.$t('lastName'),
          value: 'lastName'
        },
        {
          text: this.$t('firstName'),
          filterable: false,
          value: 'firstName'
        },
        {
          text: this.$t('register'),
          filterable: false,
          value: 'invitationStatus'
        }
      ]

      // Hybrid congress
      if (this.gathering && this.gathering.congress && this.gathering.isPhysical && this.gathering.isVirtual) {
        headers.push({ text: this.$t('participation'), value: 'participationType' })
      }

      // Physical congress only
      if (this.gathering && this.gathering.isPhysical) {
        headers.push(
          {
            text: this.$t('transport'),
            filterable: false,
            value: 'transport'
          },
          {
            text: this.$t('evenium-end'),
            filterable: false,
            value: 'arrival'
          },
          {
            text: this.$t('evenium-start'),
            filterable: false,
            value: 'departure'
          },
          {
            text: this.$t('mybb.AttendeeList.table.hosting'),
            filterable: false,
            value: 'hosting'
          }
        )
      }

      headers.push(
        {
          text: this.$t('evenium-status'),
          filterable: false,
          value: 'presence'
        },
        {
          text: this.$t('evenium-presence'),
          filterable: false,
          value: 'signatures'
        }
      )

      return headers
    },
    items() {
      if (this.virtualSession) {
        return this.$get(this.virtualSession, 'virtualSessionParticipants', []).map(participant => ({
          lastName: participant.lastName.toUpperCase(),
          firstName: _capitalize(participant.firstName),
          presence: this.computePresence(participant)
        }))
      }

      return (this.participantsForGathering || []).map(participant => {
        const transportNote = this.extractTransportNote(participant)

        if (transportNote) {
          transportNote.travels = Array.from(transportNote.travels || []).sort((a, b) => {
            const dateA = new Date(a.departureDate)
            const [hourA, minuteA] = a.departureTime.split(':').map(Number)
            dateA.setHours(hourA, minuteA)

            const dateB = new Date(b.departureDate)
            const [hourB, minuteB] = b.departureTime.split(':').map(Number)
            dateB.setHours(hourB, minuteB)

            return dateA - dateB
          })
        }

        return {
          lastName: participant.lastName,
          firstName: participant.firstName,
          participationType: participant.participationType
            ? this.$t(
                `mybb.participants.manual-add-modal.participationType.${participant.participationType.toLowerCase()}`
              )
            : null,
          invitationStatus: this.computeInvitationStatus(participant),
          transport: this.computeTransport(transportNote, participant),
          arrival: this.computeArrival(transportNote),
          departure: this.computeDeparture(transportNote),
          hosting: this.computeHosting(participant),
          presence: this.computePresence(participant),
          signatures: this.computeSignatures(participant)
        }
      })
    }
  },
  apollo: {
    participantsForGathering: {
      query: PARTICIPANTS_FOR_GATHERING_CONGREX,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      update({ participantsForGathering }) {
        return participantsForGathering
          .filter(participant => {
            const { healthCareProfessional, invitationStatus } = participant

            if (!healthCareProfessional) return false

            if (this.gathering.biogenEvent) {
              return invitationStatus === this.$const.invitationStatus.confirmed
            }

            return healthCareProfessional.solicitations.some(
              solicitation =>
                solicitation.congressUuid === this.$get(this.gathering, 'congress.congressUuid') &&
                solicitation.status === this.$const.solicitationStatus.SENT
            )
          })
          .sort((p1, p2) => (p1.lastName || '').localeCompare(p2.lastName || ''))
      },
      skip() {
        return !this.gathering
      }
    }
  },
  methods: {
    extractTransportNote(participant) {
      const { ACCEPTED, TO_EMIT, PENDING, WAITING_FOR_FEEDBACK } = this.$const.transport.noteStatus

      const { transportNotes } = participant
      const accepted = transportNotes.find(note => note.status === ACCEPTED)
      const toEmit = transportNotes.find(note => note.status === TO_EMIT)
      const pending = transportNotes.find(note => note.status === PENDING)
      const waitingForFeedBack = transportNotes.find(note => note.status === WAITING_FOR_FEEDBACK)

      return accepted || toEmit || pending || waitingForFeedBack || null
    },
    computeTransport(transportNote, participant) {
      const { pending: UNANSWERED } = this.$const.invitationStatus
      const { VIRTUAL } = this.$const.gatheringFormat
      const { ACCEPTED } = this.$const.transport.noteStatus

      if (participant.participationType === VIRTUAL) {
        return this.$t('logisticTransport.status.notEligible')
      }

      if (!transportNote && participant.invitationStatus !== UNANSWERED) {
        return this.$t('logisticTransport.status.refused')
      }

      if (!transportNote && participant.invitationStatus === UNANSWERED) {
        return ''
      }

      const modes = new Set()

      // if transport note status is accepted we display transport mode
      if (transportNote.status === ACCEPTED) {
        for (const travel of transportNote.travels) {
          modes.add(travel.mode)
        }
      }

      if (!modes.size) return this.$t(`mybb.transportNoteList.status.${transportNote.status}`)

      return Array.from(modes)
        .map(mode => this.$t(`mybb.transportNoteList.modes.${mode}`))
        .join(' - ')
    },
    computeArrival(transportNote) {
      if (!transportNote) return '- -'

      const goTravels = transportNote.travels.filter(travel => travel.type === this.$const.transport.travelType.GO)

      if (!goTravels.length) return '- -'

      const lastGoTravel = goTravels[goTravels.length - 1]
      const [hour, minute] = (lastGoTravel || '').arrivalTime.split(':').map(Number)

      const date = new Date(lastGoTravel.arrivalDate)
      date.setHours(hour || 0, minute || 0, 0, 0)

      return dateFormat(date, 'dd/MM/yy - HH:mm')
    },
    computeDeparture(transportNote) {
      if (!transportNote) return '- -'

      const backTravels = transportNote.travels.filter(travel => travel.type === this.$const.transport.travelType.BACK)

      if (!backTravels.length) return '- -'

      const firstBack = backTravels[0]
      const [hour, minute] = (firstBack || '').departureTime.split(':').map(Number)

      const date = new Date(firstBack.departureDate)
      date.setHours(hour || 0, minute || 0, 0, 0)

      return dateFormat(date, 'dd/MM/yy - HH:mm')
    },
    computeHosting(participant) {
      const { pending: UNANSWERED } = this.$const.invitationStatus
      const { PENDING, REFUSED, ACCEPTED } = this.$const.hosting.overnightStayStatus
      const overnightStays = this.$get(participant, 'overnightStays', [])
      const hostingRequests = this.$get(participant, 'hostingParticipantRequests', [])

      const { VIRTUAL } = this.$const.gatheringFormat

      if (participant.participationType === VIRTUAL) {
        return this.$t('logisticHosting.status.notEligible')
      }

      if (!overnightStays.length && !hostingRequests.length && participant.invitationStatus !== UNANSWERED) {
        return this.$t('logisticHosting.status.refused')
      }

      const isAccepted = hostingRequests.length || overnightStays.every(({ status }) => status === ACCEPTED)
      const isRefused = overnightStays.every(({ status }) => status === REFUSED)

      const status = isAccepted ? ACCEPTED : isRefused ? REFUSED : PENDING

      switch (status) {
        case ACCEPTED: {
          const hostingRequest = hostingRequests.find(request => request.hosting)
          const overnightStay = overnightStays.find(stay => stay.hostingNight)

          return hostingRequest
            ? this.$get(hostingRequest, 'hosting.name')
            : this.$get(overnightStay, 'hostingNight.hosting.name')
        }

        case REFUSED:
          return 'Non éligible'

        case PENDING:
          return 'Oui'
        default:
          return null
      }
    },
    computePresence(participant) {
      const { present, absent, noShow } = this.$const.presence
      const { presence } = participant

      if (!presence) return null

      switch (presence) {
        case present:
          return { color: 'mybb-primary-lighten1', icon: 'mdi-check', presence }

        case absent:
          return { color: 'mybb-error', icon: 'mdi-close', presence }

        case noShow:
          return { color: 'mybb-warning', icon: 'mdi-alert', presence }

        default:
          return null
      }
    },
    computeInvitationStatus(participant) {
      const { invitationStatus } = participant
      switch (invitationStatus) {
        case 'CONFIRMED':
          return { color: 'mybb-primary-lighten1', icon: 'mdi-check' }
        default:
          return null
      }
    },
    computeSignatures(participant) {
      if (!participant.healthCareProfessional) return '- -'

      const numberOfDays = Math.abs(dateDiff(new Date(this.gathering.beginDate), new Date(this.gathering.endDate))) + 1
      const signedPresenceDates = (participant.healthCareProfessional.hcpGatheringDates || []).length

      return `${signedPresenceDates}/${numberOfDays}`
    }
  }
}
</script>
