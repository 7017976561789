<template>
  <v-card class="HistoricalRecord mb-7">
    <v-card-title class="pl-10 error white--text">{{ $t`comment` }}</v-card-title>
    <v-card-text class="pl-10">
      <div class="py-5 d-flex align-center black--text">
        <!-- TODO What to display ? -->
        <div class="round-full-name mr-3 text-uppercase">{{ historicalRecord.user.userTypes[0].slice(0, 2) }}</div>
        <div>
          <div>{{ historicalRecord.user.firstName }} {{ historicalRecord.user.lastName }}</div>
          <div class="grey--text">{{ dateFormated }}</div>
        </div>
      </div>
      <p class="black--text">{{ historicalRecord.data.comment }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HistoricalRecord',

  props: {
    historicalRecord: {
      type: Object,
      required: true
    }
  },

  computed: {
    dateFormated() {
      return moment(this.historicalRecord.createdAt).format('DD MMMM YYYY')
    }
  }
}
</script>

<style lang="scss">
.HistoricalRecord {
  .round-full-name {
    background: var(--v-congrex-primary-base);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }
}
</style>
