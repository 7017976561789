<template>
  <div class="EventsByCategory">
    <v-row
      v-if="beingCreated.length > 0 || beginCreatedVS.length > 0"
      class="mb-10"
      data-e2e="event-category-being-created"
    >
      <v-col cols="12" class="display-1">{{ $t`events-being-created` }}</v-col>
      <v-col v-for="event in beingCreated" :key="event.gatheringUuid" cols="12" sm="6" md="4">
        <!-- <vue-event-card :event="event" /> -->
        <new-event-card :event="event" @refresh="$emit('refresh')" />
      </v-col>
      <v-col v-for="virtualSession in beginCreatedVS" :key="virtualSession.virtualSessionUuid" cols="12" sm="6" md="4">
        <!-- <vue-event-card :event="event" /> -->
        <virtual-session-card :virtual-session="virtualSession" @refresh="$emit('refresh')" />
      </v-col>
    </v-row>
    <v-row v-if="toValidate.length > 0" class="mb-10" data-e2e="event-category-to-validate">
      <v-col cols="12" class="display-1">{{ $t`events-to-validate` }}</v-col>
      <v-col v-for="event in toValidate" :key="event.gatheringUuid" cols="12" sm="6" md="4">
        <!-- <vue-event-card :event="event" /> -->
        <new-event-card :event="event" @refresh="$emit('refresh')" />
      </v-col>
    </v-row>
    <v-row v-if="toPublish.length > 0" class="mb-10" data-e2e="event-category-to-publish">
      <v-col cols="12" class="display-1">{{ $t`events-to-publish` }}</v-col>
      <v-col v-for="event in toPublish" :key="event.gatheringUuid" cols="12" sm="6" md="4">
        <!-- <vue-event-card :event="event" /> -->
        <new-event-card :event="event" @refresh="$emit('refresh')" />
      </v-col>
    </v-row>
    <v-row v-if="congressToCome.length > 0" class="mb-10" data-e2e="event-category-congress-to-come">
      <v-col cols="12" class="display-1">{{ $t`congress-to-come` }}</v-col>
      <v-col v-for="event in congressToCome" :key="event.gatheringUuid" cols="12" sm="6" md="4">
        <!-- <vue-event-card :event="event" /> -->
        <new-event-card :event="event" @refresh="$emit('refresh')" />
      </v-col>
    </v-row>
    <v-row
      v-if="biogenEventToCome.length > 0 || vsToCome.length > 0"
      class="mb-10"
      data-e2e="event-category-biogen-event-to-come"
    >
      <v-col cols="12" class="display-1">{{ $t`biogen-events-to-come` }}</v-col>
      <v-col v-for="event in biogenEventToCome" :key="event.gatheringUuid" cols="12" sm="6" md="4">
        <!-- <vue-event-card :event="event" /> -->
        <new-event-card :event="event" @refresh="$emit('refresh')" />
      </v-col>
      <v-col v-for="virtualSession in vsToCome" :key="virtualSession.virtualSessionUuid" cols="12" sm="6" md="4">
        <!-- <vue-event-card :event="event" /> -->
        <virtual-session-card :virtual-session="virtualSession" @refresh="$emit('refresh')" />
      </v-col>
    </v-row>
    <v-row v-if="pastCongress.length > 0" class="mb-10" data-e2e="event-category-past-congress">
      <v-col cols="12" class="display-1">{{ $t`past-congress` }}</v-col>
      <v-col v-for="event in pastCongress" :key="event.gatheringUuid" cols="12" sm="6" md="4">
        <!-- <vue-event-card :event="event" /> -->
        <new-event-card :event="event" @refresh="$emit('refresh')" />
      </v-col>
    </v-row>
    <v-row
      v-if="pastBiogenEvent.length > 0 || pastVS.length > 0"
      class="mb-10"
      data-e2e="event-category-past-biogen-event"
    >
      <v-col cols="12" class="display-1">{{ $t`past-biogen-events` }}</v-col>
      <v-col v-for="event in pastBiogenEvent" :key="event.gatheringUuid" cols="12" sm="6" md="4">
        <!-- <vue-event-card :event="event" /> -->
        <new-event-card :event="event" @refresh="$emit('refresh')" />
      </v-col>
      <v-col v-for="virtualSession in pastVS" :key="virtualSession.virtualSessionUuid" cols="12" sm="6" md="4">
        <!-- <vue-event-card :event="event" /> -->
        <virtual-session-card :virtual-session="virtualSession" @refresh="$emit('refresh')" />
      </v-col>
    </v-row>
    <v-row v-if="archivedCongress.length > 0" class="mb-10" data-e2e="event-category-archived-congress">
      <v-col cols="12" class="display-1">{{ $t`archived-congress` }}</v-col>
      <v-col v-for="event in archivedCongress" :key="event.gatheringUuid" cols="12" sm="6" md="4">
        <!-- <vue-event-card :event="event" /> -->
        <new-event-card :event="event" @refresh="$emit('refresh')" />
      </v-col>
    </v-row>
    <v-row v-if="archivedBiogenEvent.length > 0" class="mb-10" data-e2e="event-category-archived-biogen-event">
      <v-col cols="12" class="display-1">{{ $t`archived-biogen-events` }}</v-col>
      <v-col v-for="event in archivedBiogenEvent" :key="event.gatheringUuid" cols="12" sm="6" md="4">
        <!-- <vue-event-card :event="event" /> -->
        <new-event-card :event="event" @refresh="$emit('refresh')" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _sortBy from 'lodash/sortBy'
import moment from 'moment'
import { mapGetters } from 'vuex'

import { gatheringStatus, virtualSession } from '@/const/shared'
import NewEventCard from '@/components/congrex/eventCard/EventCard'
import VirtualSessionCard from '@/components/congrex/eventCard/VirtualSessionCard'

export default {
  name: 'EventsByCategory',

  components: {
    // 'vue-event-card': EventCard,
    NewEventCard,
    VirtualSessionCard
  },

  props: {
    events: {
      type: Array,
      required: true
    },
    virtualSessions: {
      type: Array,
      required: false
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    archivedCongress() {
      return this.events.filter(
        event =>
          event.congress &&
          event.status !== gatheringStatus.DRAFT &&
          new Date(event.endDate) <
            moment(new Date())
              .subtract(1, 'year')
              .toDate()
      )
    },
    archivedBiogenEvent() {
      return this.events.filter(
        event =>
          event.biogenEvent &&
          event.status !== gatheringStatus.DRAFT &&
          new Date(event.endDate) <
            moment(new Date())
              .subtract(1, 'year')
              .toDate()
      )
    },
    beingCreated() {
      return _sortBy(this.events.filter(event => event.status === gatheringStatus.DRAFT), ['gatheringType']).reverse()
    },
    beginCreatedVS() {
      if (!this.hasRight(this.$const.right.searchDraftGatherings)) return []

      return _sortBy(this.virtualSessions.filter(session => session.status === virtualSession.status.DRAFT))
    },
    toValidate() {
      return _sortBy(
        this.events.filter(
          event => event.status === gatheringStatus.TO_VALIDATE || event.status === gatheringStatus.CORRECTION
        ),
        ['gatheringType']
      ).reverse()
    },
    toPublish() {
      return _sortBy(this.events.filter(event => event.status === gatheringStatus.TO_PUBLISH), [
        'gatheringType'
      ]).reverse()
    },
    congressToCome() {
      return this.events.filter(
        event =>
          event.gatheringType === this.$const.gatheringType.CONGRESS &&
          (event.status === gatheringStatus.PUBLISHED || event.status === gatheringStatus.PUBLISHED_FRONT) &&
          moment(event.endDate).isSameOrAfter(new Date(), 'date')
      )
    },
    biogenEventToCome() {
      return this.events.filter(
        event =>
          event.gatheringType === this.$const.gatheringType.BIOGEN_EVENT &&
          (event.status === gatheringStatus.PUBLISHED || event.status === gatheringStatus.PUBLISHED_FRONT) &&
          moment(event.endDate).isSameOrAfter(new Date(), 'date')
      )
    },
    vsToCome() {
      if (!this.hasRight(this.$const.right.searchPublishedGatherings)) return []

      return _sortBy(
        this.virtualSessions.filter(
          session =>
            session.status === virtualSession.status.PUBLISHED &&
            moment(session.endDate).isSameOrAfter(new Date(), 'date')
        )
      )
    },
    pastCongress() {
      return this.events.filter(
        event =>
          event.gatheringType === this.$const.gatheringType.CONGRESS &&
          (event.status === gatheringStatus.PUBLISHED || event.status === gatheringStatus.PUBLISHED_FRONT || event.status === gatheringStatus.CLOSED || event.status === gatheringStatus.WINDED_UP) &&
          moment(event.endDate).isBefore(new Date(), 'date') &&
          moment(event.endDate).isAfter(moment(new Date()).subtract(1, 'year'))
      )
    },
    pastBiogenEvent() {
      return this.events.filter(
        event =>
          event.gatheringType === this.$const.gatheringType.BIOGEN_EVENT &&
          (event.status === gatheringStatus.PUBLISHED || event.status === gatheringStatus.PUBLISHED_FRONT || event.status === gatheringStatus.CLOSED || event.status === gatheringStatus.WINDED_UP) &&
          moment(event.endDate).isBefore(new Date(), 'date') &&
          moment(event.endDate).isAfter(moment(new Date()).subtract(1, 'year'))
      )
    },
    pastVS() {
      if (!this.hasRight(this.$const.right.searchPublishedGatherings)) return []

      return _sortBy(
        this.virtualSessions.filter(
          session =>
            session.status !== virtualSession.status.DRAFT &&
            moment(session.endDate).isBefore(new Date(), 'date') &&
            moment(session.endDate).isAfter(moment(new Date()).subtract(1, 'year'))
        )
      )
    }
  }
}
</script>

<style lang="scss">
.EventsByCategory {
  & > .row:last-child {
    margin-bottom: 0 !important;
  }
}
</style>
