<template>
  <div class="EventCardBusinessUnit mt-2">
    <v-row class="px-2">
      <!-- Doctor quota -->
      <v-col cols="6" v-if="doctorQuota > 0">
        <mybb-text class="d-block black--text">
          {{ $t('hcpQuota') }}
        </mybb-text>

        <div class="d-flex justify-center">
          <v-progress-linear :value="doctorFilledPercentage" height="6" class="mt-2" rounded top />
          <mybb-text size="12" class="ml-2">{{ doctorFilled }}/{{ doctorQuota }}</mybb-text>
        </div>

        <!-- Zone repartition -->
        <v-menu
          v-if="gatheringBusinessUnit.hasZoneQuota && zoneDetails && zoneDetails.length"
          v-model="showZoneDetails"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <mybb-text v-on="on" class="primary--text mt-2">
              {{ $t('seeZoneRepartitionDetails') }}
              <v-icon color="primary">mdi-chevron-down</v-icon>
            </mybb-text>
          </template>

          <v-card class="px-3 py-2">
            <div v-for="detail of zoneDetails" :key="detail.zoneUuid">
              <mybb-text>{{ detail.zone.label }}</mybb-text>

              <div class="d-flex justify-center">
                <v-progress-linear :value="detail.percentage" height="6" class="mt-2" rounded top />
                <mybb-text size="12" class="ml-2">{{ detail.doctors }}/{{ detail.zoneQuota }}</mybb-text>
              </div>
            </div>
          </v-card>
        </v-menu>
      </v-col>

      <!-- Other quota -->
      <v-col cols="6" v-if="otherQuota > 0">
        <mybb-text class="d-block black--text">
          {{ $t('otherQuota') }}
        </mybb-text>

        <div class="d-flex justify-center">
          <v-progress-linear
            :value="otherFilledPercentage"
            height="6"
            class="mt-2"
            color="congrex-primary"
            rounded
            top
          />
          <mybb-text size="12" class="ml-4">{{ otherFilled }}/{{ otherQuota }}</mybb-text>
        </div>
      </v-col>
    </v-row>

    <mybb-text class="black--text px-2 mt-2 mb-4" weight="bold">
      {{ $t('eventCard.solicitationEndDate') }}
      <mybb-text class="congrex-primary--text" weight="bold">{{ solicitationEndDate }}</mybb-text>
    </mybb-text>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'

export default {
  name: 'EventCardBusinessUnit',
  props: {
    event: {
      type: Object,
      required: true
    },
    gatheringBusinessUnit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showZoneDetails: false
    }
  },
  computed: {
    doctorQuota() {
      return this.gatheringBusinessUnit.doctorQuota
    },
    doctorFilled() {
      return this.gatheringBusinessUnit.subscribedUsers.reduce((sum, bundle) => sum + (bundle.doctors || 0), 0)
    },
    doctorFilledPercentage() {
      return (this.doctorFilled / this.doctorQuota) * 100
    },
    otherQuota() {
      return this.gatheringBusinessUnit.otherDisciplinesQuota
    },
    otherFilled() {
      return this.gatheringBusinessUnit.subscribedUsers.reduce((sum, bundle) => sum + (bundle.others || 0), 0)
    },
    otherFilledPercentage() {
      return (this.otherFilled / this.otherQuota) * 100
    },
    solicitationEndDate() {
      const date = this.gatheringBusinessUnit.solicitationEndDate

      if (!date) return '- -'

      return dateFormat(new Date(date), 'dd/MM/yy')
    },
    zoneDetails() {
      return this.event.congress.zoneCongresses
        .filter(zoneCongress => zoneCongress.zone.businessUnitUuid === this.gatheringBusinessUnit.businessUnitUuid)
        .sort((zc1, zc2) => zc1.zone.label.localeCompare(zc2.zone.label))
        .map(zoneCongress => {
          const subscribedUsers = this.gatheringBusinessUnit.subscribedUsers.find(
            zoneDetail =>
              zoneDetail.zone.businessUnitUuid === this.gatheringBusinessUnit.businessUnitUuid &&
              zoneDetail.zoneUuid === zoneCongress.zoneUuid
          )

          const percentage = subscribedUsers ? (subscribedUsers.doctors / zoneCongress.zoneQuota) * 100 : 0

          return {
            doctors: subscribedUsers ? subscribedUsers.doctors : 0,
            zoneQuota: zoneCongress.zoneQuota,
            zone: zoneCongress.zone,
            percentage
          }
        })
    }
  }
}
</script>
