import gql from 'graphql-tag'

export const CREATE_SPONSORSHIPPROOF = gql`
  mutation createSponsorshipProof($sponsorshipProof: SponsorshipProofCreateInput!) {
    createSponsorshipProof(sponsorshipProof: $sponsorshipProof) {
      congressUuid
      filename
      label
      mimeType
      s3Key
      s3ThumbnailKey
      sponsorshipProofUuid
      thumbnailUrl
      url
      userUuid
      createdAt
      updateAt
    }
  }
`
