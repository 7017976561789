/**
 * @class CONST
 */
const CONST = {
  guestStatus: {
    // Canceled status:
    DELETED: 'DELETED',
    CANCELED: 'CANCELED',
    REPLACED: 'REPLACED',
    DECLINED: 'DECLINED',

    // Pending status:
    PENDING: 'PENDING',
    UNANSWERED: 'UNANSWERED',

    // Confirmed status:
    VALID: 'VALID',
    RESERVED: 'RESERVED',
    OVERBOOKED: 'OVERBOOKED',
    EXTRA: 'EXTRA',
    CONFIRMED: 'CONFIRMED'
  },
  transportType: {
    plane: 'Avion',
    train: 'Train'
  }
}

export default CONST
