<template>
  <div class="QuotaAllocations">
    <v-overlay v-if="selectedBus.length === 0" :value="selectedBus.length === 0" opacity="0.1" absolute>
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-card v-else>
      <v-tabs v-model="tab" grow>
        <v-tab v-for="bu in selectedBus" :data-e2e="`quota-allocation-bu-${bu.label}`" :key="bu.businessUnitUuid">{{
          bu.label
        }}</v-tab>
        <v-tab-item v-for="bu in selectedBus" :key="bu.businessUnitUuid">
          <v-container class="px-6">
            <v-row :no-gutters="readonly">
              <v-col
                v-for="zone in bu.zones"
                :class="{ 'QuotaAllocations-readonlyColContainer': readonly }"
                :key="zone.zoneUuid"
              >
                <template v-if="!readonly" :data-e2e="`quota-allocation-bu-${zone.label}`">
                  <div data-e2e="quota-allocation-zone-label">{{ zone.label }}</div>
                  <v-text-field
                    @input="editQuotaZone($event, zone)"
                    class="QuotaAllocations-textField"
                    data-e2e="quota-allocation-zone-value"
                    dense
                    :error="isZoneError(zone.zoneUuid)"
                    hide-details
                    outlined
                    :readonly="readonly"
                    type="number"
                    :value="getValue(zone)"
                  />
                  <div class="primary--text body-2">{{ getPercentageByZone(zone) }}%</div>
                </template>
                <div v-else class="QuotaAllocations-readonlyValueContainer">
                  <div data-e2e="quota-allocation-zone-label">{{ zone.label }}</div>
                  <div v-if="getValue(zone) > 0" data-e2e="quota-allocation-zone-value">{{ getValue(zone) }}</div>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="!readonly">
              <v-col cols="10">
                <v-progress-linear :color="colorProgressBar" :value="totalPercentageAssigned" height="15" reactive>
                  <strong data-e2e="quota-allocation-percentage-assigned">{{ totalPercentageAssigned }}%</strong>
                </v-progress-linear>
              </v-col>
              <v-col>{{ maximalQuota > 0 ? `${totalAssigned}/${maximalQuota}` : '- -' }}</v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import _sortBy from 'lodash/sortBy'
import _differenceWith from 'lodash/differenceWith'
import _isEqual from 'lodash/isEqual'

export default {
  name: 'QuotaAllocations',

  inject: ['$validator'],

  model: {
    prop: 'zoneCongresses',
    event: 'change'
  },

  props: {
    zoneCongresses: {
      type: Array,
      required: true
    },
    buUuids: {
      type: Array,
      required: true
    },
    maximalQuota: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    stats: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      tab: 0
    }
  },

  computed: {
    colorProgressBar() {
      return this.errors.has('overZoneQuota', 'hcpSupport') ? 'error' : 'primary'
    },
    selectedBus() {
      return _sortBy(this.$store.state.bus.filter(bu => this.buUuids.includes(bu.businessUnitUuid)), 'label')
    },
    totalPercentageAssigned() {
      if (this.totalAssigned === 0) return 0
      if (this.maximalQuota === 0) return '- -'

      const exactPercentage = (this.totalAssigned * 100) / this.maximalQuota
      return this.totalAssigned > this.maximalQuota ? Math.ceil(exactPercentage) : Math.floor(exactPercentage)
    },
    totalAssigned() {
      return this.zoneCongresses.reduce((acc, value) => acc + value.quota, 0)
    }
  },

  watch: {
    selectedBus(currentValues, oldValues) {
      if (currentValues.length < oldValues.length) {
        const [missingBu] = _differenceWith(oldValues, currentValues, _isEqual)
        const newZones = this.zoneCongresses.filter(zone => !missingBu.zones.some(el => el.zoneUuid === zone.zoneUuid))

        this.$emit('change', newZones)
      }
    },
    totalPercentageAssigned: {
      handler() {
        this.validateGlobalQuota()
      },
      immediate: true
    },
    zoneCongresses() {
      if (Array.isArray(this.$get(this.stats, 'zones'))) {
        const wrongZonesQuotas = this.stats.zones.filter(statZone => {
          const localZone = this.zoneCongresses.find(zone => zone.zoneUuid === statZone.zoneUuid)
          return !localZone || localZone.quota < statZone.filledZoneQuota
        })
        this.errors.remove('wrongNewZoneQuota', 'hcpSupport')

        if (wrongZonesQuotas.length > 0) {
          wrongZonesQuotas.forEach(zone => {
            this.errors.add({
              field: 'wrongNewZoneQuota',
              msg: this.$t('new-event.error-message.wrong-new-zone-quota'),
              scope: 'hcpSupport',
              id: zone.zoneUuid
            })
          })
        }
      }
    }
  },

  methods: {
    editQuotaZone(value, zone) {
      if (isNaN(value)) return

      const index = this.zoneCongresses.findIndex(quota => quota.zoneUuid === zone.zoneUuid)

      if (index !== -1) {
        if (value === '') {
          this.zoneCongresses.splice(index, 1)
        } else {
          this.zoneCongresses.splice(index, 1, { zoneUuid: zone.zoneUuid, quota: Number.parseInt(value) })
        }
      } else {
        this.zoneCongresses.push({ zoneUuid: zone.zoneUuid, quota: Number.parseInt(value) })
      }
    },
    getPercentageByZone(zone) {
      if (this.maximalQuota === 0) return '- -'

      const value = this.getValue(zone)

      if (!value) return 0

      return Math.ceil((value * 100) / this.maximalQuota)
    },
    getValue(zone) {
      if (this.zoneCongresses.length === 0) return ''

      const el = this.zoneCongresses.find(quota => quota.zoneUuid === zone.zoneUuid)
      if (el) return el.quota
    },
    isZoneError(zoneUuid) {
      return this.errors.collect('wrongNewZoneQuota', 'hcpSupport', false).some(error => error.id === zoneUuid)
    },
    validateGlobalQuota() {
      this.errors.remove('overZoneQuota', 'hcpSupport')

      if (!this.errors.has('overZoneQuota', 'hcpSupport') && this.totalPercentageAssigned !== 100) {
        this.errors.add({
          field: 'overZoneQuota',
          msg: this.$t('new-event.error-message.wrong-zone-quota'),
          scope: 'hcpSupport'
        })
      }
    }
  },

  beforeDestroy() {
    this.errors.remove('overZoneQuota', 'hcpSupport')
    this.$emit('change', [])
  }
}
</script>

<style lang="scss" scoped>
.QuotaAllocations {
  position: relative;
  min-height: 120px;
}

.QuotaAllocations-textField {
  min-width: 40px;
}

.QuotaAllocations-readonlyColContainer {
  margin: 16px 0;
  padding: 8px 0;
  border-right: grey solid 1px;
  text-align: center;
  &:last-child {
    border-right: none;
  }
}

.QuotaAllocations-readonlyValueContainer {
  & > *:first-child {
    margin-bottom: 12px;
    color: grey;
  }
}
</style>
