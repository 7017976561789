<template>
  <vue-loader-tab v-if="!allLoaded" />
  <v-container v-else class="Historical">
    <v-row class="px-10 mb-10">
      <v-col cols="12" sm="4">
        <div class="subtitle-1 mb-2">{{ $t`solicitation-type` }}</div>
        <v-select v-model="historicalRecordOperation" :items="historicalRecordOperations" outlined hide-details dense />
      </v-col>
    </v-row>

    <v-chip color="congrex-primary" class="ml-6 px-7 font-weight-bold caption pa-2" dark>{{ $t`today` }}</v-chip>

    <v-timeline dense toggle-small>
      <v-timeline-item
        v-for="date in dates"
        :key="date"
        :color="isToday(date) ? 'congrex-primary' : '#e0e0e0'"
        small
        fill-dot
      >
        <v-expansion-panels accordion flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="mb-2 primary--text subtitle-2">{{ date | moment('DD MMMM YYYY') }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-for="record of historicalRecordsByDate[date]" :key="record.historicalRecordUuid">
                <v-col>
                  <div class="d-flex mb-2">
                    <div class="initalsLogo">{{ getInitials(record.user) }}</div>
                    <div v-html="historicalrecordFormated(record)" />
                  </div>
                  <div v-if="record.data.comment" class="my-3 py-2 px-4 caption comment">
                    <div class="font-weight-bold">{{ $t`comment` }}</div>
                    <div>{{ record.data.comment }}</div>
                  </div>
                </v-col>
                <v-col cols="2" class="text-right grey--text text--lighten-1 subtitle-2">
                  {{ record.createdAt | moment('HH:mm') }}
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
import _sortBy from 'lodash/sortBy'
import _difference from 'lodash/difference'

import LoaderTab from '@/components/congrex/tabs/LoaderTab'
import { GET_GATHERING_HISTORICAL_RECORDS } from '@/graphql/HistoricalRecord/GetHistoricalRecords'
import { GET_HCPS_FOR_HISTORICAL_RECORDS } from '@/graphql/Hcp/GetHcps'
import { GET_ALL_ZONES } from '@/graphql/Zone/GetZones'

export default {
  name: 'Historical',

  components: {
    'vue-loader-tab': LoaderTab
  },

  data() {
    return {
      hcps: null,
      historicalRecordOperation: '',
      historicalRecords: null,
      zones: null
    }
  },

  computed: {
    allLoaded() {
      return Boolean(this.historicalRecords && this.zones)
    },
    historicalRecordOperations() {
      const res = _sortBy(
        Object.values(this.$const.historicalRecordOperation)
          .filter(operation => `historical-record.${operation}.name` !== this.$t(`historical-record.${operation}.name`))
          .map(operation => ({
            text: this.$t(`historical-record.${operation}.name`),
            value: operation
          })),
        ['text']
      )

      res.unshift({
        text: this.$t`all`,
        value: ''
      })

      return res
    },
    dates() {
      if (!Array.isArray(this.historicalRecords)) return []

      const dates = new Set()

      for (const record of this.historicalRecords) {
        const date = new Date(record.createdAt)
        const recordDate = date.toISOString().slice(0, 10)
        dates.add(recordDate)
      }

      return Array.from(dates)
    },
    historicalRecordsByDate() {
      if (!Array.isArray(this.historicalRecords)) return {}

      return this.historicalRecords.reduce((acc, record) => {
        const date = new Date(record.createdAt)
        const recordDate = date.toISOString().slice(0, 10)

        if (!acc[recordDate]) acc[recordDate] = []

        acc[recordDate].push(record)

        return acc
      }, {})
    }
  },

  apollo: {
    historicalRecords: {
      query: GET_GATHERING_HISTORICAL_RECORDS,
      variables() {
        const query = {
          gatheringUuid: this.$route.params.gatheringUuid,
          operationName: this.historicalRecordOperation
            ? [this.historicalRecordOperation]
            : Object.values(this.$const.historicalRecordOperation)
        }

        return query
      }
    },
    hcps: {
      query: GET_HCPS_FOR_HISTORICAL_RECORDS,
      variables() {
        return {
          healthCareProfessionalUuid: this.historicalRecords
            .filter(record => record.data.healthCareProfessionalUuid)
            .map(record => record.data.healthCareProfessionalUuid)
        }
      },
      skip() {
        return (
          !this.historicalRecords ||
          this.historicalRecords.filter(record => record.data.healthCareProfessionalUuid).length === 0
        )
      },
      update: ({ healthCareProfessionals }) => healthCareProfessionals
    },
    zones: {
      query: GET_ALL_ZONES
    }
  },

  methods: {
    getInitials(user) {
      if (!user) return ''

      return `${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}`
    },
    historicalrecordFormated(record) {
      const hcp = this.hcps
        ? this.hcps.find(hcp => hcp.healthCareProfessionalUuid === record.data.healthCareProfessionalUuid) || {}
        : {}
      const zone =
        this.zones.find(zone => zone.zoneUuid === this.$get(record, 'data.after.zoneCongress.zoneUuid')) || {}

      const payload = {
        fullNameResponsible: `${record.user.firstName} ${record.user.lastName}`
      }

      switch (record.operationName) {
        // Gathering common operations
        case this.$const.historicalRecordOperation.GATHERING_CATCH_UP:
        case this.$const.historicalRecordOperation.GATHERING_CLOSED:
        case this.$const.historicalRecordOperation.GATHERING_CREATION:
        case this.$const.historicalRecordOperation.GATHERING_TO_FIX:
        case this.$const.historicalRecordOperation.GATHERING_FORMAT:
        case this.$const.historicalRecordOperation.GATHERING_FRONT_PUBLICATION:
        case this.$const.historicalRecordOperation.GATHERING_PUBLICATION:
        case this.$const.historicalRecordOperation.GATHERING_REFUSAL:
        case this.$const.historicalRecordOperation.GATHERING_TO_PUBLISH:
        case this.$const.historicalRecordOperation.GATHERING_TO_VALIDATE:
        case this.$const.historicalRecordOperation.GATHERING_VALIDATION:
        case this.$const.historicalRecordOperation.GATHERING_WINDED_UP:
        case this.$const.historicalRecordOperation.SPONSORSHIP_ADD:
          break

        case this.$const.historicalRecordOperation.GATHERING_REOPEN:
          Object.assign(payload, {
            previous: this.$t(`mybb.eventList.status.${record.data.newState}`)
          })
          break

        // Biogen event operations
        case this.$const.historicalRecordOperation.EVENT_UPDATE_QUOTAS:
          Object.assign(payload, {
            quotaBefore: this.$get(record, 'data.before.availablePlace'),
            quotaAfter: this.$get(record, 'data.after.availablePlace')
          })
          break

        // Solicitation operations
        case this.$const.historicalRecordOperation.SOLICITATION_CANCELLED:
        case this.$const.historicalRecordOperation.SOLICITATION_REFUSAL:
        case this.$const.historicalRecordOperation.SOLICITATION_CREATION:
        case this.$const.historicalRecordOperation.SOLICITATION_PUT_HOLD:
          Object.assign(payload, {
            fullNameHcp: `${hcp.firstName} ${hcp.lastName}`
          })
          break
        case this.$const.historicalRecordOperation.SOLICITATION_VALIDATION:
          Object.assign(payload, {
            fullNameHcp: `${hcp.firstName} ${hcp.lastName}`
          })

          if (record.user.firstName === 'system') {
            return this.$t('historical-record.SOLICITATION_VALIDATION_auto.message', payload)
          }
          break
        case this.$const.historicalRecordOperation.SOLICITATION_UPDATED:
          Object.assign(payload, {
            dataUpdated: this.$t(`historical-record.SOLICITATION_UPDATED.${record.data.dataUpdated}`),
            fullNameResponsible: `${record.user.firstName} ${record.user.lastName}`,
            fullNameHcp: `${hcp.firstName} ${hcp.lastName}`
          })
          break

        // Congress operations
        case this.$const.historicalRecordOperation.CONGRESS_UPDATE_CRITERIAS:
          Object.assign(payload, {
            newCriterias:
              _difference(record.data.after.criterias, record.data.before.criterias).join(', ') ||
              this.$t('historical-record.none'),
            deletedCriterias:
              _difference(record.data.before.criterias, record.data.after.criterias).join(', ') ||
              this.$t('historical-record.none')
          })
          break
        case this.$const.historicalRecordOperation.CONGRESS_INCREASE_ZONE_QUOTAS:
          Object.assign(payload, {
            bu: this.$get(zone, 'businessUnit.label', ''),
            zone: zone.label || '',
            quotaBefore: this.$get(record, 'data.before.zoneCongress.zoneQuota', 0),
            quotaAfter: this.$get(record, 'data.after.zoneCongress.zoneQuota', 0)
          })
          break
        case this.$const.historicalRecordOperation.CONGRESS_UPDATE_QUOTAS:
          {
            const quotaBefore =
              this.$get(record, 'data.before.otherDisciplinesQuota') || this.$get(record, 'data.before.doctorQuota')
            const quotaAfter =
              this.$get(record, 'data.after.otherDisciplinesQuota') || this.$get(record, 'data.after.doctorQuota')

            Object.assign(payload, {
              quotaBefore,
              quotaAfter
            })
          }
          break
        case this.$const.historicalRecordOperation.CONGRESS_UPDATE_AUTOMATED_VALIDATION:
          Object.assign(payload, {
            beforeHasAutomatedValidation: ['true', true].includes(
              this.$get(record, 'data.before.hasAutomatedValidation')
            )
              ? this.$t('auto-validation')
              : this.$t('manual-validation'),
            afterHasAutomatedValidation: ['true', true].includes(this.$get(record, 'data.after.hasAutomatedValidation'))
              ? this.$t('auto-validation')
              : this.$t('manual-validation')
          })
          break
        case this.$const.historicalRecordOperation.CONGRESS_UPDATE_SOLICITATION_END_DATE:
          Object.assign(payload, {
            beforeDate: this.$moment(this.$get(record, 'data.before.solicitationEndDate')).format('DD/MM/YY'),
            afterDate: this.$moment(this.$get(record, 'data.after.solicitationEndDate')).format('DD/MM/YY')
          })
          break

        default:
          return this.$t('historical-record.default-message')
      }

      return this.$t(`historical-record.${record.operationName}.message`, payload)
    },
    isToday(stringDate) {
      const date = new Date(stringDate)
      const today = new Date()

      return (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
      )
    }
  }
}
</script>

<style lang="scss">
.Historical {
  max-width: 900px;

  .v-select__selection {
    font-size: 14px;
  }

  .v-timeline-item__dot {
    height: 15px;
    width: 15px;
    box-shadow: none !important;
  }

  .disabled-dot {
    .v-timeline-item__dot {
      width: 12px;
      height: 12px;
    }
  }

  .initalsLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    border-radius: 50%;
    background: #005b7e;
    font-size: 10px;
    font-weight: 500;
    color: white;
  }

  .comment {
    background: #eaeaea;
    border-radius: 8px;
    white-space: pre-line;
  }
}
</style>
