<template>
  <div class="NewEventInformations">
    <div class="title">{{ $t`general-informations` }}</div>
    <v-divider class="mb-8" />
    <form>
      <div class="body-2 grey--text">
        <v-col cols="12" md="10">
          <NewEventEveniumEventId
            v-if="!displaySelectEventId"
            v-model="form"
            :editMode="isEditMode"
            @updateHours="updateDisplayHours"
          />
          <NewEventId
            v-else
            v-model="eventId"
            @change="updateEventId"
            :editMode="isEditMode"
            :form="form"
            :isCongressEvent="isCongressEvent"
          />
        </v-col>
        <v-col cols="12" md="10" class="py-0">
          <div class="mb-1 black--text">{{ isCongressEvent ? $t`congress-name` : $t`event-name` }}*</div>
          <v-text-field
            v-model="form.gathering.name"
            v-validate="'required'"
            data-e2e="input-name"
            :background-color="isPersonalizeEvent ? '' : '#f5f6fa'"
            :data-vv-name="'name'"
            :data-vv-as="$t`event-name`"
            data-vv-scope="informations"
            :disabled="!isPersonalizeEvent"
            :error-messages="errors.collect('name', 'informations')"
            outlined
            dense
            type="text"
          />
        </v-col>
        <v-col cols="12" md="10" class="py-0">
          <div class="mb-1 black--text">{{ $t`description` }}</div>
          <v-text-field
            v-model="form.gathering.description"
            v-validate="'max:200'"
            data-e2e="input-description"
            :data-vv-name="'description'"
            :data-vv-as="$t`description`"
            data-vv-scope="informations"
            :error-messages="errors.collect('description', 'informations')"
            outlined
            dense
            type="text"
            counter="200"
          />
        </v-col>
        <v-col v-if="isCongressEvent" cols="12">
          <v-btn
            @click="chooseNationalityType(nationalityType.INTERNATIONAL)"
            :color="
              $get(form, 'congressEvent.nationalityType') === nationalityType.INTERNATIONAL
                ? 'success'
                : 'grey darken-1'
            "
            :outlined="$get(form, 'congressEvent.nationalityType') !== nationalityType.INTERNATIONAL"
            data-e2e="button-nationality-type-international"
            rounded
            depressed
            class="mr-3 text-capitalize"
          >
            {{ $t(`nationality-type-${nationalityType.INTERNATIONAL}`) }}<v-icon right>mdi-web</v-icon>
          </v-btn>
          <v-btn
            @click="chooseNationalityType(nationalityType.EUROPEAN)"
            :color="
              $get(form, 'congressEvent.nationalityType') === nationalityType.EUROPEAN ? 'success' : 'grey darken-1'
            "
            :outlined="$get(form, 'congressEvent.nationalityType') !== nationalityType.EUROPEAN"
            data-e2e="button-nationality-type-european"
            rounded
            depressed
            class="mr-3 text-capitalize"
          >
            {{ $t(`nationality-type-${nationalityType.EUROPEAN}`) }}
            <v-icon right>mdi-checkbox-blank-circle-outline</v-icon>
          </v-btn>
          <v-btn
            @click="chooseNationalityType(nationalityType.FRENCH)"
            :color="
              $get(form, 'congressEvent.nationalityType') === nationalityType.FRENCH ? 'success' : 'grey darken-1'
            "
            :outlined="$get(form, 'congressEvent.nationalityType') !== nationalityType.FRENCH"
            data-e2e="button-nationality-type-french"
            rounded
            depressed
            class="text-capitalize"
          >
            {{ $t(`nationality-type-${nationalityType.FRENCH}`) }}<v-icon right>mdi-flag-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col v-else cols="12">
          <v-btn
            @click="chooseEventType(biogenEventType.MEDICAL)"
            :color="$get(form, 'biogenEvent.eventType') === biogenEventType.MEDICAL ? 'success' : 'grey darken-1'"
            :outlined="$get(form, 'biogenEvent.eventType') !== biogenEventType.MEDICAL"
            data-e2e="button-biogen-event-type-medical"
            rounded
            depressed
            class="mr-3 text-capitalize"
          >
            {{ biogenEventType.MEDICAL }}<v-icon right>mdi-plus-circle-outline</v-icon>
          </v-btn>
          <v-btn
            @click="chooseEventType(biogenEventType.COMMERCIAL)"
            :color="$get(form, 'biogenEvent.eventType') === biogenEventType.COMMERCIAL ? 'success' : 'grey darken-1'"
            :outlined="$get(form, 'biogenEvent.eventType') !== biogenEventType.COMMERCIAL"
            data-e2e="button-biogen-event-type-commercial"
            rounded
            depressed
            class="mr-3 text-capitalize"
          >
            {{ biogenEventType.COMMERCIAL }}<v-icon right>mdi-briefcase</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-row v-if="!isVirtual">
            <v-col v-if="isCongressEvent" cols="12" md="4">
              <div class="mb-1 black--text">{{ $t`country` }}</div>
              <v-text-field
                v-model="form.gathering.country"
                :background-color="isPersonalizeEvent ? '' : '#f5f6fa'"
                data-e2e="input-country"
                :disabled="!isPersonalizeEvent"
                outlined
                dense
                type="text"
              />
            </v-col>
            <v-col cols="12" md="4">
              <div class="mb-1 black--text">{{ $t`city` }}</div>
              <v-text-field
                v-model="form.gathering.city"
                :background-color="isPersonalizeEvent ? '' : '#f5f6fa'"
                data-e2e="input-city"
                :disabled="!isPersonalizeEvent"
                outlined
                dense
                type="text"
              />
            </v-col>
          </v-row>
          <v-row v-if="!isVirtual">
            <v-col cols="12" md="6">
              <div class="mb-1 black--text">{{ $t('new-event.informations.location') }}</div>
              <v-text-field
                v-model="form.gathering.location"
                :background-color="isPersonalizeEvent ? '' : '#f5f6fa'"
                data-e2e="input-location"
                dense
                :disabled="!isPersonalizeEvent"
                outlined
                type="text"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <div class="mb-1 black--text">{{ $t('new-event.informations.range-date.label') }}*</div>
          <v-menu
            ref="rangeDate"
            v-model="menu1"
            offset-y
            max-width="100%"
            :close-on-content-click="false"
            :close-on-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                @click:append="on.click"
                append-icon="mdi-calendar"
                :background-color="isPersonalizeEvent ? '' : '#f5f6fa'"
                data-e2e="input-range-date"
                :data-vv-as="$t('new-event.informations.range-date.label')"
                data-vv-name="rangeDate"
                data-vv-scope="informations"
                dense
                :disabled="!isPersonalizeEvent"
                :error-messages="[
                  ...errors.collect('rangeDate', 'informations'),
                  ...errors.collect('missEndDate', 'informations')
                ]"
                outlined
                :placeholder="isPersonalizeEvent ? rangeDatePlaceHolder : ''"
                readonly
                ref="rangeDateValidation"
                v-on="on"
                v-validate="`required`"
                :value="rangeDateFormatted"
              />
            </template>
            <v-date-picker
              v-model="rangeDate"
              @change="updateDates"
              data-e2e="range-date-picker"
              first-day-of-week="1"
              locale="fr"
              :min="todayDatePickerFormated"
              no-title
              range
              :value="beginDatePickerFormated"
            >
              <v-btn block text color="primary" @click="validRangeDate">{{
                $t('new-event.informations.range-date.valid-button')
              }}</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <div class="mb-1 black--text">{{ $t('new-event.informations.range-hours.label') }}*</div>
          <div class="d-flex align-center">
            <div class="NewEventInformations-rangeHoursLabel">
              {{ $t('new-event.informations.range-hours.begin-label') }}
            </div>
            <v-col cols="2"
              ><v-select
                @change="updateHours"
                v-model="beginHour"
                data-e2e="select-begin-hour"
                dense
                hide-details
                :items="availableHours"
                :menu-props="{ closeOnContentClick: true }"
                outlined
                v-validate="'required'"
              >
                <template v-slot:item="{ item }">
                  <v-list-item @click="beginHour = item" :data-e2e="`item-begin-hour-${item}`">{{ item }}</v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2"
              ><v-select
                @change="updateHours"
                v-model="beginMinute"
                data-e2e="select-begin-minute"
                dense
                hide-details
                :items="availableMinutes"
                :menu-props="{ closeOnContentClick: true }"
                outlined
                v-validate="'required'"
              >
                <template v-slot:item="{ item }">
                  <v-list-item @click="beginMinute = item" :data-e2e="`item-begin-minute-${item}`">{{
                    item
                  }}</v-list-item>
                </template>
              </v-select>
            </v-col>
          </div>
          <div class="d-flex align-center">
            <div class="NewEventInformations-rangeHoursLabel">
              {{ $t('new-event.informations.range-hours.end-label') }}
            </div>
            <v-col cols="2"
              ><v-select
                @change="updateHours"
                v-model="endHour"
                data-e2e="select-end-hour"
                dense
                hide-details
                :items="availableHours"
                :menu-props="{ closeOnContentClick: true }"
                outlined
                v-validate="'required'"
              >
                <template v-slot:item="{ item }">
                  <v-list-item @click="endHour = item" :data-e2e="`item-end-hour-${item}`">{{ item }}</v-list-item>
                </template>
              </v-select></v-col
            >
            <v-col cols="2"
              ><v-select
                @change="updateHours"
                v-model="endMinute"
                data-e2e="select-end-minute"
                dense
                hide-details
                :items="availableMinutes"
                :menu-props="{ closeOnContentClick: true }"
                outlined
                v-validate="'required'"
              >
                <template v-slot:item="{ item }">
                  <v-list-item @click="endMinute = item" :data-e2e="`item-end-minute-${item}`">{{ item }}</v-list-item>
                </template>
              </v-select></v-col
            >
          </div>
        </v-col>
      </div>
      <v-row>
        <v-col cols="12" md="4" v-if="isCongressEvent">
          <div class="mb-1 black--text">{{ $t`solicitiation-end-date` }}*</div>
          <v-menu ref="solicitiationEndDate" v-model="menuSolicitationEndDate" offset-y>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="solicitationEndDateFormatted"
                v-on="on"
                v-validate="`required|date_format:dd/MM/yyyy|before:${beginDateFormatted},inclusion`"
                data-e2e="input-solicitation-end-date"
                data-vv-name="solicitiationEndDate"
                :data-vv-as="$t`solicitiation-end-date`"
                data-vv-scope="informations"
                :error-messages="errors.collect('solicitiationEndDate', 'informations')"
                placeholder="25/11/2020"
                persistent-hint
                :hint="$t`hint-invitation-end-date`"
                append-icon="mdi-calendar"
                readonly
                outlined
                dense
                @click:append="on.click"
              />
            </template>
            <v-date-picker
              @click:date="date => chooseDate(date, 'congressEvent', 'solicitationEndDate')"
              data-e2e="date-picker-solicitation-end-date"
              :value="solicitationEndDatePickerFormatted"
              locale="fr"
              no-title
              first-day-of-week="1"
            />
          </v-menu>
          <div v-if="solicitationEndDateExceeded" class="orange--text font-weight-medium">
            {{ $t('new-event.hcp-support.solicitation-end-date-exceeded') }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="4">
              <div class="mb-1 black--text">{{ $t`event-logo` }}</div>
              <v-btn
                @click="$refs.logoFile.click()"
                data-e2e="button-add-logo"
                depressed
                x-large
                class="logo-event"
                :style="{ 'background-image': `url(${form.gathering.logo})` }"
              >
                <v-icon v-if="!form.gathering.logo" small>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col v-if="!isVirtual" cols="12" md="6" data-e2e="container-program">
              <div class="mb-1 black--text">{{ $t`event-program` }}</div>
              <div class="mb-2 event-program-caption">{{ $t`event-program-caption` }}</div>
              <base-input-file v-model="form.gathering.program" hide-details />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" v-if="isCongressEvent">
          <div class="mb-1 black--text">{{ $t`congrex-website` }}</div>
          <v-text-field
            v-model="form.congressEvent.websiteUrl"
            placeholder="https://ectrims.eu"
            outlined
            dense
            type="text"
            data-e2e="input-website"
          />
        </v-col>
      </v-row>
    </form>
    <input ref="logoFile" type="file" accept="image/png, image/jpeg" class="d-none" @change="updateDataFile('logo')" />
    <input ref="programFile" type="file" accept=".pdf" class="d-none" @change="updateDataFile('program')" />
  </div>
</template>

<script>
import moment from 'moment'

import { toBase64 } from '@/services/file'
import { biogenEventType, nationalityType } from '@/const/shared'
import { pad } from '@/services/hours'
import NewEventEveniumEventId from '@/components/congrex/NewEventEveniumEventId'
import NewEventId from '@/components/congrex/NewEventId'

export default {
  name: 'NewEventInformations',

  components: { NewEventId, NewEventEveniumEventId },

  inject: ['$validator'],

  model: {
    prop: 'form',
    event: 'change'
  },

  props: {
    isCongressEvent: Boolean,
    form: {
      type: Object,
      deep: true
    }
  },

  data() {
    return {
      beginHour: this.extractEventSchedule('beginHour'),
      beginMinute: this.extractEventSchedule('beginMinute'),
      email: '',
      endHour: this.extractEventSchedule('endHour'),
      endMinute: this.extractEventSchedule('endMinute'),
      eventId: {},
      isValid: false,
      logoData: {},
      menu1: null,
      menu2: null,
      menuSolicitationEndDate: null,
      programName: '',
      rangeDate: [],
      unavailable: false
    }
  },

  computed: {
    availableHours() {
      return new Array(24).fill(1).map((el, i) => pad(i))
    },
    availableMinutes() {
      return new Array(60)
        .fill(1)
        .map((el, i) => pad(i))
        .filter(value => value % 15 === 0)
    },
    beginDateFormatted() {
      return this.form.gathering.beginDate ? moment(this.form.gathering.beginDate).format('DD/MM/YYYY') : ''
    },
    beginDatePickerFormated() {
      return this.form.gathering.beginDate ? moment(this.form.gathering.beginDate).format('YYYY-MM-DD') : ''
    },
    biogenEventType() {
      return biogenEventType
    },
    displaySelectEventId() {
      return this.isVirtual
    },
    isEditMode() {
      return Boolean(this.$get(this.form, 'gathering.gatheringUuid'))
    },
    isPersonalizeEvent() {
      return Boolean(this.eventId && this.eventId.personalizeEvent && this.isVirtual)
    },
    isVirtual() {
      return this.form.gathering.isVirtual
    },
    nationalityType() {
      return nationalityType
    },
    rangeDateFormatted() {
      const endDate = this.form.gathering.endDate ? moment(this.form.gathering.endDate).format('DD/MM/YYYY') : ''

      if (!this.beginDateFormatted && !endDate) return ''
      else if (!endDate) return `${this.beginDateFormatted} - --/--/--`
      else return `${this.beginDateFormatted} - ${endDate}`
    },
    rangeDatePlaceHolder() {
      return this.menu1
        ? '--/--/-- - --/--/--'
        : `${moment(new Date()).format('DD/MM/YYYY')} - ${moment(new Date())
            .add(3, 'd')
            .format('DD/MM/YYYY')}`
    },
    today() {
      return moment(new Date()).format('DD/MM/YYYY')
    },
    todayDatePickerFormated() {
      return moment().format('YYYY-MM-DD')
    },
    solicitationEndDateExceeded() {
      if (!this.$get(this.form, 'congressEvent.solicitationEndDate')) return false

      return (
        new Date() >
        moment(this.form.congressEvent.solicitationEndDate)
          .endOf('day')
          .toDate()
      )
    },
    solicitationEndDateFormatted() {
      return this.form.congressEvent.solicitationEndDate
        ? moment(this.form.congressEvent.solicitationEndDate).format('DD/MM/YYYY')
        : ''
    },
    solicitationEndDatePickerFormatted() {
      return this.form.congressEvent.solicitationEndDate
        ? moment(this.form.congressEvent.solicitationEndDate).format('YYYY-MM-DD')
        : ''
    }
  },

  watch: {
    'form.gathering.beginDate': function(newValue) {
      let beginDate = this.form.gathering.beginDate

      if (!beginDate) return

      if (typeof newValue === 'string') {
        beginDate = new Date(beginDate)
      }

      if (this.isCongressEvent && !this.form.congressEvent.solicitationEndDate) {
        const newDate = new Date(beginDate.getTime())
        // Set date to 6 weeks earlier
        newDate.setDate(newDate.getDate() - 6 * 7)

        this.$set(this.form.congressEvent, 'solicitationEndDate', newDate)
      }
    },
    'form.gathering.personalizeEvent': function(value, prevValue) {
      // Not custom event or stay custom - do nothing
      if (!value || (value && !prevValue)) return

      this.$set(this.form.gathering, 'eveniumEventId', null)
      this.$set(this.form.gathering, 'oseusMeetingId', null)
      this.$set(this.form.gathering, 'eventSchedule', null)
      this.$set(this.form.gathering, 'endDate', null)
      this.$set(this.form.gathering, 'name', null)
      this.beginHour = null
      this.beginMinute = null
      this.endHour = null
      this.endMinute = null
      this.$set(this.form.gathering, 'beginDate', null)
    }
  },

  methods: {
    chooseDate(stringDate, eventType, value) {
      this.$set(this.form[eventType], value, new Date(stringDate))
    },
    chooseEventType(type) {
      this.$emit('change', {
        ...this.form,
        biogenEvent: { ...this.form.biogenEvent, eventType: type }
      })
    },
    chooseNationalityType(type) {
      this.$emit('change', {
        ...this.form,
        congressEvent: { ...this.form.congressEvent, nationalityType: type }
      })
    },
    extractEventSchedule(part) {
      if (!this.form.gathering.eventSchedule || !part) return null

      switch (part) {
        case 'beginHour':
          return this.form.gathering.eventSchedule[0].split(':')[0]
        case 'beginMinute':
          return this.form.gathering.eventSchedule[0].split(':')[1]
        case 'endHour':
          return this.form.gathering.eventSchedule[1].split(':')[0]
        case 'endMinute':
          return this.form.gathering.eventSchedule[1].split(':')[1]
      }
    },
    async updateDataFile(name) {
      const file = this.$refs[`${name}File`].files[0]
      const base64 = await toBase64(file)
      this.$set(this.form.gathering, name, base64)

      if (name === 'program') {
        this.programName = file.name
      }
    },
    updateEventId(event) {
      if (!event) return

      this.eventId = event

      if (event.eventProvider === this.$const.gatheringEventProvider.EVENIUM) {
        this.$set(this.form.gathering, 'oseusMeetingId', null)
        this.$set(this.form.gathering, 'personalizeEvent', false)

        if (event.value !== this.form.gathering.eveniumEventId) {
          const eventSchedule = [
            `${pad(new Date(event.event.startDate).getHours())}:${pad(new Date(event.event.startDate).getMinutes())}`,
            `${pad(new Date(event.event.endDate).getHours())}:${pad(new Date(event.event.endDate).getMinutes())}`
          ]
          this.updateDisplayHours(event.event.startDate, event.event.endDate)
          this.$set(this.form.gathering, 'eventSchedule', eventSchedule)
        }

        this.$set(this.form.gathering, 'beginDate', event.event.startDate)
        this.$set(this.form.gathering, 'endDate', event.event.endDate)
        this.$set(this.form.gathering, 'name', event.event.displayTitle)
        this.$set(this.form.gathering, 'eveniumEventId', event.value)
      } else if (event.eventProvider === this.$const.gatheringEventProvider.OSEUS) {
        this.$set(this.form.gathering, 'eveniumEventId', null)
        this.$set(this.form.gathering, 'personalizeEvent', false)

        if (event.value !== this.form.gathering.oseusMeetingId) {
          const eventSchedule = [
            `${pad(new Date(event.startDate).getHours())}:${pad(new Date(event.startDate).getMinutes())}`,
            `${pad(new Date(event.startDate).getHours())}:${pad(new Date(event.startDate).getMinutes())}`
          ]
          this.updateDisplayHours(event.startDate, event.startDate)
          this.$set(this.form.gathering, 'eventSchedule', eventSchedule)
        }

        this.$set(this.form.gathering, 'oseusMeetingId', event.value)
        this.$set(this.form.gathering, 'name', event.text)
        this.$set(this.form.gathering, 'beginDate', event.startDate)
        this.$set(this.form.gathering, 'endDate', event.startDate)
      } else if (event.personalizeEvent) {
        this.$set(this.form.gathering, 'personalizeEvent', true)
        this.$set(this.form.gathering, 'eveniumEventId', null)
        this.$set(this.form.gathering, 'oseusMeetingId', null)
      }
    },
    updateDates(dates) {
      if (!dates || dates.length === 0) return

      if (dates.length === 1) {
        this.$set(this.form.gathering, 'beginDate', new Date(dates[0]))
      } else {
        const firstValue = new Date(dates[0])
        const secondValue = new Date(dates[1])
        const isInOrder = firstValue < secondValue

        this.$set(this.form.gathering, 'beginDate', isInOrder ? firstValue : secondValue)
        this.$set(this.form.gathering, 'endDate', isInOrder ? secondValue : firstValue)
        this.errors.remove('missEndDate', 'informations')
      }
    },
    updateDisplayHours(pStartDate, pEndDate) {
      const beginDate = pStartDate || this.form.gathering.beginDate
      const endDate = pEndDate || this.form.gathering.endDate
      this.beginHour = pad(new Date(beginDate).getHours())
      this.beginMinute = pad(new Date(beginDate).getMinutes())
      this.endHour = pad(new Date(endDate).getHours())
      this.endMinute = pad(new Date(endDate).getMinutes())
    },
    updateHours() {
      if (this.beginHour === null || this.beginMinute === null) return

      const hours = [`${this.beginHour}:${this.beginMinute}`]

      if (this.endHour !== null && this.endMinute !== null) {
        hours.push(`${this.endHour}:${this.endMinute}`)
      }

      this.$set(this.form.gathering, 'eventSchedule', hours)
    },
    validRangeDate() {
      if (!this.form.gathering.endDate) {
        this.$set(this.form.gathering, 'endDate', this.form.gathering.beginDate)
      }
      this.menu1 = false
    }
  }
}
</script>

<style lang="scss">
.NewEventInformations {
  .logo-event {
    background: center / contain no-repeat #f5f5f5;
    border: 1px dashed #989898;
    width: 100% !important;
    height: 80px !important;
    margin-top: 15px;
  }
  .event-program-caption {
    font-size: 10px;
    line-height: 12px;
  }
  input:disabled,
  .v-select__selection--disabled {
    color: black !important;
  }
}

.NewEventInformations-rangeHoursLabel {
  display: inline-block;
  margin-right: 24px;
  width: 60px;
  color: black;
  font-weight: 600;
}
</style>
