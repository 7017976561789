import gql from 'graphql-tag'

export const GET_GATHERING_HISTORICAL_RECORDS = gql`
  query historicalRecords($gatheringUuid: GUID!, $operationName: [String!]) {
    historicalRecords(gatheringUuid: $gatheringUuid, operationName: $operationName) {
      historicalRecordUuid
      createdAt
      data
      operationName
      user {
        firstName
        lastName
      }
    }
  }
`
