<template>
  <v-container class="NewEvent">
    <v-row>
      <div class="flex-grow-0">
        <v-card class="NewEvent-sidebar">
          <v-btn :to="{ name: 'Congrex' }" text color="congrex-primary" class="subtitle-1 text-lowercase">
            <v-icon>mdi-chevron-left</v-icon>{{ $t`back-to-events` }}
          </v-btn>
          <div>
            <div>
              <v-btn
                @click="$vuetify.goTo('#informations', options)"
                text
                :class="
                  `pl-4 my-1 subtitle-1 text-capitalize ${focusedGroup === 'informations' ? 'font-weight-bold' : ''}`
                "
              >
                <v-icon :color="informationColor" x-small left>
                  {{ informationsValidity ? 'mdi-circle-slice-8' : 'mdi-circle-outline' }}
                </v-icon>
                <span :class="informationsValidity === false ? 'error--text' : ''">{{ $t`informations` }}</span>
              </v-btn>
            </div>
            <div>
              <v-btn
                @click="$vuetify.goTo('#teamProject', options)"
                text
                :class="
                  `pl-4 my-1 subtitle-1 text-capitalize ${focusedGroup === 'teamProject' ? 'font-weight-bold' : ''}`
                "
              >
                <v-icon :color="teamProjectColor" x-small left>
                  {{ teamProjectValidity ? 'mdi-circle-slice-8' : 'mdi-circle-outline' }}
                </v-icon>
                <span :class="teamProjectValidity === false ? 'error--text' : ''">{{ $t`team-project` }}</span>
              </v-btn>
            </div>
            <div>
              <v-btn
                @click="$vuetify.goTo('#hcpSupport', options)"
                text
                :class="
                  `pl-4 my-1 subtitle-1 text-capitalize ${focusedGroup === 'hcpSupport' ? 'font-weight-bold' : ''}`
                "
              >
                <v-icon :color="hcpSupportColor" x-small left>
                  {{ hcpSupportValidity ? 'mdi-circle-slice-8' : 'mdi-circle-outline' }}
                </v-icon>
                <span :class="hcpSupportValidity === false ? 'error--text' : ''">{{ $t`hcp-support` }}</span>
              </v-btn>
            </div>
            <div v-if="isCongressEvent">
              <v-btn
                @click="$vuetify.goTo('#biogenPresence', options)"
                text
                :class="
                  `pl-4 my-1 subtitle-1 text-capitalize ${focusedGroup === 'biogenPresence' ? 'font-weight-bold' : ''}`
                "
              >
                <v-icon :color="biogenPresenceColor" x-small left>
                  {{ biogenPresenceValidity ? 'mdi-circle-slice-8' : 'mdi-circle-outline' }}
                </v-icon>
                <span :class="biogenPresenceValidity === false ? 'error--text' : ''">{{ $t`biogen-presence` }}</span>
              </v-btn>
            </div>
          </div>
          <div>
            <v-row v-if="displaySaveToDraftButton" no-gutters class="mb-2">
              <v-col cols="12">
                <v-btn
                  @click="saveEvent($const.gatheringStatus.DRAFT)"
                  data-e2e="button-save-to-draft"
                  width="100%"
                  color="congrex-primary"
                  class="text-none"
                  outlined
                >
                  {{ $t`save-draft` }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="displaySendToValidateButton" no-gutters class="mb-2">
              <v-col cols="12">
                <v-btn
                  @click="saveEvent($const.gatheringStatus.TO_VALIDATE)"
                  width="100%"
                  depressed
                  dark
                  data-e2e="button-send-to-validate-event"
                  color="congrex-primary"
                  class="text-none"
                >
                  {{ $t`send-to-validation` }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="displayPublishButton" no-gutters>
              <v-col cols="12">
                <base-confirm-modal
                  :title="$t`confirm-title-no-publish`"
                  :text="$t`confirm-text-no-publish`"
                  v-model="modalModifiedEvent"
                  @confirm="saveEvent($const.gatheringStatus.TO_VALIDATE)"
                />
                <base-confirm-modal
                  :title="$t('new-event.publish-modal.title')"
                  :text="$t('new-event.publish-modal.text')"
                  v-model="modalPublish"
                  @confirm="publish"
                />
                <v-btn
                  @click="validatePublish"
                  width="100%"
                  color="congrex-primary"
                  dark
                  data-e2e="button-publish-event"
                  depressed
                  class="text-none"
                >
                  {{ $t`publish` }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="displayUpdateEvent" no-gutters>
              <v-col cols="12" class="mt-3">
                <v-btn
                  @click="validateUpdateEvent"
                  width="100%"
                  color="congrex-primary"
                  depressed
                  dark
                  class="text-none"
                >
                  {{ $t('new-event.update-button') }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
      <v-col class="relative pt-0">
        <v-overlay v-if="isEditMode && loading.queryGathering" absolute light opacity="0">
          <v-progress-circular indeterminate size="64" color="congrex-primary" />
        </v-overlay>
        <template v-else>
          <HistoricalRecord v-for="(record, i) in historicalRecords" :key="i" :historicalRecord="record" />
          <v-card class="pa-5">
            <div class="d-flex align-center mt-2 mb-8">
              <div class="display-1 congrex-primary--text" id="informations" ref="informations">
                {{ isCongressEvent ? $t`congress` : $t`biogen-event` }}
              </div>
              <v-spacer />
              <div>
                <v-radio-group @change="preChangeVirtualMode" dense row :value="form.gathering.isVirtual">
                  <v-radio
                    v-for="el in isVirtualValues"
                    class="d-inline"
                    color="success"
                    :data-e2e="`button-${el.value ? 'virtual' : 'present'}-event`"
                    :key="el.value"
                    :label="el.text"
                    :value="el.value"
                  />
                </v-radio-group>
              </div>
            </div>
            <section>
              <NewEventInformations
                v-model="form"
                @validity="v => (informationsValidity = v)"
                :isCongressEvent="isCongressEvent"
              />
            </section>
            <section class="mt-11" id="teamProject" ref="teamProject">
              <NewEventTeamProject
                v-model="form"
                @validity="v => (teamProjectValidity = v)"
                :isCongressEvent="isCongressEvent"
                :sensibleDataFields="sensibleDataFields"
              />
            </section>
            <section class="mt-11" id="hcpSupport" ref="hcpSupport">
              <NewEventHcpSupport
                v-model="form"
                :isCongressEvent="isCongressEvent"
                :sensibleDataFields="sensibleDataFields"
                :totalFilled="$get(queryGathering, 'congress.stats.totalFilled')"
              >
                <template v-slot:quotaAllocation>
                  <div :class="{ 'NewEvent-sensibleDataBlock': sensibleDataFields.includes('zoneCongresses') }">
                    <QuotaAllocations
                      :zoneCongresses="
                        $get(queryGathering, 'congress.zoneCongresses', []).map(el => ({
                          zoneUuid: el.zoneUuid,
                          quota: el.zoneQuota
                        }))
                      "
                      v-model="form.congressEvent.zoneCongresses"
                      :buUuids="form.gathering.businessUnitUuids"
                      :maximalQuota="form.congressEvent.maximalQuota || 0"
                      :stats="queryGathering.congress && queryGathering.congress.stats"
                    />
                  </div>
                </template>
              </NewEventHcpSupport>
            </section>
          </v-card>
          <v-card v-if="isCongressEvent" class="pa-5 mt-8" id="biogenPresence" ref="biogenPresence">
            <NewEventBiogenPresence v-model="form" :isCongressEvent="isCongressEvent" />
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-dialog v-model="modalUpdateEvent" max-width="600">
      <v-card>
        <v-card-title>{{ $t('new-event.update-modal.title') }}</v-card-title>
        <v-card-text v-if="updateElements.length > 0">
          <p class="mb-1">{{ $t('new-event.update-modal.message') }}</p>
          <p v-for="element in updateElements" :key="element" class="pl-2 mb-0 font-weight-medium">- {{ element }}</p>
          <p class="mt-5 mb-1">{{ $t('new-event.update-modal.label-comment') }}</p>
          <v-textarea
            v-model="updateComment"
            outlined
            auto-grow
            v-validate.immediate="'required'"
            data-vv-name="comment"
            :data-vv-as="$t`comment`"
            :error-messages="errors.collect('comment')"
          />
        </v-card-text>
        <v-card-text v-else>{{ $t('new-event.update-modal.default-message') }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn @click="modalUpdateEvent = false" depressed text color="error">{{ $t`cancel` }}</v-btn>
          <v-btn
            @click="saveEvent($const.gatheringStatus.PUBLISHED)"
            :disabled="updateElements.length > 0 && !updateComment"
            depressed
            text
            color="success"
          >
            {{ $t`validate` }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalPostPublishing" max-width="600">
      <v-card>
        <v-card-title>{{ $t('new-event.post-publishing-modal.title') }}</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="updateComment"
            outlined
            auto-grow
            v-validate.immediate="'required'"
            data-vv-name="comment"
            :data-vv-as="$t`comment`"
            :error-messages="errors.collect('comment')"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn @click="modalPostPublishing = false" depressed text color="error">{{ $t`cancel` }}</v-btn>
          <v-btn
            @click="saveEvent($const.gatheringStatus.PUBLISHED)"
            :disabled="!updateComment"
            depressed
            text
            color="success"
          >
            {{ $t`validate` }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmModal
      v-if="modalVirtualComment"
      @cancel="cancelVirtualCommentModal"
      @confirm="changeVirtualMode"
      :dialog="modalVirtualComment"
      :disableValidate="!virtualComment"
      :title="$t('new-event.virtual-modal.title')"
      :text="$t('new-event.virtual-modal.text')"
      persistent
    >
      <template v-slot:text>
        <v-textarea
          v-model="virtualComment"
          autofocus
          auto-grow
          class="mt-4"
          data-vv-name="comment"
          :data-vv-as="$t`comment`"
          :error-messages="errors.collect('comment')"
          outlined
          v-validate.immediate="'required'"
        />
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import _isEqual from 'lodash/isEqual'

import NewEventInformations from '@/components/congrex/NewEventInformations'
import NewEventTeamProject from '@/components/congrex/NewEventTeamProject'
import NewEventHcpSupport from '@/components/congrex/NewEventHcpSupport'
import NewEventBiogenPresence from '@/components/congrex/NewEventBiogenPresence'
import HistoricalRecord from '@/components/congrex/HistoricalRecord'
import QuotaAllocations from '@/components/congrex/QuotaAllocations'
import {
  GET_GATHERING_EVENT_GENERAL_FOR_EDIT,
  GET_GATHERING_EVENT_GENERAL_FOR_EDIT_WITHOUT_HISTORICAL_RECORD
} from '@/graphql/Gatherings/GetGathering'
import ConfirmModal from '@/components/base/ConfirmModal'
import { UPDATE_CONGRESS_EVENT } from '@/graphql/Event/CongressEvent/UpdateCongressEvent'
import { UPDATE_BIOGEN_EVENT } from '@/graphql/Event/BiogenEvent/UpdateBiogenEvent'
import { CREATE_CONGRESS_EVENT } from '@/graphql/Event/CongressEvent/CreateCongressEvent'
import { CREATE_BIOGEN_EVENT } from '@/graphql/Event/BiogenEvent/CreateBiogenEvent'
import { PUBLISH_GATHERING } from '@/graphql/Gatherings/PublishGathering'
import CONST from '@/const/shared'

let initData = {}

export default {
  name: 'NewEvent',

  $_veeValidate: {
    validator: 'new'
  },

  components: {
    ConfirmModal,
    QuotaAllocations,
    NewEventBiogenPresence,
    NewEventHcpSupport,
    NewEventInformations,
    NewEventTeamProject,
    HistoricalRecord
  },

  data() {
    return {
      biogenPresenceValidity: null,
      focusedGroup: 'informations',
      form: {
        biogenEvent: {
          hasSpecialtyQuota: true, // TODO
          invitationEndDate: ''
        },
        congressEvent: {
          criterias: [],
          hasZoneQuota: false,
          zoneCongresses: []
        },
        gathering: {
          businessUnitUuids: [],
          isVirtual: false,
          personalizeEvent: false,
          status: CONST.gatheringStatus.DRAFT
        }
      },
      hcpSupportValidity: null,
      informationsValidity: null,
      loading: {
        queryGathering: false
      },
      modalModifiedEvent: false,
      modalPostPublishing: false,
      modalPublish: false,
      modalUpdateEvent: false,
      modalVirtualComment: false,
      queryGathering: {},
      sensibleDataFields: [],
      teamProjectValidity: null,
      updateComment: '',
      updateElements: [],
      virtualComment: ''
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    biogenPresenceColor() {
      return this.setColotStatus(this.biogenPresenceValidity)
    },
    displayPublishButton() {
      return (
        this.queryGathering.status === this.$const.gatheringStatus.TO_PUBLISH &&
        this.hasRight(this.$const.right.publishGathering)
      )
    },
    displaySendToValidateButton() {
      return (
        !this.loading.queryGathering &&
        this.queryGathering.status !== this.$const.gatheringStatus.PUBLISHED &&
        this.hasRight(this.$const.right.toValidateGathering)
      )
    },
    displayUpdateEvent() {
      return this.queryGathering.status === this.$const.gatheringStatus.PUBLISHED
    },
    displaySaveToDraftButton() {
      if (this.loading.queryGathering || this.queryGathering.status === this.$const.gatheringStatus.PUBLISHED)
        return false
      if (this.isEditMode && this.isCongressEvent && !this.hasRight(this.$const.right.updateCongress)) return false
      if (this.isEditMode && !this.isCongressEvent && !this.hasRight(this.$const.right.updateBiogenEvent)) return false
      return true
    },
    historicalRecords() {
      if (!this.$get(this.queryGathering, 'historicalRecords[0]')) return []

      return this.queryGathering.historicalRecords
        .filter(item => Boolean(item.data.comment))
        .sort((a, b) => {
          if (b.createdAt < a.createdAt) return -1
          if (a.createdAt < b.createdAt) return 1
          return 0
        })
    },
    informationColor() {
      return this.setColotStatus(this.informationsValidity)
    },
    isCongressEvent() {
      return this.$route.params.eventType === 'congress'
    },
    isEditMode() {
      return this.$route.name === 'EditEvent'
    },
    isPersonalizeEvent() {
      return Boolean(this.isVirtual && !this.form.gathering.eveniumEventId && !this.form.gathering.oseusMeetingId)
    },
    isVirtual() {
      return this.form.gathering.isVirtual
    },
    isVirtualValues() {
      return [
        { value: false, text: this.$t('new-event.physical-event') },
        { value: true, text: this.$t('new-event.virtual-event') }
      ]
    },
    options() {
      return { duration: 900, easing: 'easeInOutCubic' }
    },
    teamProjectColor() {
      return this.setColotStatus(this.teamProjectValidity)
    },
    hcpSupportColor() {
      return this.setColotStatus(this.hcpSupportValidity)
    }
  },

  apollo: {
    queryGathering: {
      query() {
        return this.$store.getters.hasRight(this.$const.right.readHistoricalRecord)
          ? GET_GATHERING_EVENT_GENERAL_FOR_EDIT
          : GET_GATHERING_EVENT_GENERAL_FOR_EDIT_WITHOUT_HISTORICAL_RECORD
      },
      variables() {
        this.loading.queryGathering = true
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      update: ({ gathering }) => gathering,
      result({ data: { gathering } }) {
        const variables = this.formatedDataToMutate(gathering)
        initData = { ...JSON.parse(JSON.stringify(variables)) }
        this.form = JSON.parse(JSON.stringify(variables))
        this.loading.queryGathering = false
      },
      skip() {
        return Boolean(!this.isEditMode)
      }
    }
  },

  watch: {
    'queryGathering.status'() {
      // Update with sensible data
      if (this.$route.query.changes) {
        const sensibleData = JSON.parse(atob(this.$route.query.changes))
        const sensibleGatheringFields = ['businessUnitUuids']
        const sensibleCongressFields = [
          'criterias',
          'hasAutomatedValidation',
          'hasZoneQuota',
          'maximalQuota',
          'otherQuota',
          'zoneCongresses'
        ]
        const sensibleBiogenFields = ['availablePlace']

        sensibleGatheringFields.forEach(field => {
          if (sensibleData[field] !== undefined) {
            this.form.gathering[field] = sensibleData[field]
          }
        })

        if (this.isCongressEvent) {
          sensibleCongressFields.forEach(field => {
            if (sensibleData[field] !== undefined) {
              this.form.congressEvent[field] = sensibleData[field]
            }
          })
        } else {
          sensibleBiogenFields.forEach(field => {
            if (sensibleData[field] !== undefined) {
              this.form.biogenEvent[field] = sensibleData[field]
            }
          })
        }

        this.sensibleDataFields = Object.keys(sensibleData)
      }
    }
  },

  mounted() {
    if (this.isCongressEvent) {
      this.form.congressEvent.nationalityType = this.$const.nationalityType.INTERNATIONAL
    } else {
      this.form.biogenEvent.eventType = this.$const.biogenEventType.MEDICAL
    }
    document.addEventListener('scroll', this.handleScroll)
  },

  destroyed() {
    document.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    ...mapMutations({
      setModalMessage: 'SET_MODAL_MESSAGE'
    }),
    cancelVirtualCommentModal() {
      this.modalVirtualComment = false
      this.form.gathering.isVirtual = !this.form.gathering.isVirtual

      const fixRadioButton = () => {
        this.form.gathering.isVirtual = !this.form.gathering.isVirtual
      }
      setTimeout(fixRadioButton, 1)
    },
    changeVirtualMode() {
      if (!this.form.gathering.isVirtual) {
        this.deleteFieldsToVirtualEvent()
      }

      this.modalVirtualComment = false
      this.form.gathering.isVirtual = !this.form.gathering.isVirtual
    },
    deleteFieldsToVirtualEvent() {
      delete this.form.gathering.country
      delete this.form.gathering.city
      delete this.form.gathering.location

      if (this.isCongressEvent) {
        delete this.form.congressEvent.hasBiogenStand
      }
    },
    formatToGatheringCreationInput(gathering) {
      return {
        beginDate: gathering.beginDate,
        businessUnitUuids: gathering.businessUnitUuids,
        city: gathering.city,
        country: gathering.country,
        description: gathering.description,
        endDate: gathering.endDate,
        eveniumEventId: gathering.eveniumEventId,
        eventSchedule: gathering.eventSchedule,
        inChargeMarketingUuid: gathering.inChargeMarketingUuid,
        inChargeMciUuid: gathering.inChargeMciUuid,
        inChargeMedicalUuid: gathering.inChargeMedicalUuid,
        inChargeRocUuid: gathering.inChargeRocUuid,
        isVirtual: gathering.isVirtual,
        location: gathering.location,
        logo: gathering.logo,
        lvcGlobal: gathering.lvcGlobal,
        lvcLocal: gathering.lvcLocal,
        lvcMilitary: gathering.lvcMilitary,
        name: gathering.name,
        program: gathering.program,
        oseusMeetingId: gathering.oseusMeetingId,
        projectManagerUuid: gathering.projectManagerUuid,
        status: gathering.status
      }
    },
    formatedDataToMutate(data) {
      const { biogenEvent, congress, ...gathering } = data
      const form = {}
      form.gathering = {
        gatheringUuid: gathering.gatheringUuid,
        beginDate: gathering.beginDate,
        businessUnitUuids: gathering.businessUnits.map(bu => bu.businessUnitUuid),
        city: gathering.city,
        isVirtual: gathering.isVirtual,
        country: gathering.country,
        location: gathering.location,
        description: gathering.description,
        endDate: gathering.endDate,
        eveniumEventId: gathering.eveniumEventId,
        eventSchedule: gathering.eventSchedule,
        inChargeMarketingUuid: gathering.inChargeMarketing && gathering.inChargeMarketing.userUuid,
        inChargeMciUuid: gathering.inChargeMci && gathering.inChargeMci.userUuid,
        inChargeMedicalUuid: gathering.inChargeMedical && gathering.inChargeMedical.userUuid,
        inChargeRocUuid: gathering.inChargeRoc && gathering.inChargeRoc.userUuid,
        logo: gathering.logo,
        lvcGlobal: gathering.lvcGlobal,
        lvcLocal: gathering.lvcLocal,
        lvcMilitary: gathering.lvcMilitary,
        name: gathering.name,
        oseusMeetingId: gathering.oseusMeetingId,
        program: gathering.program,
        projectManagerUuid: gathering.projectManager && gathering.projectManager.userUuid,
        visioUrl: gathering.visioUrl
      }

      if (congress) {
        form.congressEvent = {
          criterias: congress.criterias,
          hasAutomatedValidation: congress.hasAutomatedValidation,
          hasBiogenStand: congress.hasBiogenStand,
          hasBiogenSymposium: congress.hasBiogenSymposium,
          hasBiogenWorkshop: congress.hasBiogenWorkshop,
          hasZoneQuota: congress.hasZoneQuota,
          nationalityType: congress.nationalityType,
          maximalQuota: congress.maximalQuota,
          medicalDirectorUuid: congress.medicalDirectorUuid,
          otherQuota: congress.otherQuota || 0,
          solicitationEndDate: congress.solicitationEndDate,
          sponsorships: congress.sponsorships,
          symposiumProgram: congress.symposiumProgram,
          websiteUrl: congress.websiteUrl,
          zoneCongresses: []
        }

        if (congress.zoneCongresses.length > 0) {
          form.congressEvent.zoneCongresses = congress.zoneCongresses.map(zone => ({
            zoneUuid: zone.zoneUuid,
            quota: zone.zoneQuota
          }))
        }
      } else {
        form.biogenEvent = {
          availablePlace: biogenEvent.availablePlace,
          eventType: biogenEvent.eventType,
          hasSpecialtyQuota: biogenEvent.hasSpecialtyQuota,
          invitationEndDate: biogenEvent.invitationEndDate,
          invitationType: biogenEvent.invitationType
        }
      }

      return form
    },
    preChangeVirtualMode(value) {
      if (value === undefined) return

      if (this.isEditMode) {
        this.modalVirtualComment = true
      } else {
        if (value) {
          this.deleteFieldsToVirtualEvent()
        }

        this.changeVirtualMode()
      }
    },
    async publish() {
      await this.$apollo.mutate({
        mutation: PUBLISH_GATHERING,
        variables: { gatheringUuid: this.$route.params.gatheringUuid }
      })

      this.setModalMessage({ display: true, text: this.$t`message-published-event`, prevPage: true })
    },
    async saveEvent(gatheringStatus) {
      const validated = await this.validateAll()
      if (!validated) return

      let mutation
      const variables = this.isCongressEvent
        ? { gathering: { ...this.form.gathering }, congressEvent: { ...this.form.congressEvent } }
        : { gathering: { ...this.form.gathering }, biogenEvent: { ...this.form.biogenEvent } }

      // TODO - move this to format function
      delete variables.gathering.personalizeEvent

      if (
        variables.congressEvent &&
        !variables.congressEvent.hasZoneQuota &&
        variables.congressEvent.zoneCongresses &&
        variables.congressEvent.zoneCongresses.length > 0
      ) {
        variables.congressEvent.zoneCongresses = []
      }

      if (this.isEditMode) {
        mutation = this.isCongressEvent ? UPDATE_CONGRESS_EVENT : UPDATE_BIOGEN_EVENT

        if (this.updateComment || this.virtualComment) {
          variables.comment = this.updateComment || this.virtualComment
        }
      } else {
        variables.gathering = this.formatToGatheringCreationInput(this.form.gathering)
        mutation = this.isCongressEvent ? CREATE_CONGRESS_EVENT : CREATE_BIOGEN_EVENT
      }

      variables.gathering.status = gatheringStatus

      try {
        await this.$apollo.mutate({
          mutation: mutation,
          variables: variables
        })

        if (gatheringStatus === this.$const.gatheringStatus.DRAFT) {
          this.setModalMessage({ display: true, text: this.$t`message-saved-draft`, prevPage: true })
        } else if (this.modalPostPublishing) {
          this.setModalMessage({
            display: true,
            text: this.isCongressEvent
              ? this.$t('new-event.modal-message.update-event-with-sensible-data.dm')
              : this.$t('new-event.modal-message.update-event-with-sensible-data.cp'),
            prevPage: true
          })
        } else if (gatheringStatus === this.$const.gatheringStatus.PUBLISHED) {
          this.setModalMessage({
            display: true,
            text: this.$t('new-event.modal-message.update-event'),
            prevPage: true
          })
        } else {
          this.setModalMessage({
            display: true,
            text: this.isCongressEvent
              ? this.$t`message-send-to-dm-validation`
              : this.$t`message-send-to-cp-validation`,
            prevPage: true
          })
        }
      } catch (error) {
        console.error("l'enregistrement a échoué : ", error)
      }
    },
    setColotStatus(value) {
      if (value) {
        return 'success'
      }
      if (value === false) {
        return 'error'
      }
      return ''
    },
    async validateAll() {

      if (this.form.gathering.businessUnitUuids.length === 0 && !this.errors.has('bus', 'teamProject')) {
        this.errors.add({
          field: 'bus',
          msg: this.$t`bu-required`,
          scope: 'teamProject'
        })
      } else if (this.form.gathering.businessUnitUuids.length > 0) {
        this.errors.remove('bus', 'teamProject')
      }

      if (this.isCongressEvent) {
        const { congressEvent } = this.form

        if (
          congressEvent.criterias.length === 0 &&
          !this.errors.has('criterias', 'hcpSupport') &&
          congressEvent.hasAutomatedValidation
        ) {
          this.errors.add({
            field: 'criterias',
            msg: this.$t`criterias-required`,
            scope: 'hcpSupport'
          })
        } else if (congressEvent.criterias.length > 0 || !congressEvent.hasAutomatedValidation) {
          this.errors.remove('criterias', 'hcpSupport')
        }
      }

      if (this.isPersonalizeEvent) {
        if (!this.form.gathering.endDate && !this.errors.has('missEndDate', 'informations')) {
          this.errors.add({
            field: 'missEndDate',
            msg: this.$t('new-event.error-message.missing-end-date'),
            scope: 'informations'
          })
        }
      }

      if (this.form.gathering.endDate) {
        this.errors.remove('missEndDate', 'informations')
      }

      await this.$validator.validateAll('informations')
      await this.$validator.validateAll('teamProject')
      await this.$validator.validateAll('hcpSupport')

      if (this.isCongressEvent) {
        const { congressEvent } = this.form
        if (!congressEvent.maximalQuota && !congressEvent.otherQuota) {
          if (!this.errors.has('quota', 'hcpSupport')) {
            this.errors.add({
              field: 'quota',
              msg: this.$t('new-event.hcp-support.hcpQuota.maximumQuotaError'),
              scope: 'hcpSupport'
            })
          }
        } else {
          this.errors.remove('quota', 'hcpSupport')
        }
      }

      this.informationsValidity = !this.errors.any('informations')
      this.teamProjectValidity = !this.errors.any('teamProject')
      this.hcpSupportValidity = !this.errors.any('hcpSupport')
      this.biogenPresenceValidity = await this.$validator.validateAll('biogenPresence')

      return (
        this.informationsValidity &&
        this.teamProjectValidity &&
        this.hcpSupportValidity &&
        this.biogenPresenceValidity
      )
    },
    validatePublish() {
      const modified = Object.keys(this.fields).some(key =>
        Object.keys(this.fields[key]).some(keyField => this.fields[key][keyField].changed)
      )

      if (modified && this.queryGathering.status !== this.$const.gatheringStatus.PUBLISHED) {
        this.modalModifiedEvent = true
        return
      }

      this.modalPublish = true
    },
    async validateUpdateEvent() {
      const validated = await this.validateAll()
      if (!validated) return

      this.updateElements = []
      if (this.isCongressEvent) {
        // Update published event not by event's DM
        if (
          this.queryGathering.congress.medicalDirectorUuid !== this.$store.state.myUser.userUuid &&
          this.queryGathering.status === this.$const.gatheringStatus.PUBLISHED &&
          (!_isEqual(this.form.congressEvent.maximalQuota, initData.congressEvent.maximalQuota) ||
            !_isEqual(this.form.gathering.businessUnitUuids, initData.gathering.businessUnitUuids) ||
            !_isEqual(this.form.congressEvent.zoneCongresses, initData.congressEvent.zoneCongresses) ||
            !_isEqual(this.form.congressEvent.criterias, initData.congressEvent.criterias))
        ) {
          this.modalPostPublishing = true
          return
        }

        if (
          JSON.stringify(this.form.congressEvent.zoneCongresses) !==
          JSON.stringify(initData.congressEvent.zoneCongresses)
        ) {
          this.updateElements.push(this.$t('new-event.update-modal.quota-message'))
        }

        if (this.form.congressEvent.maximalQuota !== initData.congressEvent.maximalQuota) {
          this.updateElements.push(this.$t('new-event.update-modal.maximal-quota-message'))
        }

        if (!this.form.congressEvent.criterias.every(criteria => initData.congressEvent.criterias.includes(criteria))) {
          this.updateElements.push(this.$t('new-event.update-modal.criteria-message'))
        }
      } else {
        if (
          this.queryGathering.projectManagerUuid !== this.$store.state.myUser.userUuid &&
          this.queryGathering.status === this.$const.gatheringStatus.PUBLISHED &&
          (!_isEqual(this.form.gathering.businessUnitUuids, initData.gathering.businessUnitUuids) ||
            this.form.biogenEvent.availablePlace !== initData.biogenEvent.availablePlace)
        ) {
          this.modalPostPublishing = true
          return
        }

        if (!_isEqual(this.form.gathering.businessUnitUuids, initData.gathering.businessUnitUuids)) {
          this.updateElements.push(this.$t('new-event.update-modal.business-unit'))
        }

        if (this.form.biogenEvent.availablePlace !== initData.biogenEvent.availablePlace) {
          this.updateElements.push(this.$t('new-event.update-modal.available-place-message'))
        }
      }

      this.modalUpdateEvent = true
    },
    handleScroll(event) {
      const elements = {
        informations: this.$refs.informations,
        teamProject: this.$refs.teamProject,
        hcpSupport: this.$refs.hcpSupport,
        biogenPresence: this.$refs.biogenPresence
      }

      const windowOffsetTop = window.scrollY + window.screen.height * 0.75
      const visibleElements = []

      Object.keys(elements).forEach(key => {
        const element = elements[key]

        if (!element) return

        if (element.offsetTop < windowOffsetTop) {
          visibleElements.push(key)
        }
      })

      this.focusedGroup = visibleElements.pop()
    }
  }
}
</script>

<style lang="scss">
.NewEvent-sensibleDataField fieldset {
  border: 3px solid var(--v-success-base) !important;
}

.NewEvent-sensibleDataField input {
  color: var(--v-success-base) !important;
}

.NewEvent-sensibleDataBlock {
  padding: 8px;
  border: 3px solid var(--v-success-base) !important;
  border-radius: 4px;
}
</style>

<style scoped lang="scss">
.NewEvent {
  max-width: 1200px;

  .sticky {
    position: sticky;
    top: 90px;
  }
}

.NewEvent-sidebar {
  position: sticky;
  top: 68px;
  height: 450px;
  max-height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 20px;
  overflow: auto;
}
</style>
