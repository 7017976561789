<template>
  <div>
    <v-card-title v-if="doctorContext">{{
      displaySelectZone
        ? $t('force-solicitation-validation.choose-step.title')
        : $t('force-solicitation-validation.choose-step.title-global-quota')
    }}</v-card-title>
    <v-card-title v-else>{{ $t('force-solicitation-validation.choose-step.title-other-quota') }}</v-card-title>
    <v-card-text class="py-12 px-10">
      <v-btn
        @click="$emit('step', 1)"
        block
        class="mb-6 text-none text-left"
        color="congrex-primary"
        dark
        depressed
        v-cy="$cy.congrex.solicitation.validation.choice.globalButton"
      >
        <span v-if="doctorContext" v-html="$t('force-solicitation-validation.choose-step.increase-global-button')" />
        <span v-else v-html="$t('force-solicitation-validation.choose-step.increase-other-button')" />
      </v-btn>
      <v-btn
        v-if="displaySelectZone"
        @click="$emit('step', 2)"
        block
        class="text-none text-left"
        color="congrex-primary"
        dark
        v-cy="$cy.congrex.solicitation.validation.choice.zoneButton"
        depressed
      >
        {{ $t('force-solicitation-validation.choose-step.select-zone-button') }}
      </v-btn>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'ChooseStep',

  props: {
    displaySelectZone: {
      type: Boolean,
      default: true
    },
    doctorContext: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped></style>
