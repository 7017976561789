<template>
  <div>
    <div class="mb-1 black--text">{{ $t('new-event-id.event-id') }}*</div>
    <div class="d-flex">
      <v-select
        :value="eventId"
        @change="$emit('change', $event)"
        data-e2e="select-evenium-id"
        data-vv-name="eventId"
        :data-vv-as="$t`event-id`"
        data-vv-scope="informations"
        dense
        :error-messages="errors.collect('eventId', 'informations')"
        hide-details="auto"
        :items="items"
        :loading="!items || loading.oseusEvents || loading.eveniumEvents"
        outlined
        return-object
        v-validate="'required'"
      >
        <template v-slot:item="{ item }">
          <span v-if="item.personalizeEvent" class="NewEventId-personalizeEventButton">{{
            $t('new-event-id.new-custom-event')
          }}</span>
          <span v-else>{{ item.text }}</span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import _orderBy from 'lodash/orderBy'
import moment from 'moment'

import { GET_ID_EVENIUM_EVENTS } from '@/graphql/EveniumEvent/GetEveniumEvents'
import { GET_ID_OSEUS_EVENTS } from '@/graphql/OseusEvent/GetOseusEvents'

export default {
  name: 'NewEventId',

  inject: ['$validator'],

  model: {
    prop: 'eventId',
    event: 'change'
  },

  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    eventId: Object,
    form: Object,
    isCongressEvent: Boolean
  },

  data() {
    return {
      eveniumEvents: null,
      loading: {
        oseusEvents: false,
        eveniumEvents: false
      },
      oseusEvents: null
    }
  },

  computed: {
    items() {
      const items = []
      if (!this.isCongressEvent) {
        items.push({ text: this.$t('new-event-id.custom-event'), personalizeEvent: true })
      }

      if (this.eveniumEvents) {
        if (items.length > 0) {
          items.push({ divider: true })
        }

        items.push({ header: this.$t('new-event-id.evenium-header') })
        items.push(
          ..._orderBy(
            this.eveniumEvents.map(event => ({
              event,
              eventProvider: this.$const.gatheringEventProvider.EVENIUM,
              text: event.displayTitle,
              value: event.id
            })),
            'text'
          )
        )
      }

      if (this.oseusEvents) {
        if (items.length > 0) {
          items.push({ divider: true })
        }

        items.push({ header: this.$t('new-event-id.oseus-header') })

        const meetings = _orderBy(this.oseusEvents.map(oseusEvent => oseusEvent.meetings).flat(), 'startDate')

        items.push(
          ...meetings.map(event => ({
            ...event,
            eventProvider: this.$const.gatheringEventProvider.OSEUS,
            text: `${event.theme} - ${moment(new Date(event.startDate)).format('LLL')}`,
            value: event.oseusMeetingId
          }))
        )
      }

      return items
    }
  },

  apollo: {
    eveniumEvents: {
      query: GET_ID_EVENIUM_EVENTS,
      result({ data }) {
        if (this.form.gathering.eveniumEventId) {
          const eventId = data.eveniumEvents.find(event => event.eveniumEventId === this.form.gathering.eveniumEventId)
          this.$emit('change', eventId)
        }
        this.loading.eveniumEvents = false
      },
      variables() {
        this.loading.eveniumEvents = true
        return { alreadyUsed: this.editMode, startAfter: new Date() }
      }
    },
    oseusEvents: {
      query: GET_ID_OSEUS_EVENTS,
      result({ data }) {
        if (this.form.gathering.oseusMeetingId) {
          const eventId = data.oseusEvents.find(event => event.oseusMeetingId === this.form.gathering.oseusMeetingId)
          this.$emit('change', eventId)
        }
        this.loading.oseusEvents = false
      },
      variables() {
        this.loading.oseusEvents = true
        return { startAfter: new Date() }
      }
    }
  },

  mounted() {
    if (!this.form.gathering.oseusMeetingId && !this.form.gathering.eveniumEventId) {
      this.$emit('change', this.items[0])
    }
  },

  watch: {
    items(items) {
      if (!items) return

      const providedEventId = this.form.gathering.eveniumEventId || this.form.gathering.oseusMeetingId

      if (!providedEventId) return

      const event = items.find(item => item.value === providedEventId)
      this.$emit('change', event)
    }
  }
}
</script>

<style lang="scss">
.v-subheader {
  font-weight: 600 !important;
}
</style>

<style lang="scss" scoped>
.NewEventId-personalizeEventButton {
  display: flex;
  align-items: center;
  padding-left: 16px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  color: var(--v-primary-base);
  font-weight: 700;
}
</style>
