import gql from 'graphql-tag'

export const CONFIRM_SOLICITATION = gql`
  mutation confirmSolicitation($decrementedZoneUuid: GUID, $solicitationUuid: GUID!, $quotaComment: String, $chosenZoneUuid: GUID) {
    confirmSolicitation(
      decrementedZoneUuid: $decrementedZoneUuid
      solicitationUuid: $solicitationUuid
      quotaComment: $quotaComment
      chosenZoneUuid: $chosenZoneUuid
    ) {
      status
    }
  }
`
