<template>
  <div class="SolicitationList" v-cy="$cy.congrex.solicitation.list.container">
    <vue-loader-tab v-if="Object.keys(gathering).length === 0" />
    <v-card v-else elevation="0">
      <v-card-title class="align-end">
        {{ $t`requests-list` }}
        <v-spacer />
        <v-btn
          v-if="hasRight($const.right.downloadSolicitation)"
          class="text-none"
          color="congrex-primary"
          dark
          dense
          depressed
          :href="`solicitation/export-pdf-zip/${$route.params.gatheringUuid}`"
          small
          >{{ $t('solicitationTab.downloadSolicitationsButton') }}</v-btn
        >
        <v-btn
          v-if="hasRight($const.right.exportSolicitation)"
          class="mx-5 text-none"
          color="congrex-primary"
          dark
          dense
          depressed
          :href="`solicitation/export-csv/${$route.params.gatheringUuid}`"
          small
          >{{ $t('solicitationTab.exportSolicitationsButton') }}</v-btn
        >
        <v-btn
          v-if="hasRight($const.right.exportSolicitationMci)"
          class="mx-5 text-none"
          color="congrex-primary"
          dark
          dense
          depressed
          :href="`solicitation/export-mci/${$route.params.gatheringUuid}`"
          small
        >
          {{ $t('solicitationTab.exportSolicitationsButton') }}
        </v-btn>
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t`search`" single-line hide-details />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="gathering.congress.solicitations"
        :items-per-page="30"
        :loading="Object.keys(gathering).length === 0"
        :search="search"
        sort-by="status"
        sort-desc
        :footer-props="{
          itemsPerPageOptions: [30, 50, 100]
        }"
        :hide-default-footer="gathering.congress.solicitations.length <= 30"
        @click:row="
          item => $router.push({ name: 'SolicitationView', params: { solicitationUuid: item.solicitationUuid } })
        "
        class="data-table"
      >
        <template v-slot:[`item.healthCareProfessional.title`]="{ value, item }">
          <span v-cy="$cy.congrex.solicitation.row(item.healthCareProfessional.lastName)">{{ value || '- -' }}</span>
        </template>
        <template v-slot:[`item.zone.label`]="{ value }">
          {{ value || '- -' }}
        </template>
        <template v-slot:[`item.target`]="{ item }">
          <span v-if="getTargets(item.healthCareProfessional).length === 0">- -</span>
          <div v-else class="SolicitationList-targetsContainer">
            <span v-for="target in getTargets(item.healthCareProfessional)" :key="target" class="bob">{{
              target
            }}</span>
          </div>
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">{{ formatDate(item.updatedAt) }}</template>
        <template v-slot:[`item.specialty`]="{ item }">
          <span v-if="item.healthCareProfessional.specialty">{{
            $t(`specialtyValues.${item.healthCareProfessional.specialty}`)
          }}</span>
        </template>
        <template v-slot:[`item.healthCareProfessional.isMslTarget`]="{ value }">
          <v-icon :color="value ? 'success' : 'error'">{{ value ? 'mdi-check' : 'mdi-close' }}</v-icon>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            :color="statusColor(item.status)"
            dark
            v-cy="$cy.congrex.solicitation.status(item.healthCareProfessional.lastName)"
            >{{ status(item.status) }}</v-chip
          >
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      v-if="canCreateSolicitation && !disableCreateSolicitation"
      @click="openDialogHcpList"
      color="congrex-primary"
      data-e2e="button-open-hcp-list"
      fab
      dark
      large
      fixed
      right
      bottom
    >
      <v-icon dark>mdi-account-plus-outline</v-icon>
    </v-btn>

    <business-unit-picker-modal
      v-if="canCreateSolicitation && gathering"
      v-model="showBuPicker"
      :gathering-uuid="$get(gathering, 'gatheringUuid')"
      @submit="openDialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment'

import LoaderTab from '@/components/congrex/tabs/LoaderTab'
import { GET_SOLICITATIONS_BY_GATHERING_ID } from '@/graphql/Gatherings/GetGatheringSolicitation'
import { gatheringStatus } from '@/const/shared'
import BusinessUnitPickerModal from '../dialogs/BusinessUnitPickerModal'

export default {
  name: 'SolicitationList',

  components: {
    'vue-loader-tab': LoaderTab,
    BusinessUnitPickerModal
  },

  data() {
    return {
      gathering: {},
      loaded: false,
      search: '',
      showBuPicker: false
    }
  },

  computed: {
    ...mapGetters(['hasRight', 'userBusinessUnitUuid']),
    ...mapState(['myUser']),
    canCreateSolicitation() {
      if (!this.gathering.congress || !this.hasRight(this.$const.right.collectSolicitation)) return false

      return (
        (this.gathering.status === gatheringStatus.PUBLISHED_FRONT &&
          this.hasRight(this.$const.right.collectSolicitation)) ||
        (this.gathering.status === gatheringStatus.PUBLISHED && this.hasRight(this.$const.right.collectSolicitation))
      )
    },
    disableCreateSolicitation() {
      const now = new Date()

      if (this.userBusinessUnitUuid) {
        const gatheringBusinessUnit = this.gathering.gatheringBusinessUnits.find(
          gbu => gbu.businessUnitUuid === this.userBusinessUnitUuid
        )

        if (!gatheringBusinessUnit) return true
        const solicitationEndDate = gatheringBusinessUnit.solicitationEndDate

        const endDate = new Date(solicitationEndDate)

        return !this.$moment(now).isSameOrBefore(endDate, 'day')
      } else {
        return this.gathering.gatheringBusinessUnits.every(gbu => {
          const endDate = new Date(gbu.solicitationEndDate)

          return !this.$moment(now).isSameOrBefore(endDate, 'day')
        })
      }
    },
    headers() {
      const columns = [
        {
          text: this.$t`lastName`,
          value: 'healthCareProfessional.lastName',
          class: 'headerTableTh'
        },
        {
          text: this.$t`firstName`,
          value: 'healthCareProfessional.firstName',
          class: 'headerTableTh'
        },
        {
          text: this.$t`title`,
          value: 'healthCareProfessional.title',
          class: 'headerTableTh'
        },
        {
          text: this.$t`specialty`,
          value: 'specialty',
          class: 'headerTableTh'
        },
        {
          text: this.$t`city`,
          value: 'healthCareProfessional.city',
          class: 'headerTableTh'
        },
        {
          text: this.$t`zone`,
          value: 'zone.label',
          class: 'headerTableTh'
        },
        {
          text: this.$t`target`,
          value: 'target',
          class: 'headerTableTh'
        },
        {
          text: this.$t('msl-responsible'),
          value: 'healthCareProfessional.isMslTarget',
          sortable: false
        },
        {
          text: this.$t`solicitation`,
          value: 'status',
          class: 'headerTableTh'
        },
        {
          text: this.$t`updated-date`,
          value: 'updatedAt',
          class: 'headerTableTh'
        }
      ]

      return columns
    }
  },

  apollo: {
    gathering: {
      query: GET_SOLICITATIONS_BY_GATHERING_ID,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      result({ data: { gathering } }) {
        const numberSentSolicitations = gathering.congress.solicitations.filter(
          solicitation => solicitation.status === this.$const.solicitationStatus.SENT
        ).length

        this.loaded = true
        this.$emit('numberSentSolicitations', numberSentSolicitations)
      }
    }
  },

  methods: {
    ...mapMutations({
      setDialogHcpList: 'SET_DIALOG_HCP_LIST'
    }),
    getTargets({ targetBbu, targetKme, targetMs, targetSma }) {
      const targets = []

      if (targetBbu && targetBbu !== 'HC') {
        targets.push(`BBU_${targetBbu}`)
      }

      if (targetKme && targetKme !== 'HC') {
        targets.push(`KME_${targetKme}`)
      }

      if (targetMs && targetMs !== 'HC') {
        targets.push(`MS_${targetMs}`)
      }

      if (targetSma && targetSma !== 'HC') {
        targets.push(`SMA_${targetSma}`)
      }

      return targets
    },
    openDialogHcpList() {
      if (!this.userBusinessUnitUuid) {
        this.showBuPicker = true
        return
      }

      this.openDialog()
    },
    openDialog(businessUnitUuid) {
      this.setDialogHcpList({
        beginDate: this.gathering.beginDate,
        businessUnitUuid,
        congressUuid: this.gathering.congress.congressUuid,
        display: true,
        endDate: this.gathering.endDate,
        gatheringUuid: this.gathering.gatheringUuid,
        name: this.gathering.name,
        status: this.gathering.status
      })
    },
    status(status) {
      switch (status) {
        case this.$const.solicitationStatus.AD_HOC_VALIDATION:
          return this.$t`to-validate`
        case this.$const.solicitationStatus.REFUSED:
          return this.$t`refused`
        case this.$const.solicitationStatus.SENT:
          return this.$t`validated`
        case this.$const.solicitationStatus.WAITING_LIST:
          return this.$t`waiting-list`
        case this.$const.solicitationStatus.CANCELLED:
          return this.$t`cancel-female`
        default:
          return '- -'
      }
    },
    statusColor(status) {
      switch (status) {
        case this.$const.solicitationStatus.AD_HOC_VALIDATION:
          return 'grey'
        case this.$const.solicitationStatus.REFUSED:
          return 'error'
        case this.$const.solicitationStatus.SENT:
          return 'success'
        case this.$const.solicitationStatus.WAITING_LIST:
          return 'orange'
        case this.$const.solicitationStatus.CANCELLED:
          return 'error'
        default:
          return 'black'
      }
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>

<style lang="scss">
.SolicitationList-targetsContainer {
  white-space: nowrap;

  & > * {
    padding: 4px;
    margin-right: 6px;
    color: var(--v-primary-base);
    border: 1px solid var(--v-primary-base);
    border-radius: 4px;
  }
}

.SolicitationList {
  .data-table {
    tr {
      cursor: pointer;
    }
  }

  a {
    text-decoration: none;
  }

  .headerTableTh {
    white-space: nowrap;
  }
}
</style>
