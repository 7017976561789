<template>
  <div data-e2e="select-zone">
    <v-card-title>{{ $t('force-solicitation-select-zone.title') }}</v-card-title>
    <v-card-text>
      <div v-for="zoneBu in zonesByBu" data-e2e="bu" :key="zoneBu.zoneUuid" class="my-6">
        <v-alert color="congrex-primary" data-e2e="bu-label" dark dense>{{ zoneBu.bu.label }}</v-alert>
        <v-radio-group v-model="zoneUuid" :mandatory="false">
          <v-radio
            v-for="zone in zoneBu.zones"
            :key="zone.zoneUuid"
            class="pl-md-5"
            color="congrex-primary"
            :data-e2e="`button-zone-${zone.label}`"
            :value="zone.zoneUuid"
          >
            <template v-slot:label>
              <div class="d-flex">
                <div v-cy="$cy.congrex.solicitation.validation.zoneQuota.zone(zone.label)">{{ zone.label }}</div>
                <div class="ml-5" data-e2e="zone-available-places">
                  {{
                    $tc('force-solicitation-select-zone.rest', zone.zoneQuota - zone.filledZoneQuota, {
                      value: zone.zoneQuota - zone.filledZoneQuota
                    })
                  }}
                </div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <v-textarea
        v-model="localComment"
        @input="$emit('change', $event)"
        :comment="comment"
        data-e2e="input-comment-select-zone"
        :label="$t('force-solicitation-select-zone.label-comment')"
        outlined
      />
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn @click="$emit('cancel')" data-e2e="cancel-select-zone" depressed text color="error">{{
        $t('force-solicitation-validation.cancel-button')
      }}</v-btn>
      <v-btn
        @click="$emit('confirm', zoneUuid)"
        data-e2e="confirm-select-zone"
        depressed
        :disabled="!comment || !zoneUuid"
        text
        color="success"
      >
        {{ $t('force-solicitation-validation.confirm-button') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { GET_QUOTA_BY_ZONE } from '@/graphql/Solicitation/Solicitation'

export default {
  name: 'SelectZone',

  props: {
    comment: {
      type: String,
      required: true
    },
    solicitationUuid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      localComment: '',
      zoneUuid: ''
    }
  },

  computed: {
    zonesByBu() {
      if (!this.$get(this.solicitation, 'congress.zoneCongresses')) return []

      const congressZones = this.solicitation.congress.zoneCongresses
      const congressZoneUuids = congressZones.map(zone => zone.zoneUuid)
      return this.$store.state.bus
        .filter(bu => bu.businessUnitUuid === this.solicitation.businessUnitUuid)
        .filter(bu => bu.zones.some(zone => congressZoneUuids.includes(zone.zoneUuid)))
        .map(bu => {
          const zones = bu.zones
            .map(zone => {
              const zoneUuid = zone.zoneUuid

              const congressZone = this.solicitation.congress.zoneCongresses.find(z => z.zoneUuid === zoneUuid)
              const statZone = this.solicitation.congress.stats.zones.find(z => z.zoneUuid === zoneUuid)
              const label = bu.zones.find(buZone => buZone.zoneUuid === zoneUuid).label

              return {
                zoneQuota: this.$get(statZone, 'zoneQuota', 0),
                filledZoneQuota: this.$get(statZone, 'filledZoneQuota', 0),
                ...congressZone,
                label
              }
            })
            .filter(zone => zone.zoneQuota - zone.filledZoneQuota > 0)

          return { bu, zones }
        })
        .filter(buZone => buZone.zones.length > 0)
    }
  },

  apollo: {
    solicitation: {
      query: GET_QUOTA_BY_ZONE,
      variables() {
        return { solicitationUuid: this.solicitationUuid }
      }
    }
  }
}
</script>
