import gql from 'graphql-tag'

export const UPDATE_CONGRESS_EVENT = gql`
  mutation updateCongressEvent(
    $gathering: GatheringUpdateInput!
    $congressEvent: CongressUpdateInput
    $comment: String
  ) {
    updateCongressEvent(gathering: $gathering, congressEvent: $congressEvent, comment: $comment) {
      gatheringUuid
    }
  }
`
