<template>
  <div class="RadioButton">
    <div class="RadioButton-buttonsContainer">
      <div
        v-for="(item, index) in value"
        :key="item.id || index"
        @click="clickButton(item)"
        class="RadioButton-button"
        :class="{ 'RadioButton-button--active': item.selected }"
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioButton',

  props: {
    value: Array
  },

  methods: {
    clickButton(item) {
      this.$emit('change', item.value)
      const newArray = this.value.map(el => {
        el.selected = el.id === item.id
        return el
      })
      this.$emit('input', newArray)
    }
  }
}
</script>

<style lang="scss">
.RadioButton-buttonsContainer {
  --size-border-radius: 4px;

  & > *:first-child {
    border-radius: var(--size-border-radius) 0 0 var(--size-border-radius);
  }
  & > *:last-child {
    border-radius: 0 var(--size-border-radius) var(--size-border-radius) 0;
  }
}
.RadioButton-button {
  background: #e3eff9;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px 14px;
  color: var(--v-primary-base);
  cursor: pointer;
  transition: all 0.3s ease;
}
.RadioButton-button--active {
  background: var(--v-primary-base);
  color: white;
}
</style>
