<template>
  <v-overlay v-if="Object.keys(gathering).length === 0">
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
  <div v-else class="PresenceList">
    <base-banner
      :imageUrl="require('@/assets/manager-background.png')"
      :title="gathering.name"
      :subTitle="subtitleBanner"
      :returnRoute="backLink"
    />
    <v-row v-if="$store.getters.hasRight($const.right.exportHcpSignature)" class="mt-6">
      <v-col cols="4" class="d-none d-md-block" />
      <v-col cols="12" sm="6" md="4" class="d-flex justify-center">
        <v-btn rounded dark class="congrex-primary text-none" @click="exportPdf">
          <v-icon left>mdi-download</v-icon> {{ $t`signature-export-pdf` }}
        </v-btn>
      </v-col>
      <v-col class="d-flex justify-center justify-md-end">
        <v-btn text class="congrex-primary--text text-none mr-6" @click="exportCsv">
          <v-icon left>mdi-view-list</v-icon>
          {{ $t`signature-export-csv` }}
        </v-btn>
      </v-col>
    </v-row>
    <v-container v-if="Object.keys(gathering).length > 0" fluid class="main-container pa-0 mt-6">
      <div class="px-10 py-8 mb-8 elevation-4 white">
        <v-row>
          <v-col col="12" sm="8">
            <v-select :items="gatheringDatesList" :prefix="$t('presence-date')" v-model="selectedDate" />
          </v-col>
          <v-col col="12" sm="4" class="px-10">
            <v-text-field
              v-model="presenceListSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
        <v-data-table
          :headers="presenceListHeader"
          :items="presenceListItems"
          :loading="loading"
          :search="presenceListSearch"
          :no-data-text="errorMessage || $t`no-data`"
          item-key="key"
        >
          <template v-slot:[`item.specialty`]="{ value }">
            <span>{{ $t(`specialtyValues.${value}`) }}</span>
          </template>
          <template v-slot:[`item.undersigning`]="{ item }">
            <span v-if="item.undersigning">
              <v-icon color="success" size="24" center>
                mdi-check-circle
              </v-icon>
              {{ $t`status-signed` }}
            </span>
            <span v-else class="d-flex">
              <v-icon color="primary" @click="modalSignatureOpen(item, item.gatheringDate)" class="mr-4"
                >mdi-pencil-outline</v-icon
              >
              <v-icon color="primary" @click="modalUploadOpen(item)">mdi-camera-outline</v-icon>
            </span>

            <input ref="scanInput" type="file" @change="handleScan" accept="image/png, image/jpeg" class="d-none" />
          </template>
        </v-data-table>

        <vue-modal-signature
          ref="modalSignature"
          :display="modalSignatureDisplay"
          :caption="captionModalSignature"
          @sign="saveSignature"
          @close="modalSignatureClose"
        />

        <p class="pa-12 caption font-weight-light">
          {{ $t`signature-legal-info` }}
        </p>
        <p class="px-12 caption font-weight-light">
          {{ $t('presence-title') }} <br />
          {{ $t`signature-legal-info-plus` }}
        </p>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import FileDownload from 'js-file-download'
import { readAsDataURL } from 'promise-file-reader'
import moment from 'moment'

import { dateRange } from '@/services/date'
import ModalSignature from '@/components/base/ModalSignature'
import { GET_GATHERING_EVENT_DATE_AND_PLACE } from '@/graphql/Gatherings/GetGathering'
import { PARTICIPANTS_FOR_SIGNATURES } from '@/graphql/Participant'
import { SIGN_PRESENCE } from '@/graphql/GatheringDates/SignPresence'

export default {
  name: 'PresenceList',

  components: { 'vue-modal-signature': ModalSignature },

  data() {
    return {
      currentSigningHcp: {},
      errorMessage: '',
      gathering: {},
      loading: false,
      modalSignatureDisplay: false,
      presenceListHeader: [
        { text: this.$t`lastName`, value: 'lastName' },
        { text: this.$t`firstName`, value: 'firstName' },
        { text: this.$t`quality`, value: 'quality' },
        { text: this.$t`medical-specialty`, value: 'specialty' },
        { text: this.$t`exercisePlace`, value: 'healthCareOrganization' },
        { text: this.$t`undersigning`, value: 'undersigning' }
      ],
      presenceListSearch: '',
      reader: new FileReader(),
      selectedDate: ''
    }
  },

  computed: {
    backLink() {
      return {
        name: 'CongressTabs',
        params: { gatheringUuid: this.$route.params.gatheringUuid },
        query: { tab: 'logistic' }
      }
    },
    captionModalSignature() {
      return !this.isCongressEvent && this.currentSigningHcp.specialty === 'Interne' //TODO - use a const
        ? this.$t`presence-list-disclaimer-biogen-event`
        : ''
    },
    gatheringDate() {
      return `${moment(this.gathering.beginDate).format('DD')} ${this.$t`date-to`} ${moment(
        this.gathering.endDate
      ).format('DD MMMM')}`
    },
    gatheringDateBanner() {
      return `${this.$t`date-from`} ${moment(this.gathering.beginDate).format('DD')} ${this.$t`date-to`} ${moment(
        this.gathering.endDate
      ).format('DD MMMM YYYY')}`
    },
    gatheringDateRange() {
      if (Object.keys(this.gathering).length === 0) return []

      return dateRange(new Date(this.gathering.beginDate), new Date(this.gathering.endDate))
    },
    gatheringDatesList() {
      return this.gatheringDateRange.map(date => {
        return {
          value: moment(date).format('YYYY-MM-DD'),
          text: moment(date).format('DD MMMM YYYY'),
          disabled: this.isDisableDate(date)
        }
      })
    },
    isCongressEvent() {
      return this.gathering.gatheringType === this.$const.gatheringType.CONGRESS
    },
    signatureDateMap() {
      const map = {}

      for (const date of this.gatheringDateRange) {
        const key = moment(date).format('YYYY-MM-DD')

        map[key] = this.formatGuests(this.participants, date)
      }

      return map
    },
    presenceListItems() {
      return this.signatureDateMap[this.selectedDate]
    },
    subtitleBanner() {
      return `${this.gatheringDateBanner} ${this.$t('from-place', { value: this.gathering.city })}`
    }
  },

  apollo: {
    gathering: {
      query: GET_GATHERING_EVENT_DATE_AND_PLACE,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      result({ data: { gathering } }) {
        this.selectedDate = (moment().isBetween(gathering.beginDate, gathering.endDate)
          ? moment()
          : moment(gathering.beginDate)
        ).format('YYYY-MM-DD')
      }
    },
    participants: {
      query: PARTICIPANTS_FOR_SIGNATURES,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      update({ participantsForGathering }) {
        return participantsForGathering
          .filter(participant => {
            const { healthCareProfessional, invitationStatus } = participant

            if (!healthCareProfessional) return false

            if (this.gathering.biogenEvent) {
              return invitationStatus === this.$const.invitationStatus.confirmed
            }

            return healthCareProfessional.solicitations.some(
              solicitation =>
                solicitation.congressUuid === this.$get(this.gathering, 'congress.congressUuid') &&
                solicitation.status === this.$const.solicitationStatus.SENT
            )
          })
          .sort((p1, p2) => (p1.lastName || '').localeCompare(p2.lastName || ''))
      },
      skip() {
        return !this.$route.params.gatheringUuid
      }
    }
  },

  methods: {
    exportCsv() {
      axios
        .post(
          `${this.$baseUrl}/congress/export-signatures-csv`,
          {
            gatheringUuid: this.$route.params.gatheringUuid,
            hcps: this.presenceListItems.map(item => item.healthCareProfessionalUuid)
          },
          {
            responseType: 'blob',
            headers: {
              'x-impersonificated-user': this.$store.state.impersonificatedUser
            }
          }
        )
        .then(response => {
          FileDownload(response.data, 'signatures.xlsx')
        })
    },
    exportPdf() {
      axios
        .post(
          `${this.$baseUrl}/congress/export-signatures-pdf`,
          {
            gatheringUuid: this.$route.params.gatheringUuid,
            hcps: this.presenceListItems.map(item => item.healthCareProfessionalUuid)
          },
          {
            responseType: 'blob',
            headers: {
              'x-impersonificated-user': this.$store.state.impersonificatedUser
            }
          }
        )
        .then(response => {
          FileDownload(response.data, 'signatures.pdf')
        })
    },
    async handleScan(event) {
      const file = event.target.files[0]
      const base64 = await readAsDataURL(file)
      this.saveSignature(base64)
    },
    isDisableDate(date) {
      return moment(date).format('DD-MM-YYYY') !== moment().format('DD-MM-YYYY') && moment(date) > moment()
    },
    isHcpSignedDate(hcpGatheringDates, gatheringDate) {
      return Boolean(
        hcpGatheringDates.find(date => moment(date.gatheringDate).isSame(gatheringDate, 'day') && date.signatureDate)
      )
    },
    formatGuest({ healthCareProfessional: hcp }, gatheringDate) {
      const date = moment(gatheringDate).format('YYYY-MM-DD')

      return {
        key: `${date}-${hcp.healthCareProfessionalUuid}`,
        gatheringDate: date,
        lastName: hcp.lastName,
        firstName: hcp.firstName,
        healthCareOrganization: hcp.healthCareOrganization,
        healthCareProfessionalUuid: hcp.healthCareProfessionalUuid,
        specialty: hcp.specialty,
        undersigning: this.isHcpSignedDate(hcp.hcpGatheringDates, gatheringDate)
      }
    },
    formatGuests(guests, gatheringDate) {
      return (guests || [])
        .filter(guest => guest.healthCareProfessional)
        .map(guest => this.formatGuest(guest, gatheringDate))
    },
    modalSignatureClose() {
      this.$refs.modalSignature.clearSignature()
      this.modalSignatureDisplay = false
    },
    modalSignatureOpen(hcp) {
      this.currentSigningHcp = hcp
      this.modalSignatureDisplay = true
    },
    modalUploadOpen(hcp) {
      this.currentSigningHcp = hcp
      this.$refs.scanInput.click()
    },
    async saveSignature(data) {
      await this.$apollo.mutate({
        mutation: SIGN_PRESENCE,
        variables: {
          hcpGatheringDate: {
            healthCareProfessionalUuid: this.currentSigningHcp.healthCareProfessionalUuid,
            gatheringUuid: this.$route.params.gatheringUuid,
            gatheringDate: this.selectedDate,
            signature: data
          }
        }
      })

      await this.$apollo.queries.participants.refetch()
      this.modalSignatureClose()
    }
  }
}
</script>

<style lang="scss">
.PresenceList {
  .main-container {
    max-width: 1100px;
  }
}
</style>
