<template>
  <div>
    <div class="mb-1 black--text">{{ $t`evenium-id` }}*</div>
    <div class="d-flex">
      <v-select
        v-model="form.gathering.eveniumEventId"
        v-validate="'required'"
        data-e2e="select-evenium-event"
        data-vv-name="eveniumEventId"
        :data-vv-as="$t`evenium-id`"
        data-vv-scope="informations"
        dense
        :error-messages="errors.collect('eveniumEventId', 'informations')"
        hide-details="auto"
        :items="eventsComputed"
        :loading="eventsComputed.length === 0"
        outlined
      />
      <base-confirm-modal @confirm="syncData" v-model="confirmModal" :title="$t('eveniumEventId.confirmModal.title')" />
      <v-btn
        @click="confirmModal = true"
        class="ml-2"
        data-e2e="button-sync-evenium"
        :disabled="!displayUpdateMessage"
        icon
      >
        <v-icon color="orange">mdi-sync</v-icon>
      </v-btn>
    </div>
    <div v-if="displayUpdateMessage" class="my-2 orange--text subtitle-2">
      {{ $t('eveniumEventId.updateDataEvenium') }}
    </div>
  </div>
</template>

<script>
import _orderBy from 'lodash/orderBy'

import { GET_ID_EVENIUM_EVENTS } from '@/graphql/EveniumEvent/GetEveniumEvents'
import { pad } from '@/services/hours'

export default {
  name: 'NewEventEveniumEventId',

  inject: ['$validator'],

  model: {
    prop: 'form',
    event: 'change'
  },

  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    form: Object
  },

  data() {
    return {
      confirmModal: false,
      eveniumEvents: []
    }
  },

  computed: {
    displayUpdateMessage() {
      const event = this.eveniumEvents.find(event => event.id === this.form.gathering.eveniumEventId)

      if (!event) return false

      return (
        this.$moment(this.form.gathering.beginDate).format('L') !== this.$moment(event.startDate).format('L') ||
        this.form.gathering.city !== event.location.city ||
        this.form.gathering.country !== event.location.country ||
        this.$moment(this.form.gathering.endDate).format('L') !== this.$moment(event.endDate).format('L') ||
        this.form.gathering.location !== event.location.name ||
        this.form.gathering.name !== event.displayTitle
      )
    },
    eventsComputed() {
      return _orderBy(this.eveniumEvents.map(event => ({ text: event.displayTitle, value: event.id })), 'text')
    },
    eventSchedule() {
      return [
        `${pad(new Date(this.form.gathering.beginDate).getHours())}:${pad(
          new Date(this.form.gathering.beginDate).getMinutes()
        )}`,
        `${pad(new Date(this.form.gathering.endDate).getHours())}:${pad(
          new Date(this.form.gathering.endDate).getMinutes()
        )}`
      ]
    }
  },

  apollo: {
    eveniumEvents: {
      query: GET_ID_EVENIUM_EVENTS,
      variables() {
        return {
          alreadyUsed: false,
          startAfter: new Date(),
          includeCurrent: this.editMode ? this.form.gathering.eveniumEventId : ''
        }
      }
    }
  },

  watch: {
    'form.gathering.eveniumEventId'() {
      this.syncData()
    }
  },

  methods: {
    syncData() {
      const event = this.eveniumEvents.find(event => event.id === this.form.gathering.eveniumEventId)

      if (!event) return

      this.$emit('updateHours', event.startDate, event.endDate)
      this.$set(this.form.gathering, 'beginDate', event.startDate)
      this.$set(this.form.gathering, 'city', event.location.city)
      this.$set(this.form.gathering, 'country', event.location.country)
      this.$set(this.form.gathering, 'endDate', event.endDate)
      this.$set(this.form.gathering, 'location', event.location.name)
      this.$set(this.form.gathering, 'name', event.displayTitle)
      this.$set(this.form.gathering, 'eventSchedule', this.eventSchedule)
    }
  }
}
</script>
