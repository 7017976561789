<template>
  <v-card class="EventCard">
    <div class="EventCardHead">
      <v-img src="@/assets/bckg-biogen-event.jpg" class="px-3 py-3 Image" @click="toDetails" height="150px">
        <!-- Banner (BU + edit button) -->
        <div class="d-flex">
          <!-- Business units -->
          <div>
            <v-chip color="white" class="font-weight-bold mr-1">
              {{ virtualSession.businessUnit.externalId }}
            </v-chip>
          </div>
        </div>

        <!-- Titles -->
        <div class="mt-4">
          <mybb-text weight="bold" size="18" class="white--text d-block">
            {{ name }}

            <v-tooltip v-if="nameTrimmed" color="primary" top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="primary">mdi-information</v-icon>
              </template>
              <mybb-text class="white--text">{{ virtualSession.name }}</mybb-text>
            </v-tooltip>
          </mybb-text>
          <mybb-text weight="semi-bold" class="white--text d-block">{{ dates }}</mybb-text>
          <mybb-text weight="semi-bold" class="white--text d-block">{{ nature }}</mybb-text>
        </div>
      </v-img>
    </div>

    <div class="mb-2">
      <!-- Biogen Event -->
      <div class="px-3 mt-8">
        <div class="d-flex justify-center">
          <v-progress-linear :value="100" height="6" class="mt-2" rounded top />
          <mybb-text size="12" class="ml-2">
            {{ $get(virtualSession, 'virtualSessionParticipants.length', 0) }}
          </mybb-text>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import dateFormat from 'date-fns/format'
import { fr } from 'date-fns/locale'

export default {
  name: 'VirtualSessionCard',
  props: {
    virtualSession: {
      type: Object,
      required: true
    }
  },
  computed: {
    nameTrimmed() {
      return this.virtualSession.name.length > 30
    },
    name() {
      const { name } = this.virtualSession

      return name.length > 30 ? name.slice(0, 27).concat('...') : name
    },
    dates() {
      const { beginDate, endDate } = this.virtualSession

      if (!beginDate || !endDate) return null

      const start = dateFormat(new Date(beginDate), 'dd')
      const end = dateFormat(new Date(endDate), 'dd MMMM', { locale: fr })

      return `${start} - ${end}`
    },
    nature() {
      return this.$t('mybb.informationStep.eventNatureLabel') + this.$t('mybb.global.eventType.virtualSession')
    }
  },
  methods: {
    toDetails() {
      const route = {
        name: 'CongressTabs',
        params: { gatheringUuid: this.virtualSession.virtualSessionUuid },
        query: { virtualSession: true }
      }

      this.$router.push(route)
    }
  }
}
</script>

<style lang="scss">
.EventCard {
  position: relative;
  overflow: hidden;
  z-index: 0;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 355px;

  .EventCardHead {
    .EditIcon {
      background-color: white;
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }

    .Image {
      cursor: pointer;
    }
  }
}
</style>
