<template>
  <div fluid class="SolicitationsList">
    <div class="header">
      <v-img src="@/assets/solicitation-background.jpg" height="123px" class="d-flex text-center align-center">
        <v-btn :to="{ name: 'Congrex' }" class="page-before ml-4" dark icon><v-icon>mdi-chevron-left</v-icon></v-btn>
        <div class="display-1">
          {{ $t`solicitation-list-title` }}
        </div>
        <h5>{{ $t('xx-solicitation-to-validate', { value: solicitations.length }) }}</h5>
      </v-img>
    </div>
    <v-data-table
      @click:row="
        item => $router.push({ name: 'SolicitationView', params: { solicitationUuid: item.solicitationUuid } })
      "
      class="data-table elevation-1"
      :footer-props="{
        itemsPerPageOptions: [30, 50, 100]
      }"
      :headers="headers"
      :hide-default-footer="solicitations.length <= 30"
      :items="solicitations"
      :items-per-page="30"
      :loading="loading"
      :options="dataTableOptions"
    >
      <template v-slot:item.createdAt="value">
        {{ value | formatDate }}
      </template>
      <template v-slot:item.congress.gathering="{ value }">
        <v-btn
          :to="{
            name: 'CongressTabs',
            params: { gatheringUuid: value.gatheringUuid },
            query: { tab: 'generalInformations' }
          }"
          small
          class="text-none"
          color="primary"
        >
          {{ value.name }}
        </v-btn>
      </template>
      <template v-slot:item.congress.maximalQuota="{ item }">
        <span>{{ item.congress.stats.totalFilled }}/{{ item.congress.maximalQuota }}</span>
      </template>
      <template v-slot:item.target="{ item }">
        {{ item.healthCareProfessional.targetMs }} - {{ item.healthCareProfessional.targetBbu }} -
        {{ item.healthCareProfessional.targetSma }}
      </template>
      <template v-slot:item.zone="{ item }">
        {{ quotaZone(item) }}
      </template>
      <template v-slot:item.congress.criterias="{ item }">
        <span v-if="getTargets(item.healthCareProfessional).length === 0">- -</span>
        <div v-else class="SolicitationManagement-targetsContainer">
          <span v-for="target in getTargets(item.healthCareProfessional)" :key="target" class="bob">
            {{ target }}
          </span>
        </div>
      </template>
      <template v-slot:item.initiator="{ item }">
        <span>{{ item.initiator.firstName }} {{ item.initiator.lastName }}</span>
      </template>
      <template v-slot:item.healthCareProfessional.type="{ value }">
        <span>{{ value || '- -' }}</span>
      </template>
      <template v-slot:item.healthCareProfessional.targetBbu="{ item, value }">
        <span v-if="item.congress.criterias.some(txt => txt.split('_')[0] === 'BBU')">{{ value }}</span>
      </template>
      <template v-slot:item.healthCareProfessional.targetMs="{ item, value }">
        <span v-if="item.congress.criterias.some(txt => txt.split('_')[0] === 'MS')">{{ value }}</span>
      </template>
      <template v-slot:item.healthCareProfessional.targetSma="{ item, value }">
        <span v-if="item.congress.criterias.some(txt => txt.split('_')[0] === 'SMA')">{{ value }}</span>
      </template>
      <template v-slot:item.healthCareProfessional.isMslTarget="{ value }">
        <v-icon :color="value ? 'success' : 'error'">{{ value ? 'mdi-check' : 'mdi-close' }}</v-icon>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="statusColor(item.status)" dark>
          {{ status(item.status) }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _get from 'lodash/get'
import moment from 'moment'

import { GET_ALL_SOLICITATIONS } from '@/graphql/Solicitation/GetSolicitations'

export default {
  name: 'SolicitationsManagement',

  data() {
    return {
      dataTableOptions: { sortBy: ['createdAt'] },
      loading: false,
      solicitations: []
    }
  },

  computed: {
    headers() {
      const columns = [
        {
          text: this.$t('solicitationManagement.createdAtHeader'),
          value: 'createdAt',
          class: 'headerTableTh'
        },
        {
          text: this.$t`lastName`,
          value: 'healthCareProfessional.lastName',
          class: 'headerTableTh'
        },
        {
          text: this.$t`firstName`,
          value: 'healthCareProfessional.firstName',
          class: 'headerTableTh'
        },
        {
          text: this.$t`type`,
          value: 'healthCareProfessional.type',
          class: 'headerTableTh'
        },
        {
          text: this.$t`event`,
          value: 'congress.gathering',
          class: 'headerTableTh'
        },
        {
          text: 'Quota max',
          value: 'congress.maximalQuota',
          class: 'headerTableTh'
        },
        {
          text: 'Quota/zone',
          value: 'zone',
          class: 'headerTableTh'
        },
        {
          text: this.$t`request-initiator`,
          value: 'initiator',
          class: 'headerTableTh'
        },
        {
          text: this.$t`target`,
          value: 'congress.criterias',
          class: 'headerTableTh'
        },
        {
          text: this.$t`zone`,
          value: 'healthCareProfessional.zone.label',
          class: 'headerTableTh'
        },
        {
          text: this.$t('msl-responsible'),
          value: 'healthCareProfessional.isMslTarget',
          class: 'headerTableTh'
        },
        {
          text: this.$t`solicitation`,
          value: 'status',
          class: 'headerTableTh'
        }
      ]

      return columns
    }
  },

  apollo: {
    solicitations: {
      query: GET_ALL_SOLICITATIONS,
      variables() {
        this.loading = true
        return {
          limit: 1000,
          status: ['ad_hoc_validation', 'waiting_list']
        }
      },
      result(data) {
        this.loading = false
        return data.solicitations
      },
      debounce: 300,
      deep: true
    }
  },

  filters: {
    formatDate(value) {
      return moment(value).format('L')
    }
  },

  methods: {
    getTargets({ targetBbu, targetKme, targetMs, targetSma }) {
      const targets = []

      if (targetBbu && targetBbu !== 'HC') {
        targets.push(`BBU_${targetBbu}`)
      }

      if (targetKme && targetKme !== 'HC') {
        targets.push(`KME_${targetKme}`)
      }

      if (targetMs && targetMs !== 'HC') {
        targets.push(`MS_${targetMs}`)
      }

      if (targetSma && targetSma !== 'HC') {
        targets.push(`SMA_${targetSma}`)
      }

      return targets
    },
    quotaZone(item) {
      const zoneQuota = _get(item, 'zoneCongress.zoneQuota', '?')

      if (isNaN(zoneQuota)) return '-'

      const zone = item.congress.stats.zones.find(el => el.zoneUuid === item.zone.zoneUuid)

      if (!zone) return '-'

      return `${zone.filledZoneQuota}/${zoneQuota}`
    },
    status(status) {
      switch (status) {
        case this.$const.solicitationStatus.AD_HOC_VALIDATION:
          return this.$t`to-validate`
        case this.$const.solicitationStatus.REFUSED:
          return this.$t`refused`
        case this.$const.solicitationStatus.SENT:
          return this.$t`validated`
        case this.$const.solicitationStatus.WAITING_LIST:
          return this.$t`waiting-list`
        default:
          return 'error'
      }
    },
    statusColor(status) {
      switch (status) {
        case this.$const.solicitationStatus.AD_HOC_VALIDATION:
          return 'grey'
        case this.$const.solicitationStatus.REFUSED:
          return 'error'
        case this.$const.solicitationStatus.SENT:
          return 'success'
        case this.$const.solicitationStatus.WAITING_LIST:
          return 'orange'
        default:
          return 'black'
      }
    },
    url(uuid) {
      return `/congrex/solicitations/${uuid}`
    }
  }
}
</script>

<style lang="scss">
.SolicitationsList {
  .page-before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 13vh;
    color: white;
  }

  .data-table {
    tr {
      cursor: pointer;
    }

    .headerTableTh {
      white-space: nowrap;
    }
  }

  .v-chip {
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
}

.SolicitationManagement-targetsContainer {
  white-space: nowrap;

  & > * {
    padding: 4px;
    margin-right: 6px;
    color: var(--v-primary-base);
    border: 1px solid var(--v-primary-base);
    border-radius: 4px;
  }
}
</style>
