<template>
  <modal v-model="modal" :title="t('title')">
    <mybb-text class="mb-2">{{ t('text') }}</mybb-text>

    <mybb-radios v-model="businessUnitUuid" :items="items" />

    <div class="mt-10 text-center">
      <mybb-btn color="mybb-grey" class="mr-10" @click="modal = false">
        {{ t('cancel') }}
      </mybb-btn>

      <mybb-btn color="mybb-success" :disabled="!businessUnitUuid" @click="submitBu">
        {{ t('confirm') }}
      </mybb-btn>
    </div>
  </modal>
</template>

<script>
import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'

import Modal from '../../mybb/Modal'

export default {
  name: 'BusinessUnitPickerModal',
  model: {
    prop: 'visible'
  },
  components: { Modal },
  props: {
    gatheringUuid: {
      type: String
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      businessUnitUuid: null
    }
  },
  computed: {
    modal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('input', value)
        this.businessUnitUuid = null
      }
    },
    items() {
      const now = new Date().getTime()

      return this.$get(this.gathering, 'gatheringBusinessUnits', []).map(gbu => ({
        value: gbu.businessUnitUuid,
        label: gbu.businessUnit.label,
        disabled: now >= new Date(gbu.solicitationEndDate).getTime() + 86400000
      }))
    }
  },
  apollo: {
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return {
          gatheringUuid: this.gatheringUuid
        }
      },
      skip() {
        return !this.gatheringUuid
      },
      result() {
        const availableItems = this.items.filter(item => !item.disabled)

        if (this.visible && availableItems.length === 1) {
          this.$emit('submit', availableItems[0].value)
          this.modal = false
        }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`business-unit-picker-modal.${key}`, params)
    },
    submitBu() {
      this.$emit('submit', this.businessUnitUuid)

      this.modal = false
    }
  }
}
</script>
