<template>
  <div>
    <div class="title">{{ $t`biogen-presence` }}</div>
    <v-divider class="mb-8" />
    <v-row>
      <v-col v-if="!form.gathering.isVirtual" cols="12" sm="6" md="4">
        <v-checkbox v-model="form.congressEvent.hasBiogenStand" color="success" data-e2e="button-has-biogen-stand">
          <template v-slot:label>
            <span class="checkbox-label">{{ $t`biogen-stand` }}</span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-checkbox
          v-model="form.congressEvent.hasBiogenWorkshop"
          color="success"
          data-e2e="button-has-biogen-workshop"
        >
          <template v-slot:label>
            <span class="checkbox-label">{{ $t`biogen-workshop` }}</span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-checkbox
          v-model="form.congressEvent.hasBiogenSymposium"
          color="success"
          data-e2e="button-has-biogen-symposium"
        >
          <template v-slot:label>
            <span class="checkbox-label">{{ $t`biogen-symposium` }}</span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <div class="mb-2 black--text">{{ $t`symposium-program` }}</div>
    <v-btn @click="$refs.symposiumProgramFile.click()" color="success" depressed>
      <v-icon left>mdi-plus</v-icon>{{ $t`add-document` }}
    </v-btn>
    <v-row>
      <v-col cols="12" lg="4">
        <v-file-input
          v-if="document"
          v-model="document"
          @change="v => updateFile(v, 'symposiumProgram')"
          class="mt-2"
          clear-icon="mdi-close"
          data-e2e="file-input-symposium-program"
          dense
          outlined
          prepend-icon=""
        />
      </v-col>
    </v-row>
    <v-proof-sponsorship v-model="form.congressEvent.sponsorships" />

    <input
      @change="convertToFile('symposiumProgram')"
      accept=".pdf"
      class="d-none"
      data-e2e="input-symposium-program"
      ref="symposiumProgramFile"
      type="file"
    />
  </div>
</template>

<script>
import { toBase64 } from '@/services/file'
import ProofSponsorship from '@/components/congrex/ProofSponsorship'

export default {
  name: 'NewEventBiogenPresence',
  components: { 'v-proof-sponsorship': ProofSponsorship },
  model: {
    prop: 'form',
    event: 'change'
  },

  props: {
    form: Object
  },

  data() {
    return {
      document: ''
    }
  },

  methods: {
    async convertToFile(name) {
      const file = this.$refs[`${name}File`].files[0]
      this.updateFile(file, name)
    },
    async updateFile(file, name) {
      if (!file) {
        this.form.congressEvent.symposiumProgram = ''
        return
      }

      const base64 = await toBase64(file)
      this.$emit('change', { ...this.form, congressEvent: { ...this.form.congressEvent, [name]: base64 } })
      this.document = file
    }
  }
}
</script>

<style scoped>
.checkbox-label {
  font-size: 1.1em;
  color: #444444;
}
</style>
