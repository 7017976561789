var templateObject = Object.freeze(["solicitation-list-title"]);
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SolicitationsList",attrs:{"fluid":""}},[_c('div',{staticClass:"header"},[_c('v-img',{staticClass:"d-flex text-center align-center",attrs:{"src":require("@/assets/solicitation-background.jpg"),"height":"123px"}},[_c('v-btn',{staticClass:"page-before ml-4",attrs:{"to":{ name: 'Congrex' },"dark":"","icon":""}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('div',{staticClass:"display-1"},[_vm._v(" "+_vm._s(_vm.$t(templateObject))+" ")]),_c('h5',[_vm._v(_vm._s(_vm.$t('xx-solicitation-to-validate', { value: _vm.solicitations.length })))])],1)],1),_c('v-data-table',{staticClass:"data-table elevation-1",attrs:{"footer-props":{
      itemsPerPageOptions: [30, 50, 100]
    },"headers":_vm.headers,"hide-default-footer":_vm.solicitations.length <= 30,"items":_vm.solicitations,"items-per-page":30,"loading":_vm.loading,"options":_vm.dataTableOptions},on:{"click:row":function (item) { return _vm.$router.push({ name: 'SolicitationView', params: { solicitationUuid: item.solicitationUuid } }); }},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(value){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"item.congress.gathering",fn:function(ref){
    var value = ref.value;
return [_c('v-btn',{staticClass:"text-none",attrs:{"to":{
          name: 'CongressTabs',
          params: { gatheringUuid: value.gatheringUuid },
          query: { tab: 'generalInformations' }
        },"small":"","color":"primary"}},[_vm._v(" "+_vm._s(value.name)+" ")])]}},{key:"item.congress.maximalQuota",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.congress.stats.totalFilled)+"/"+_vm._s(item.congress.maximalQuota))])]}},{key:"item.target",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.healthCareProfessional.targetMs)+" - "+_vm._s(item.healthCareProfessional.targetBbu)+" - "+_vm._s(item.healthCareProfessional.targetSma)+" ")]}},{key:"item.zone",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.quotaZone(item))+" ")]}},{key:"item.congress.criterias",fn:function(ref){
        var item = ref.item;
return [(_vm.getTargets(item.healthCareProfessional).length === 0)?_c('span',[_vm._v("- -")]):_c('div',{staticClass:"SolicitationManagement-targetsContainer"},_vm._l((_vm.getTargets(item.healthCareProfessional)),function(target){return _c('span',{key:target,staticClass:"bob"},[_vm._v(" "+_vm._s(target)+" ")])}),0)]}},{key:"item.initiator",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.initiator.firstName)+" "+_vm._s(item.initiator.lastName))])]}},{key:"item.healthCareProfessional.type",fn:function(ref){
        var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value || '- -'))])]}},{key:"item.healthCareProfessional.targetBbu",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
return [(item.congress.criterias.some(function (txt) { return txt.split('_')[0] === 'BBU'; }))?_c('span',[_vm._v(_vm._s(value))]):_vm._e()]}},{key:"item.healthCareProfessional.targetMs",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
return [(item.congress.criterias.some(function (txt) { return txt.split('_')[0] === 'MS'; }))?_c('span',[_vm._v(_vm._s(value))]):_vm._e()]}},{key:"item.healthCareProfessional.targetSma",fn:function(ref){
        var item = ref.item;
        var value = ref.value;
return [(item.congress.criterias.some(function (txt) { return txt.split('_')[0] === 'SMA'; }))?_c('span',[_vm._v(_vm._s(value))]):_vm._e()]}},{key:"item.healthCareProfessional.isMslTarget",fn:function(ref){
        var value = ref.value;
return [_c('v-icon',{attrs:{"color":value ? 'success' : 'error'}},[_vm._v(_vm._s(value ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.status(item.status))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }