import gql from 'graphql-tag'

export const VALIDATE_GATHERING = gql`
  mutation ValidateGathering($gatheringUuid: GUID!) {
    toPublishGathering(gatheringUuid: $gatheringUuid) {
      gatheringUuid
    }
  }
`

export const VALIDATE_ALL_GATHERING_SECTIONS = gql`
  mutation validateAllGatheringSections($gatheringUuid: GUID!) {
    validateAllGatheringSections(gatheringUuid: $gatheringUuid) {
      gatheringUuid
    }
  }
`

export const ASK_CORRECTION_GATHERING = gql`
  mutation askGlobalCorrectionForGathering($gatheringUuid: GUID!, $comment: String!) {
    askGlobalCorrectionForGathering(gatheringUuid: $gatheringUuid, comment: $comment) {
      gatheringUuid
    }
  }
`

export const VALIDATE_GATHERING_SECTION = gql`
  mutation validateGatheringSection(
    $gatheringUuid: GUID!
    $section: GatheringValidationSection!
    $businessUnitUuid: GUID
  ) {
    validateGatheringSection(gatheringUuid: $gatheringUuid, section: $section, businessUnitUuid: $businessUnitUuid) {
      gatheringUuid
    }
  }
`

export const CORRECT_GATHERING_SECTION = gql`
  mutation correctGatheringSection(
    $gatheringUuid: GUID!
    $section: GatheringValidationSection!
    $businessUnitUuid: GUID
    $comment: String!
  ) {
    correctGatheringSection(
      gatheringUuid: $gatheringUuid
      section: $section
      businessUnitUuid: $businessUnitUuid
      comment: $comment
    ) {
      gatheringUuid
    }
  }
`
