<template>
  <div class="container-fluid" data-e2e="solicitation-view">
    <div
      class="fluid-bandeau"
      :style="{
        'background-image': `url(${require('@/assets/solicitation-background.jpg')})`
      }"
    >
      <v-btn
        icon
        class="SolicitationView-returnLink ml-sm-9"
        dark
        :to="{ name: 'CongressTabs', params: { gatheringUuid: solicitation.congress.gatheringUuid } }"
      >
        <v-icon large>mdi-chevron-left</v-icon>
      </v-btn>
      <h1>{{ solicitation.healthCareProfessional.firstName }} {{ solicitation.healthCareProfessional.lastName }}</h1>
      <h2>{{ solicitation.congress.gathering.name }}</h2>
      <div class="btn_wrapper mt-3">
        <base-confirm-modal
          :title="$t('confirm-title-validation-solicitation', { fullName })"
          :text="$t`confirm-text-validation-solicitation`"
          v-cy="$cy.congrex.solicitation.submitValidate"
          :disable-validate="isCongress && !comment"
          v-model="modalConfirmSolicitation"
          @confirm="confirmSolicitation"
        >
          <template v-slot:text v-if="isCongress">
            <v-text-field
              v-model="comment"
              data-e2e="input-confirm-validate-solicitation"
              v-cy="$cy.congrex.solicitation.comment"
              required
              :placeholder="$t`comment`"
            />
          </template>
        </base-confirm-modal>
        <v-btn
          v-if="canConfirmSolicitation"
          @click="openModalConfirmSolicitation"
          class="mx-2 success--text"
          depressed
          v-cy="$cy.congrex.solicitation.validate"
        >
          {{ $t`validate` }}
        </v-btn>

        <v-btn v-if="canUpdateSolicitation" @click="updateSolicitation" class="mx-2 primary--text" depressed>
          {{ $t('solicitation-view.button-update-solicitation') }}
        </v-btn>

        <base-confirm-modal
          :title="$t('confirm-title-refuse-solicitation', { fullName })"
          :text="$t`confirm-text-refuse-solicitation`"
          :disable-validate="!comment"
          v-model="modalRefuseSolicitation"
          @confirm="refuseSolicitation"
        >
          <template v-slot:text>
            <v-text-field v-model="comment" data-e2e="input-confirm-refuse-solicitation" :placeholder="$t`comment`" />
          </template>
        </base-confirm-modal>
        <v-btn
          v-if="toRefuseSolicitation && hasRight($const.right.confirmSolicitation)"
          @click="modalRefuseSolicitation = true"
          data-e2e="button-refuse-solicitation"
          class="mx-2 error--text"
          depressed
        >
          {{ $t`refuse` }}
        </v-btn>
        <v-dialog v-model="modalWaitingSolicitation" max-width="600">
          <v-card>
            <v-card-title>{{ $t('solicitation-view.waiting-modal.title') }}</v-card-title>
            <v-card-text>
              <p>{{ $t('solicitation-view.waiting-modal.text') }}</p>
              <v-textarea
                v-model="comment"
                data-e2e="input-confirm-put-waiting-solicitation"
                :label="$t`label-increase-quota-solicitation`"
                outlined
              />
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                @click="modalWaitingSolicitation = false"
                data-e2e="button-confirm-cancel-refuse-solicitation"
                depressed
                text
                color="error"
                >{{ $t`cancel` }}</v-btn
              >
              <v-btn
                @click="putOnHoldSolicitation"
                data-e2e="button-confirm-validate-put-waiting-solicitation"
                depressed
                :disabled="!comment"
                text
                color="success"
                >{{ $t`validate` }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          v-if="toWaitingSolicitation && $store.getters.hasRight($const.right.putOnHoldSolicitation)"
          @click="modalWaitingSolicitation = true"
          class="mx-2 orange--text"
          data-e2e="button-put-waiting-solicitation"
          depressed
        >
          {{ $t('solicitation-view.waiting-button') }}
        </v-btn>

        <base-confirm-modal
          :title="
            isSubscribed
              ? $t('confirm-title-cancel-solicitation-blocked')
              : $t('confirm-title-cancel-solicitation', { fullName })
          "
          :text="
            isSubscribed
              ? $t('confirm-text-cancel-solicitation-blocked', { rocIdentity })
              : $t`confirm-text-cancel-solicitation`
          "
          v-model="modalCancelSolicitation"
          :disable-validate="isSubscribed"
          @confirm="cancelSolicitation"
        />
        <v-btn
          v-if="
            $store.getters.hasRight($const.right.cancelSolicitation) &&
              solicitation.status &&
              solicitation.status !== $const.solicitationStatus.CANCELLED &&
              solicitation.status !== $const.solicitationStatus.REFUSED
          "
          @click="modalCancelSolicitation = true"
          class="mx-2 error--text"
          data-e2e="button-cancel-solicitation"
          depressed
        >
          {{ $t`cancel` }}
        </v-btn>
      </div>
    </div>
    <v-container class="max-width">
      <v-row>
        <v-col cols="0" sm="3" />
        <v-col cols="12" sm="6" class="text-center">
          <v-btn
            v-if="$store.getters.hasRight($const.right.downloadSolicitation)"
            @click="exportPdf"
            class="text-none"
            color="primary"
            data-e2e="button-download-solicitation"
            dark
            depressed
            rounded
          >
            <v-icon left>mdi-download</v-icon>
            {{ $t('solicitation-view.download-solicitation') }}
          </v-btn>
          <template v-if="solicitation.scan && $store.getters.hasRight($const.right.downloadSolicitation)">
            <br />
            <v-btn
              class="mt-3 text-none"
              color="primary"
              dark
              data-e2e="button-download-scan"
              depressed
              download="scan"
              :href="solicitation.scan"
              outlined
              rounded
              small
            >
              <v-icon left small>mdi-download</v-icon>
              {{ $t('solicitation-view.download-solicitation-scan') }}
            </v-btn>
          </template>
        </v-col>
        <v-col cols="12" sm="3" class="text-center text-sm-right pr-2" data-e2e="solicitation-created-at">
          {{ $t`date` }}: {{ createdAt }}
        </v-col>
      </v-row>
      <v-card class="mx-auto mt-6" :loading="this.$apollo.queries.solicitation.loading">
        <v-card-title class="pt-12 title justify-center">{{ $t`solicitation-title` }}</v-card-title>
        <v-spacer />
        <v-spacer />
        <v-card-text class="pa-6 px-12">
          <v-card-subtitle class="subtitle mx-auto">{{ $t`solicitation-informations-demandeur` }}</v-card-subtitle>
          <v-row>
            <v-col cols="12" md="3">
              <label>{{ $t`full-name-solicitation` }}</label>
              <p data-e2e="hcp-full-name">{{ fullName }}</p>
            </v-col>
            <v-col cols="12" md="3">
              <label>{{ $t`emailAddress` }}</label>
              <p data-e2e="hcp-email">{{ solicitation.emailAddress }}</p>
            </v-col>
            <v-col cols="12" md="3">
              <label>{{ $t`medical-specialty` }}</label>
              <p data-e2e="hcp-specialty">{{ solicitation.healthCareProfessional.specialty }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <label>{{ $t`rppsNumber` }}</label>
              <p data-e2e="hcp-rpps-number">{{ solicitation.healthCareProfessional.rppsNumber || '- -' }}</p>
            </v-col>
            <v-col cols="12" md="3">
              <label>{{ $t`phoneNumber` }}</label>
              <p data-e2e="hcp-phone-number">{{ solicitation.phoneNumber }}</p>
            </v-col>
            <v-col cols="12" md="3">
              <label>{{ $t`healthCareOrganization` }}</label>
              <p data-e2e="hcp-health-care-organisation">
                {{ solicitation.healthCareProfessional.healthCareOrganization }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ma-0">{{ $t`solicitation-therapeutique-ask` }}</v-col>
          </v-row>
          <v-row>
            <v-col class="radio" cols="12" md="3">
              <p class="text-capitalize">
                <v-icon
                  v-if="solicitation.isTherapeuticCommittee"
                  color="success"
                  data-e2e="is-therapeutic-committee"
                  size="20"
                  left
                >
                  mdi-check-circle
                </v-icon>
                {{ $t`yes` }}
              </p>
            </v-col>
            <v-col class="radio" cols="12" md="3">
              <p class="text-capitalize">
                <v-icon
                  v-if="solicitation.isTherapeuticCommittee === false"
                  color="success"
                  data-e2e="no-therapeutic-committee"
                  size="20"
                  left
                >
                  mdi-check-circle
                </v-icon>
                {{ $t`no` }}
              </p>
            </v-col>
          </v-row>
          <v-row v-if="solicitation.isTherapeuticCommittee">
            <v-col cols="12" md="12">
              <p>
                {{ $t`solicitationYesRoleFirstPart` }}
                <br />
                {{ $t`solicitationYesRoleFirstTwo` }}
              </p>
              <v-textarea
                v-model="solicitation.therapeuticCommitteeRole"
                data-e2e="therapeutic-committee-role"
                no-resize
                readonly
                solo
                name="input-7-4"
                >{{ solicitation.therapeuticCommitteeRole }}</v-textarea
              >
            </v-col>
          </v-row>
          <v-card-subtitle class="subtitle mx-auto">
            {{ $t`solicitation-informations-congres-evenement` }}
          </v-card-subtitle>
          <v-row>
            <v-col cols="12" md="3">
              <label>{{ $t`gatheringName` }}</label>
              <p data-e2e="event-name">{{ solicitation.congress.gathering.name }}</p>
            </v-col>
            <v-col cols="12" md="3">
              <label>{{ $t`gatheringPlace` }}</label>
              <p data-e2e="event-city">{{ solicitation.congress.gathering.location }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <label>{{ $t`gatheringDates` }}</label>
              <p data-e2e="event-date">{{ gatheringDate }}</p>
            </v-col>
            <v-col cols="12" md="9">
              <label>{{ $t('solicitation-view.support-type') }}</label>
              <v-row>
                <v-col class="radio" cols="12" md="5">
                  <p>
                    <v-icon
                      v-if="solicitation.supportRegistration"
                      color="success"
                      data-e2e="is-support-registration"
                      size="20"
                      left
                    >
                      mdi-check-circle
                    </v-icon>
                    {{ $t`inscriptionCongress` }}
                  </p>
                </v-col>
                <v-col class="radio" cols="12" md="3">
                  <p>
                    <v-icon
                      v-if="solicitation.supportTransport"
                      color="success"
                      data-e2e="is-support-transport"
                      size="20"
                      left
                    >
                      mdi-check-circle
                    </v-icon>
                    {{ $t`transport` }}
                  </p>
                </v-col>
                <v-col class="radio" cols="12" md="4">
                  <p>
                    <v-icon
                      v-if="solicitation.supportAccommodation"
                      color="success"
                      data-e2e="is-support-accommodation"
                      size="20"
                      left
                    >
                      mdi-check-circle
                    </v-icon>
                    {{ $t`accommodation` }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-col class="radio" cols="12" md="12">
                <p>
                  <v-icon
                    v-if="solicitation.shareClinicalKnowledge"
                    color="success"
                    data-e2e="is-share-clinical-knowledge"
                    size="20"
                    left
                  >
                    mdi-check-circle
                  </v-icon>
                  {{ $t`shareClinicalKnowledge` }}
                </p>
              </v-col>
              <v-col class="radio" cols="12" md="12">
                <v-icon
                  v-if="solicitation.newsSearchPath"
                  color="success"
                  data-e2e="is-news-search-path"
                  size="20"
                  left
                >
                  mdi-check-circle
                </v-icon>
                <p class="mb-0">{{ $t`newsSearchPath` }}</p>
              </v-col>
              <v-col class="radio" cols="12" md="12">
                <v-icon
                  v-if="solicitation.clinicalStudyPresentation"
                  color="success"
                  data-e2e="is-clinical-study-presentation"
                  size="20"
                  left
                >
                  mdi-check-circle
                </v-icon>
                <p class="mb-0">{{ $t`clinicalStudyPresentation` }}</p>
              </v-col>
            </v-col>
            <v-col cols="12" md="5">
              <v-col class="radio" cols="12" md="12">
                <v-icon
                  v-if="solicitation.actualAndNewTherapeutic"
                  color="success"
                  data-e2e="is-actual-and-new-therapeutic"
                  size="20"
                  left
                >
                  mdi-check-circle
                </v-icon>
                <p class="mb-0">{{ $t`actualAndNewTherapeutic` }}</p>
              </v-col>
              <v-col class="radio" cols="12" md="12">
                <v-icon
                  v-if="solicitation.therapeuticArea"
                  color="success"
                  data-e2e="is-therapeutic-area"
                  size="20"
                  left
                >
                  mdi-check-circle
                </v-icon>
                <p class="mb-0">{{ $t`therapeuticArea` }}</p>
              </v-col>
              <template v-if="solicitation.others">
                <p>{{ $t`other` }}</p>
                <p class="SolicitationView-othersFieldContainer" data-e2e="others-fields">
                  {{ solicitation.others }}
                </p>
              </template>
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="12" md="12">
              <v-col class="radio" cols="12" md="12">
                <v-icon
                  v-if="solicitation.noOtherFunding"
                  color="success"
                  data-e2e="is-no-other-funding"
                  size="20"
                  left
                >
                  mdi-check-circle
                </v-icon>
                <p class="mb-0">{{ $t`noOtherFunding` }}</p>
              </v-col>
              <v-col
                v-if="solicitation.congress.nationalityType === $const.nationalityType.INTERNATIONAL"
                class="radio"
                cols="12"
                data-e2e="is-international-nationality"
                md="12"
              >
                <v-icon color="success" size="20" left>
                  mdi-check-circle
                </v-icon>
                <p class="mb-0">
                  <span class="blue__label">
                    {{ $t`noOtherIntercontinentalFundingBlue` }}
                    <br />
                  </span>
                  {{ $t`noOtherIntercontinentalFunding` }}
                </p>
              </v-col>
              <v-col class="radio" cols="12" data-e2e="is-no-biogen-obligation" md="12">
                <v-icon v-if="solicitation.noBiogenObligation" color="success" size="20" left>
                  mdi-check-circle
                </v-icon>
                <p class="mb-0">{{ $t`noBiogenObligation` }}</p>
              </v-col>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="text-center">
              <h4>{{ $t`date` }}</h4>
              <p class="mt-6" data-e2e="created-at">{{ createdAt }}</p>
            </v-col>
            <v-col cols="6" class="text-center">
              <h4 class="mb-2">{{ $t`signature` }}</h4>
              <div v-if="solicitation.scan">
                <base-preview-card :content="scanContent" v-model="solicitation.scan" :canDownload="canDownloadScan" />
              </div>
              <v-img
                v-else-if="solicitation.signature || solicitation.scan"
                class="ma-auto"
                data-e2e="signature-or-scan"
                :src="solicitation.signature || solicitation.scan"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog v-model="modalComment" max-width="600">
      <v-card data-e2e="confirm-validate-card">
        <v-card-title>{{ $t('solicitation-view.out-criteria-modal.title') }}</v-card-title>
        <v-card-text>
          <p>{{ $t('solicitation-view.out-criteria-modal.text') }}</p>
          <v-textarea
            v-model="comment"
            data-e2e="input-comment-validate-solicitation"
            :label="$t`label-increase-quota-solicitation`"
            outlined
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="modalComment = false"
            data-e2e="cancel-confirm-validate-solicitation"
            depressed
            text
            color="error"
            >{{ $t`cancel` }}</v-btn
          >
          <v-btn
            @click="confirmSolicitation"
            data-e2e="confirm-validate-solicitation"
            depressed
            :disabled="!comment"
            text
            color="success"
            >{{ $t`validate` }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalExceedQuota" max-width="700">
      <ForceSolicitationValidation
        v-if="modalExceedQuota"
        v-model="comment"
        @cancel="modalExceedQuota = false"
        @confirm="confirmSolicitation"
        :doctorContext="solicitation.healthCareProfessional.type === $const.importedUserTypes.doctor"
        :displaySelectZone="displaySelectZoneToForceSolicitation"
        :solicitation-uuid="solicitation.solicitationUuid"
      />
    </v-dialog>
    <v-dialog v-model="modalForceSolicitation" max-width="700">
      <ForceSolicitationValidation
        v-if="modalForceSolicitation"
        v-model="quotaComment"
        @cancel="modalForceSolicitation = false"
        @confirm="confirmSolicitation"
        :doctorContext="solicitation.healthCareProfessional.type === $const.importedUserTypes.doctor"
        :displaySelectZone="displaySelectZoneToForceSolicitation"
        :solicitation-uuid="solicitation.solicitationUuid"
      />
    </v-dialog>
    <v-dialog v-model="modalChooseSolicitationZone" max-width="700">
      <ChooseSolicitationZone
        v-if="modalChooseSolicitationZone"
        v-model="quotaComment"
        @cancel="modalChooseSolicitationZone = false"
        @confirm="confirmSolicitation"
        :solicitation-uuid="solicitation.solicitationUuid"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import FileDownload from 'js-file-download'
import { mapMutations, mapGetters } from 'vuex'

import { PUT_ON_HOLD_SOLICITATION } from '@/graphql/Solicitation/PutOnHoldSolicitation'
import { GET_SOLICITATION } from '@/graphql/Solicitation/Solicitation'
import { CONFIRM_SOLICITATION } from '@/graphql/Solicitation/ConfirmSolicitation'
import { REFUSE_SOLICITATION } from '@/graphql/Solicitation/RefuseSolicitation'
import { CANCEL_SOLICITATION } from '@/graphql/Solicitation/CancelSolicitation'
import { MATCH_CRITERIAS } from '@/graphql/MatchCriterias/MatchCriterias'
import { PARTICIPANT_FROM_HCP_AND_GATHERING } from '@/graphql/Participant'
import ForceSolicitationValidation from '@/components/congrex/forceSolicitationValidation/ForceSolicitationValidation'
import ChooseSolicitationZone from '@/components/congrex/chooseSolicitationZone/ChooseSolicitationZone'

export default {
  name: 'SolicitationView',

  components: { ForceSolicitationValidation, ChooseSolicitationZone },

  data() {
    return {
      comment: '',
      loaded: false,
      modalComment: false,
      modalExceedQuota: false,
      modalForceSolicitation: false,
      modalCancelSolicitation: false,
      modalConfirmSolicitation: false,
      modalChooseSolicitationZone: false,
      modalRefuseSolicitation: false,
      modalWaitingSolicitation: false,
      solicitation: {
        actualAndNewTherapeutic: false,
        clinicalStudyPresentation: false,
        createdAt: new Date(),
        gatheringUuid: this.$route.params.congressUuid,
        isTherapeuticCommittee: false,
        newsSearchPath: false,
        noBiogenObligation: false,
        noOtherFunding: false,
        noOtherIntercontinentalFunding: false,
        others: '',
        phoneNumber: '',
        requestedSupport: [],
        scan: '',
        shareClinicalKnowledge: false,
        signature: '',
        therapeuticArea: false,
        therapeuticCommitteeRole: '',
        congress: {
          gathering: {
            name: '',
            city: '',
            beginDate: new Date(),
            endDate: new Date()
          }
        },
        healthCareProfessional: {
          healthCareProfessionalUuid: '',
          veevaId: '',
          firstName: '',
          lastName: '',
          emailAddress: '',
          healthCareOrganization: '',
          specialty: ''
        }
      },
      menu: false,
      quotaComment: '',
      isValid: false,
      errorMessage: ''
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    canConfirmSolicitation() {
      return this.toValidateSolicitation && this.hasRight(this.$const.right.confirmSolicitation)
    },
    canDownloadScan() {
      return (
        this.hasRight(this.$const.right.downloadSolicitation) ||
        this.$store.state.myUser.userUuid === this.solicitation.initiatorUuid
      )
    },
    canUpdateSolicitation() {
      return (
        this.solicitation.scan &&
        (this.solicitation.status === this.$const.solicitationStatus.SENT ||
          this.solicitation.status === this.$const.solicitationStatus.AD_HOC_VALIDATION ||
          this.solicitation.status === this.$const.solicitationStatus.WAITING_LIST) &&
        (this.hasRight(this.$const.right.updateSolicitation) ||
          this.solicitation.initiatorUuid === this.$store.state.myUser.userUuid)
      )
    },
    displayModalChooseSolicitationZone() {
      const gatheringBusinessUnit = this.solicitation.congress.gathering.gatheringBusinessUnits.find(
        gbu => gbu.businessUnitUuid === this.solicitation.businessUnitUuid
      )
      const hasZoneQuota = this.$get(gatheringBusinessUnit, 'hasZoneQuota', false)

      return (
        this.solicitation.healthCareProfessional.type === this.$const.importedUserTypes.doctor &&
        this.solicitation.zoneUuid === null &&
        hasZoneQuota
      )
    },
    displayModalForceSolicitation() {
      return (
        (this.solicitation.healthCareProfessional.type === this.$const.importedUserTypes.doctor &&
          (this.isExceedZoneQuota || this.isExceedDoctorQuota)) ||
        (this.solicitation.healthCareProfessional.type !== this.$const.importedUserTypes.doctor &&
          this.isExceedOtherQuota)
      )
    },
    displaySelectZoneToForceSolicitation() {
      return (
        !this.isExceedDoctorQuota &&
        this.solicitation.healthCareProfessional.type === this.$const.importedUserTypes.doctor
      )
    },
    toRefuseSolicitation() {
      return (
        this.solicitation.status === this.$const.solicitationStatus.AD_HOC_VALIDATION ||
        this.solicitation.status === this.$const.solicitationStatus.WAITING_LIST
      )
    },
    toValidateSolicitation() {
      return (
        this.solicitation.status === this.$const.solicitationStatus.AD_HOC_VALIDATION ||
        this.solicitation.status === this.$const.solicitationStatus.WAITING_LIST ||
        this.solicitation.status === this.$const.solicitationStatus.REFUSED ||
        this.solicitation.status === this.$const.solicitationStatus.CANCELLED
      )
    },
    toWaitingSolicitation() {
      return (
        this.solicitation.status === this.$const.solicitationStatus.AD_HOC_VALIDATION ||
        this.solicitation.status === this.$const.solicitationStatus.CANCELLED ||
        this.solicitation.status === this.$const.solicitationStatus.REFUSED
      )
    },
    fullName() {
      return `${this.solicitation.healthCareProfessional.firstName} ${this.solicitation.healthCareProfessional.lastName}`
    },
    gatheringDate() {
      return `${moment(this.solicitation.congress.gathering.beginDate).calendar()} - ${moment(
        this.solicitation.congress.gathering.endDate
      ).calendar()}`
    },
    hcpBuStats() {
      if (!this.$get(this.solicitation, 'congress.stats.businessUnits') || !this.solicitation.businessUnitUuid) {
        return null
      }

      return this.solicitation.congress.stats.businessUnits.find(
        businessUnit => businessUnit.businessUnitUuid === this.solicitation.businessUnitUuid
      )
    },
    hcpZoneStats() {
      if (!this.$get(this.solicitation, 'congress.stats.zones') || !this.solicitation.zoneUuid) return null

      return this.solicitation.congress.stats.zones.find(zone => zone.zoneUuid === this.solicitation.zoneUuid)
    },
    createdAt() {
      return moment(this.solicitation.createdAt).format('DD/MM/YYYY')
    },
    needToDisplayModalComment() {
      return !this.matchCriterias
    },
    isExceedDoctorQuota() {
      const hcpBuStats = this.hcpBuStats

      return hcpBuStats && hcpBuStats.doctorFilled >= hcpBuStats.doctorQuota
    },
    isExceedOtherQuota() {
      const hcpBuStats = this.hcpBuStats

      return hcpBuStats && hcpBuStats.otherFilled >= hcpBuStats.otherDisciplinesQuota
    },
    isExceedZoneQuota() {
      const gatheringBusinessUnit = this.solicitation.congress.gathering.gatheringBusinessUnits.find(
        gbu => gbu.businessUnitUuid === this.solicitation.businessUnitUuid
      )
      const hasZoneQuota = this.$get(gatheringBusinessUnit, 'hasZoneQuota', false)

      return (
        hasZoneQuota &&
        (!this.solicitation.zoneUuid ||
          !this.hcpZoneStats ||
          this.hcpZoneStats.filledZoneQuota >= this.hcpZoneStats.zoneQuota)
      )
    },
    isCongress() {
      return this.$get(this.solicitation, 'congress', null)
    },
    scanContent() {
      if (!this.solicitation || !this.solicitation.scan) {
        return {}
      }

      const imageRegex = /^data:([^;]+);base64,(.*)$/
      const regexResult = imageRegex.exec(this.solicitation.scan ? this.solicitation.scan : this.solicitation.signature)

      if (!regexResult) {
        throw new this.error.BadRequest('Bad file format')
      }

      const fileExtension = this.fileExtensionFromMimeType(regexResult[1])

      return {
        fileName: `scan.${fileExtension}`
      }
    },
    rocIdentity() {
      const gatheringUsers = this.$get(this.solicitation, 'congress.gathering.users')

      if (!Array.isArray(gatheringUsers)) return null

      const rocGatheringUser = gatheringUsers.find(
        gatheringUser =>
          gatheringUser.gatheringUserType === this.$const.gatheringUserType.ROC && !gatheringUser.isBackup
      )

      if (!rocGatheringUser) return null

      return `${rocGatheringUser.user.firstName} ${rocGatheringUser.user.lastName}`
    }
  },

  apollo: {
    matchCriterias: {
      query: MATCH_CRITERIAS,
      skip() {
        return (
          !this.$get(this.solicitation, 'healthCareProfessional.healthCareProfessionalUuid') ||
          !this.$get(this.solicitation, 'congress.congressUuid') ||
          !this.$get(this.solicitation, 'businessUnitUuid')
        )
      },
      variables() {
        return {
          healthCareProfessionalUuid: this.$get(this.solicitation, 'healthCareProfessional.healthCareProfessionalUuid'),
          congressUuid: this.$get(this.solicitation, 'congress.congressUuid'),
          businessUnitUuid: this.$get(this.solicitation, 'businessUnitUuid')
        }
      }
    },
    solicitation: {
      query: GET_SOLICITATION,
      variables() {
        return {
          solicitationUuid: this.$route.params.solicitationUuid
        }
      }
    },
    isSubscribed: {
      query: PARTICIPANT_FROM_HCP_AND_GATHERING,
      variables() {
        return {
          healthCareProfessionalUuid: this.$get(this.solicitation, 'healthCareProfessional.healthCareProfessionalUuid'),
          gatheringUuid: this.$get(this.solicitation, 'congress.gatheringUuid')
        }
      },
      skip() {
        return (
          !this.$get(this.solicitation, 'healthCareProfessional.healthCareProfessionalUuid') ||
          !this.$get(this.solicitation, 'congress.gatheringUuid')
        )
      },
      update({ healthCareProfessionalParticipantForGathering }) {
        const invitationStatus = this.$get(healthCareProfessionalParticipantForGathering, 'invitationStatus')

        return invitationStatus === this.$const.invitationStatus.confirmed
      }
    }
  },

  methods: {
    ...mapMutations({
      setModalMessage: 'SET_MODAL_MESSAGE'
    }),
    async cancelSolicitation() {
      await this.$apollo.mutate({
        mutation: CANCEL_SOLICITATION,
        variables: { solicitationUuid: this.solicitation.solicitationUuid }
      })

      this.setModalMessage({
        display: true,
        text: this.$t`message-solicitation-canceled`,
        icon: 'mdi-close-circle-outline',
        iconColor: 'error',
        prevPage: true
      })
    },
    async confirmSolicitation(zoneUuid) {
      const variables = {
        solicitationUuid: this.solicitation.solicitationUuid
      }

      if (this.displayModalChooseSolicitationZone) {
        this.modalChooseSolicitationZone = false

        if (zoneUuid) {
          variables.chosenZoneUuid = zoneUuid
          variables.quotaComment = this.quotaComment
        }
      } else if (this.displayModalForceSolicitation) {
        this.modalForceSolicitation = false
        variables.quotaComment = this.quotaComment
        if (zoneUuid) {
          variables.decrementedZoneUuid = zoneUuid
        }
      } else if (this.needToDisplayModalComment) {
        variables.quotaComment = this.comment
      } else if (this.isCongress) {
        variables.quotaComment = this.comment
      }

      await this.$apollo.mutate({
        mutation: CONFIRM_SOLICITATION,
        variables: variables
      })

      this.setModalMessage({
        display: true,
        text: this.$t`message-solicitation-validated`,
        prevPage: true
      })

      this.quotaComment = null
    },
    exportPdf() {
      axios
        .post(
          `${this.$baseUrl}/solicitation/export-pdf`,
          {
            solicitationUuid: this.solicitation.solicitationUuid
          },
          {
            responseType: 'blob',
            headers: {
              'x-impersonificated-user': this.$store.state.impersonificatedUser
            }
          }
        )
        .then(response => {
          FileDownload(response.data, 'solicitation.pdf')
        })
    },
    openModalConfirmSolicitation() {
      if (this.displayModalChooseSolicitationZone) {
        this.modalChooseSolicitationZone = true
      } else if (this.displayModalForceSolicitation) {
        this.modalForceSolicitation = true
      } else {
        this.modalConfirmSolicitation = true
      }
    },
    async putOnHoldSolicitation() {
      await this.$apollo.mutate({
        mutation: PUT_ON_HOLD_SOLICITATION,
        variables: {
          comment: this.comment,
          solicitationUuid: this.$route.params.solicitationUuid
        }
      })

      this.loaded = true
      this.modalWaitingSolicitation = false
      this.setModalMessage({
        display: true,
        text: this.$t('solicitation-view.waiting-message-validated'),
        prevPage: true
      })
    },
    async refuseSolicitation() {
      await this.$apollo.mutate({
        mutation: REFUSE_SOLICITATION,
        variables: { solicitationUuid: this.solicitation.solicitationUuid, comment: this.comment }
      })

      this.setModalMessage({
        display: true,
        text: this.$t`message-solicitation-refused`,
        icon: 'mdi-close-circle-outline',
        iconColor: 'error',
        prevPage: true
      })

      this.comment = ''
    },
    updateSolicitation() {
      this.$router.push({
        name: 'UpdateSolicitation',
        query: {
          gatheringUuid: this.solicitation.congress.gatheringUuid,
          solicitationUuid: this.$route.params.solicitationUuid,
          veevaId: this.solicitation.healthCareProfessional.veevaId
        }
      })
    },
    fileExtensionFromMimeType(mimeType) {
      switch (mimeType) {
        case 'application/pdf':
          return 'pdf'
        case 'application/zip':
          return 'zip'
        case 'image/png':
          return 'png'
        case 'image/jpeg':
        case 'image/jpg':
          return 'jpg'
        case 'image/svg+xml':
          return 'svg'
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return 'docx'
        case 'application/msword':
          return 'doc'
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return 'xlsx'
        case 'application/vnd.ms-excel':
          return 'xls'
        case 'text/plain':
          return 'txt'
        case 'text/csv':
          return 'csv'
        case 'text/html':
          return 'html'
        default:
          return 'unknown'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.SolicitationView-returnLink {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 35px;
  color: white;
  transform: translateY(-50%);
}

.SolicitationView-othersFieldContainer {
  padding: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

.subtitle {
  background-color: rgba(27, 154, 247, 0.1);
  color: #1b9af7 !important;
  font-size: 20px;
  font-weight: 500;
}
.title {
  font-size: 24px !important;
  font-weight: bold;
  text-align: center;
  color: #3c4858;
}
.fluid-bandeau {
  position: relative;
  text-align: center;
  padding: 10px 0;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.blue__label {
  color: #1b9af7;
}
.max-width {
  max-width: 1100px;
}
.btn_wrapper {
  display: flex;
  justify-content: center;
}
.radio {
  display: flex;
  justify-content: flex-start;
  div {
    margin-right: 20px;
  }
}
h4 {
  font-size: 20px;
  font-weight: 500;
  color: black;
}
p {
  color: black;
  font-size: 14px;
  font-weight: 500;
}
</style>
