<template>
  <div class="HcpSearch" v-cy="$cy.congrex.hcpList.container">
    <v-card elevation="2">
      <v-row align="center" class="px-4 py-4" no-gutters>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="searchText"
            :append-icon="searchText ? '' : 'mdi-magnify'"
            clearable
            dense
            hide-details
            :placeholder="$t('hcp-search.text-field-placeholder')"
            v-cy="$cy.congrex.hcpList.search"
            outlined
          />
        </v-col>
        <v-col v-if="displaySwitchAllBase" class="d-flex align-center">
          <v-switch
            v-model="searchAllDb"
            color="congrex-primary"
            hide-details
            class="mx-5 mt-0 pt-0"
            v-cy="$cy.congrex.hcpList.allBaseToggle"
          >
            <template v-slot:label>
              <span class="body-2">{{ $t('hcp-search.switch-all-db') }}</span>
            </template>
          </v-switch>
        </v-col>
      </v-row>
    </v-card>
    <div class="data-table-title">{{ $t('hcp-search.data-table-title') }}</div>
    <v-data-table
      :headers="headers"
      :items="hcpFormated"
      :items-per-page="10"
      :loading="loading"
      :options.sync="dataOptions"
      :server-items-length="veevaUsers.total"
      :footer-props="{
        itemsPerPageOptions: [10, 20, 50]
      }"
      must-sort
    >
      <template v-slot:[`item.action`]="{ item }">
        <div class="HcpSearch-actionContainer">
          <!-- TODO open modal for banList instead of createSolicitation
            fields: isSolicitationBanned in HCP
           -->
          <v-btn
            v-if="hasRight($const.right.collectSolicitation)"
            @click.stop="createSolicitation(item)"
            color="congrex-primary"
            depressed
            dark
            fab
            x-small
            class="mx-1 my-1 text-none"
            v-cy="$cy.congrex.hcpList.findEvent(item.lastName)"
          >
            <v-icon>mdi-account-plus-outline</v-icon>
          </v-btn>
          <v-icon @click="goToHcpHistorical(item)" color="congrex-primary" size="38">mdi-information-outline</v-icon>
        </div>
      </template>
    </v-data-table>

    <modal v-model="bannedModal" :title="$t('banned-modal.title')">
      <mybb-text class="mt-3">{{ $t('banned-modal.text', { gatheringName: bannedGatheringName }) }}</mybb-text>
      <mybb-text weight="bold">{{ $t('banned-modal.highlight') }}</mybb-text>

      <div class="text-center mt-10">
        <mybb-btn color="mybb-grey" @click="bannedModal = false">{{ $t('banned-modal.close') }}</mybb-btn>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { GET_HCPS_FOR_HCP_HISTORICAL } from '@/graphql/VeevaUsers/GetVeevaUsers'

import Modal from '../mybb/Modal'

export default {
  name: 'HcpSearch',

  components: { Modal },

  data() {
    return {
      veevaUsers: {},
      dataOptions: {
        sortBy: ['lastName'],
        sortDesc: [false]
      },
      healthCareProfessionals: null,
      loading: false,
      searchAllDb: false,
      searchText: '',
      bannedGatheringName: null
    }
  },

  computed: {
    buUuids() {
      return this.searchAllDb ? [] : this.$get(this.$store.state.myUser, 'abmMeta.zone.zoneUuid', [])
    },
    ...mapGetters(['hasRight']),
    bannedModal: {
      get() {
        return Boolean(this.bannedGatheringName)
      },
      set(value) {
        if (value) {
          this.bannedGatheringName = value
        } else {
          this.bannedGatheringName = null
        }
      }
    },
    displaySwitchAllBase() {
      return Boolean(
        this.$get(this.$store.state.myUser, 'mslMeta.zone.zoneUuid') ||
          this.$get(this.$store.state.myUser, 'abmMeta.businessUnitUuid')
      )
    },
    hcpFormated() {
      if (!this.veevaUsers.results) return []

      return this.veevaUsers.results.map(({ healthCareProfessional: hcp }) => ({
        attentendIntercontinentalCongressYears: hcp.attentendIntercontinentalCongressYears,
        buLabel: hcp.businessUnit ? hcp.businessUnit.label : '- -',
        city: hcp.city,
        emailAddress: hcp.emailAddress,
        firstName: hcp.firstName,
        healthCareProfessionalUuid: hcp.healthCareProfessionalUuid,
        isSolicitationBannedFromGathering: hcp.isSolicitationBannedFromGathering,
        lastName: hcp.lastName,
        specialty: this.$t(`specialtyValues.${hcp.specialty}`),
        target: (hcp.businessUnit && hcp[this.$const.targetBu[hcp.businessUnit.label]]) || '- -',
        title: hcp.title || '- -',
        veevaId: hcp.veevaId,
        zone: hcp.zone && hcp.zone.label
      }))
    },
    headers() {
      const headers = [
        {
          text: this.$t`lastName`,
          value: 'lastName'
        },
        {
          text: this.$t`firstName`,
          value: 'firstName'
        },
        {
          text: this.$t`title`,
          value: 'title',
          align: 'center'
        },
        {
          text: this.$t`specialty`,
          value: 'specialty',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t`city`,
          value: 'city',
          align: 'center'
        },
        {
          text: this.$t`bu`,
          value: 'buLabel',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t`target`,
          value: 'target',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t`zone`,
          value: 'zone',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('actions'),
          value: 'action',
          align: 'center',
          sortable: false
        }
      ]

      return headers
    }
  },

  apollo: {
    veevaUsers: {
      query: GET_HCPS_FOR_HCP_HISTORICAL,
      variables() {
        this.loading = true

        const variables = {
          businessUnitUuids: this.searchAllDb
            ? []
            : this.$get(this.$store.state.myUser, 'abmMeta.businessUnitUuid', []),
          lastName: this.searchText,
          zoneUuids: this.searchAllDb ? [] : this.$get(this.$store.state.myUser, 'mslMeta.zone.zoneUuid', [])
        }

        if (this.dataOptions.page) {
          variables.offset = (this.dataOptions.page - 1) * this.dataOptions.itemsPerPage
          variables.limit = this.dataOptions.itemsPerPage
        }

        // Sorting
        if (this.dataOptions.sortBy.length !== 0) {
          variables.sorts = [
            { field: this.dataOptions.sortBy[0], order: this.dataOptions.sortDesc[0] ? 'DESC' : 'ASC' }
          ]
        }

        return variables
      },
      result() {
        this.loading = false
      },
      debounce: 500
    }
  },

  watch: {
    searchAllDb() {
      this.dataOptions.page = 1
    }
  },

  methods: {
    createSolicitation(item) {
      if (item.isSolicitationBannedFromGathering) {
        this.bannedGatheringName = item.isSolicitationBannedFromGathering.name
        return
      }

      this.$store.commit('SET_DIALOG_EVENT_LIST', {
        display: true,
        emailAddress: item.emailAddress,
        hcpFullName: `${item.firstName} ${item.lastName}`,
        healthCareProfessionalUuid: item.healthCareProfessionalUuid,
        subtitle: item.specialty,
        title: `${item.firstName} ${item.lastName}`,
        veevaId: item.veevaId
      })
    },
    goToHcpHistorical(item) {
      this.$router.push({ name: 'HcpHistorical', params: { hcpUuid: item.healthCareProfessionalUuid } })
    }
  }
}
</script>

<style lang="scss">
.HcpSearch {
  .data-table-title {
    padding: 17px 30px;
    background: #fafafa;
    font-size: 22px;
    font-weight: 400;
    border-bottom: 1px solid lightgrey;
  }
}
.HcpSearch-actionContainer {
  white-space: nowrap;
}
</style>
