import gql from 'graphql-tag'

export const GET_GUESTS_BY_EVENT = gql`
  query eveniumGuests(
    $eveniumEventId: String!
    $gatheringUuid: GUID!
    $lastName: String
    $limit: Int
    $offset: Int
    $status: String
  ) {
    eveniumGuests(
      eveniumEventId: $eveniumEventId
      lastName: $lastName
      limit: $limit
      offset: $offset
      status: $status
    ) {
      guests {
        contactId
        email
        firstName
        gender
        lastName
        postStatus
        status
        transportWaybackDate
        transportWaybackType
        transportWaytoDate
        transportWaytoType
        transportStatus
        category {
          id
          label
        }
        event {
          gathering {
            beginDate
            endDate
          }
        }
        healthCareProfessional {
          city
          healthCareProfessionalUuid
          hcpGatheringDates(gatheringUuid: $gatheringUuid) {
            signatureDate
          }
          targetAd
          targetBbu
          targetKme
          targetMs
          targetSma
          zone {
            label
          }
        }
        fields {
          name
          value
        }
      }
      hasMore
    }
  }
`
export const GET_GUESTS_BY_EVENT_FOR_TRANSPORT_STATUS = gql`
  query eveniumGuests($eveniumEventId: String!, $limit: Int, $offset: Int, $status: String) {
    eveniumGuests(eveniumEventId: $eveniumEventId, limit: $limit, offset: $offset, status: $status) {
      guests {
        contactId
        email
        postStatus
        transportStatus
        veevaId
      }
      hasMore
    }
  }
`
