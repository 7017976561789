<template>
  <v-tabs v-model="tab" grow v-cy="$cy.congrex.list.container" class="SearchTabs-tabs">
    <v-tab
      v-for="item in tabs"
      :key="item.id"
      v-cy="$cy.congrex.global.tab(item.name)"
      :to="item.to"
      class="text-none font-weight-bold"
    >
      {{ item.name }}
    </v-tab>
    <v-tab-item class="search-tab-item" v-for="item in tabs" :key="item.id" :value="item.value">
      <router-view />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { hasOneOfType } from '@/services/user'

export default {
  name: 'SearchTabs',

  data() {
    return {
      tab: null
    }
  },

  computed: {
    tabs() {
      const tabs = [
        {
          id: 0,
          name: this.$t('search-tabs.event-tab-name'),
          to: { name: 'SearchEvents' },
          value: this.$router.resolve({ name: 'SearchEvents' }).route.path
        },
        {
          id: 1,
          name: this.$t('search-tabs.hcp-tab-name'),
          to: { name: 'SearchHcp' },
          value: this.$router.resolve({ name: 'SearchHcp' }).route.path
        }
      ]

      if (hasOneOfType([this.$const.userType.ABM, this.$const.userType.MSL])) {
        return tabs.reverse()
      }

      return tabs
    }
  }
}
</script>

<style lang="scss">
.SearchTabs-tabs {
  position: relative;

  .v-item-group[role='tablist'] {
    position: sticky;
    top: 50px;
    left: 0;
    right: 0;
    height: 48px;
    z-index: 1;
  }
}
</style>
