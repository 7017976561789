<template>
  <v-card elevation="0" class="InvitationList">
    <v-card-title class="align-end">
      <div>
        <span>{{ $t`invitations-list` }}</span>
        <span v-if="isPersonalizeEvent" class="ml-2 body-2">{{ invitationsListPlaces }}</span>
      </div>
      <v-col
        v-if="isPersonalizeEvent && $store.getters.hasRight($const.right.importAttendees)"
        class="flex-grow-0 px-md-5 py-0"
      >
        <v-row class="flex-nowrap">
          <v-col class="flex-grow-0 py-0">
            <v-btn outlined small class="text-none" color="primary" @click="downloadParticipations">
              {{ downloadInvitationLabel }}
            </v-btn>
          </v-col>
          <v-col class="flex-grow-0 py-0">
            <v-btn small class="text-none" color="primary" @click="importModal = true">
              {{ $t`import-participations` }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-else-if="!isPersonalizeEvent && invitations && invitations.length > 0"
        cols="6"
        md="4"
        class="InvitationList-Available-Places-Container px-md-5 py-0"
      >
        <div>{{ $t`places-number` }}</div>
        <div class="d-flex align-center">
          <v-progress-linear :value="percentConfirmed" />
          <span class="pl-3">{{ confirmedInvitations }}/{{ gathering.biogenEvent.availablePlace }}</span>
        </div>
      </v-col>

      <v-spacer />

      <v-col cols="12" md="3" class="pa-0">
        <v-text-field
          v-model="searchLastName"
          clearable
          :label="$t`search`"
          :placeholder="$t('invitationList.searchFieldPlaceholder')"
          append-icon="mdi-magnify"
          hide-details
          single-line
        />
      </v-col>
    </v-card-title>
    <v-data-table
      disable-sort
      :footer-props="{ itemsPerPageOptions: [10, 20, 50], 'page-text': '' }"
      :headers="headers"
      :hide-default-footer="true"
      :items="guests || []"
      :loading="loading.guests && !errorMessage"
      :server-items-length="displayNextButton ? 1000000 : guests.length"
    >
      <template v-slot:[`item.target`]="{ item }">
        <span v-if="!item.healthCareProfessional || getTargets(item.healthCareProfessional).length === 0">- -</span>
        <div v-else class="InvitationList-targetsContainer">
          <span v-for="target in getTargets(item.healthCareProfessional)" :key="target">{{ target }}</span>
        </div>
      </template>

      <template v-slot:[`item.city`]="{ item }">
        {{ $get(item, 'healthCareProfessional.city') || searchField(item.fields, 'ADDR_CITY') }}
      </template>

      <template v-slot:[`healthCareProfessional.zone.label`]="{ value }">
        <span>{{ value }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="statusColor(item.invitationStatus)" dark>
          {{ $t(`guest-status.${item.invitationStatus}`) }}
        </v-chip>
      </template>
    </v-data-table>
    <v-alert v-if="errorMessage" class="mx-6" type="error">{{ $t('invitationList.error.evenium') }}</v-alert>

    <event-attendee-import-dialog
      :gatheringUuid="$route.params.gatheringUuid"
      :attendees="importedAttendees"
      :open="importModal"
      @close="importModal = false"
      @download="downloadParticipations"
      @refresh-attendees="refreshAttendees"
    />
  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import FileDownload from 'js-file-download'
import _capitalize from 'lodash/capitalize'

import EventAttendeeImportDialog from '@/components/congrex/dialogs/EventAttendeeImportDialog'
import CONST_EVENIUM from '@/const/evenium'
import { PARTICIPANTS_FOR_GATHERING_CONGREX } from '@/graphql/Participant'

export default {
  name: 'InvitationList',

  components: {
    EventAttendeeImportDialog
  },

  props: {
    virtualSession: {
      type: Object
    }
  },

  data() {
    return {
      canFetchGathering: true,
      dataTableSync: {
        itemsPerPage: 10,
        offset: 0
      },
      errorMessage: null,
      eveniumGuests: null,
      gathering: {},
      headers: [
        {
          text: this.$t`lastName`,
          value: 'lastName'
        },
        {
          text: this.$t`firstName`,
          value: 'firstName'
        },
        {
          text: this.$t`city`,
          value: 'city'
        },
        {
          text: this.$t`zone`,
          value: 'healthCareProfessional.zone.label',
          sortable: false
        },
        {
          text: this.$t`targeting`,
          value: 'target',
          sortable: false
        },
        {
          text: this.$t`invitations`,
          value: 'status'
        }
      ],
      importModal: false,
      invitations: null,
      loaded: {
        guests: false
      },
      loading: {
        gathering: true,
        guests: false
      },
      searchLastName: ''
    }
  },

  computed: {
    isVirtualSession() {
      return [true, 'true'].includes(this.$route.query.virtualSession)
    },
    confirmedInvitations() {
      if (!this.invitations || this.invitations.length === 0) return 0

      return this.invitations.filter(invitation => {
        return (
          invitation.status === CONST_EVENIUM.guestStatus.VALID ||
          invitation.status === CONST_EVENIUM.guestStatus.RESERVED ||
          invitation.status === CONST_EVENIUM.guestStatus.OVERBOOKED ||
          invitation.status === CONST_EVENIUM.guestStatus.EXTRA ||
          invitation.status === CONST_EVENIUM.guestStatus.CONFIRMED
        )
      }).length
    },
    displayNextButton() {
      return !this.loading.guests && (!this.eveniumGuests || this.eveniumGuests.hasMore)
    },
    downloadInvitationLabel() {
      return this.invitationsListPlaces <= 0 ? this.$t`download-template` : this.$t`download-invitations`
    },
    guests() {
      let guests = []

      if (this.isVirtualSession) {
        guests = Array.isArray(this.virtualSession.virtualSessionParticipants)
          ? this.virtualSession.virtualSessionParticipants.map(this.mapVirtualSessionParticipant)
          : []
      } else {
        guests = Array.isArray(this.participants) ? this.participants : []
      }

      if (this.searchLastName) {
        guests = guests.filter(guest => guest.lastName.toLowerCase().includes(this.searchLastName.toLowerCase()))
      }

      return guests
    },
    percentConfirmed() {
      return (this.confirmedInvitations / this.gathering.biogenEvent.availablePlace) * 100
    },
    title() {
      return this.isVirtualBiogenEvent
        ? this.$t`invitations-list`
        : this.$t('invitations-list-places', {
            confirmedInvitations: this.$get(this.gathering, 'importedAttendees', []).length,
            availablePlaces: this.gathering.biogenEvent.availablePlace
          })
    },
    invitationsListPlaces() {
      const confirmedInvitations = this.importedAttendees.filter(
        el =>
          el.status === this.$const.importedAttendees.status.ABSENT ||
          el.status === this.$const.importedAttendees.status.CONFIRMED ||
          el.status === this.$const.importedAttendees.status.PRESENT ||
          el.status === this.$const.importedAttendees.status.REGISTERED ||
          el.status === this.$const.importedAttendees.status.NO_SHOW
      ).length

      const invitationCount = this.importedAttendees.length

      return this.$t('invitations-list-places', {
        confirmedInvitations,
        availablePlaces: invitationCount
      })
    },
    isPersonalizeEvent() {
      return (
        this.gathering.isVirtual &&
        this.gathering.hasOwnProperty('biogenEvent') &&
        !this.gathering.eveniumEventId &&
        !this.gathering.oseusMeetingId
      )
    },
    isVirtualBiogenEvent() {
      return (
        this.gathering.isVirtual &&
        this.gathering.hasOwnProperty('biogenEvent') &&
        (this.gathering.eveniumEventId || this.gathering.oseusMeetingId)
      )
    },
    importedAttendees() {
      return this.$get(this.gathering, 'importedAttendees', [])
    }
  },

  apollo: {
    participants: {
      query: PARTICIPANTS_FOR_GATHERING_CONGREX,
      update({ participantsForGathering }) {
        return Array.isArray(participantsForGathering)
          ? participantsForGathering
              .filter(participant => participant.healthCareProfessional)
              .sort((p1, p2) => (p1.lastName || '').localeCompare(p2.lastName || ''))
          : []
      },
      variables() {
        return { gatheringUuid: this.$route.params.gatheringUuid }
      },
      result() {
        this.loading.gathering = false
        this.loading.guests = false
      },
      skip() {
        return Boolean(this.virtualSession)
      }
    }
  },

  methods: {
    mapVirtualSessionParticipant(participant) {
      const { REFUSED, SUBSCRIBED, UNKNOWN } = this.$const.virtualSession.subscription
      let invitationStatus

      switch (participant.subscription) {
        case REFUSED:
          invitationStatus = 'REFUSED'
          break
        case SUBSCRIBED:
          invitationStatus = 'VALID'
          break
        case UNKNOWN:
          invitationStatus = 'UNANSWERED'
          break
      }

      return {
        lastName: participant.lastName.toUpperCase(),
        firstName: _capitalize(participant.firstName),
        city: participant.city,
        healthCareProfessional: {
          zone: {
            label: this.$get(participant, 'healthCareProfessional.zone.label')
          }
        },
        target: this.getTargets(participant.healthCareProfessional || {}),
        invitationStatus
      }
    },
    getTargets({ targetBbu, targetKme, targetMs, targetSma }) {
      const targets = []

      if (targetBbu && targetBbu !== 'HC') {
        targets.push(`BBU_${targetBbu}`)
      }

      if (targetKme && targetKme !== 'HC') {
        targets.push(`KME_${targetKme}`)
      }

      if (targetMs && targetMs !== 'HC') {
        targets.push(`MS_${targetMs}`)
      }

      if (targetSma && targetSma !== 'HC') {
        targets.push(`SMA_${targetSma}`)
      }

      return targets
    },
    refreshAttendees() {
      this.canFetchGathering = true
      this.$apollo.queries.gathering.refresh()
    },
    searchField(fields, name) {
      if (!fields || fields.length === 0) return '- -'

      const field = fields.find(field => field.name === name)

      return field ? field.value : '- -'
    },
    statusColor(status) {
      const { confirmed, pending, refused } = this.$const.invitationStatus

      switch (status) {
        case 'VALID':
        case confirmed:
          return 'success'
        case refused:
          return 'error'
        case pending:
        default:
          return 'grey'
      }
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    async downloadParticipations() {
      const { data } = await axios.get(`gathering/participation/${this.$route.params.gatheringUuid}/export`, {
        responseType: 'blob',
        headers: {
          'x-impersonificated-user': this.$store.state.impersonificatedUser
        }
      })

      FileDownload(data, `Participations-${this.$route.params.gatheringUuid}.xlsx`)
    }
  }
}
</script>

<style lang="scss">
.InvitationList {
  .InvitationList-Available-Places-Container {
    font-size: 10px;
    line-height: initial;
  }
}

.InvitationList-targetsContainer {
  white-space: nowrap;

  & > * {
    padding: 4px;
    margin-right: 6px;
    color: var(--v-primary-base);
    border: 1px solid var(--v-primary-base);
    border-radius: 4px;
  }
}
</style>
