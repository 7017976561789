<template>
  <div>
    <div class="title">{{ $t`hcp-support` }}</div>
    <v-divider class="mb-8" />
    <form>
      <v-row class="body-2 grey--text">
        <v-col md="6" v-if="isCongressEvent">
          <div class="d-flex justify-space-between mb-1 black--text">
            <span>{{ $t('new-event.hcp-support.hcpQuota.label') }}*</span>
            <v-tooltip right top transition="dialog-bottom-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-help-circle
                </v-icon>
              </template>
              <span v-html="$t('new-event.hcp-support.hcpQuota.moreInfo')" />
            </v-tooltip>
          </div>
          <v-text-field
            v-model.number="form.congressEvent.maximalQuota"
            :class="{ 'NewEvent-sensibleDataField': sensibleDataFields.includes('maximalQuota') }"
            data-e2e="input-maximal-quota"
            data-vv-name="quota"
            :data-vv-as="$t`new-event.hcp-support.hcpQuota.label`"
            data-vv-scope="hcpSupport"
            dense
            :error-messages="errors.collect('quota', 'hcpSupport')"
            outlined
            type="text"
            v-validate="`required|numeric${totalFilled > 0 ? `|min_value:${totalFilled}` : ''}`"
          />
        </v-col>
        <v-col v-if="isCongressEvent" md="6">
          <div class="d-flex justify-space-between mb-1 black--text" :class="{ 'white--text': !displayOtherQuota }">
            {{ $t('new-event.hcp-support.otherQuota.label') }}
            <v-tooltip v-if="displayOtherQuota" right top transition="dialog-bottom-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-help-circle
                </v-icon>
              </template>
              <span v-html="$t('new-event.hcp-support.otherQuota.moreInfo')" />
            </v-tooltip>
          </div>
          <v-btn
            v-if="!displayOtherQuota"
            @click="displayOtherQuota = true"
            class="text-none"
            color="congrex-primary"
            dark
            depressed
          >
            <v-icon>mdi-plus</v-icon>
            {{ $t('new-event.hcp-support.otherQuota.buttonName') }}
          </v-btn>
          <v-text-field
            v-else
            v-model.number="form.congressEvent.otherQuota"
            :class="{ 'NewEvent-sensibleDataField': sensibleDataFields.includes('otherQuota') }"
            data-e2e="input-other-quota"
            data-vv-name="otherQuota"
            :data-vv-as="$t`new-event.hcp-support.otherQuota.label`"
            data-vv-scope="hcpSupport"
            dense
            hint="x"
            :error-messages="errors.collect('otherQuota', 'hcpSupport')"
            outlined
            persistent-hint
            type="text"
            v-validate="'numeric|required'"
          >
            <template v-slot:message="message">
              <span v-if="message.message !== 'x'">{{message.message}}<br /></span>
              <span v-html="$t('new-event.hcp-support.otherQuota.hint')" />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3" v-else>
          <div class="mb-1 black--text">{{ $t('new-event.hcp-support.hcpQuota.label') }}*</div>
          <v-text-field
            v-model.number="form.biogenEvent.availablePlace"
            :class="{ 'NewEvent-sensibleDataField': sensibleDataFields.includes('availablePlace') }"
            data-e2e="input-available-place"
            data-vv-name="places-number"
            :data-vv-as="$t`places-number`"
            data-vv-scope="hcpSupport"
            dense
            :error-messages="errors.collect('places-number', 'hcpSupport')"
            outlined
            type="text"
            v-validate="'required|numeric'"
          />
        </v-col>
        <v-col v-if="isCongressEvent" cols="12">
          <v-radio-group
            v-model="form.congressEvent.hasAutomatedValidation"
            :class="{ 'NewEvent-sensibleDataBlock': sensibleDataFields.includes('hasAutomatedValidation') }"
            class="mt-0"
            hide-details
            mandatory
          >
            <v-radio :label="$t`auto-validation`" :value="true" color="success" data-e2e="button-auto-validation" />
            <v-radio
              :label="$t`manual-validation`"
              :value="false"
              color="success"
              data-e2e="button-manual-validation"
            />
          </v-radio-group>
        </v-col>
        <v-col v-else cols="12">
          <div class="mb-3 black--text title">{{ $t`invitations` }}</div>
          <v-radio-group v-model="form.biogenEvent.invitationType" mandatory hide-details class="mt-0">
            <v-radio
              v-if="form.biogenEvent.eventType === biogenEventType.MEDICAL"
              :label="$t`medical-invitation`"
              :value="biogenEventInvitationType.MEDICAL"
              color="success"
              data-e2e="button-medical-invitation"
            />
            <v-radio
              v-if="form.biogenEvent.eventType === biogenEventType.MEDICAL"
              :label="$t`medical-invitation-and-medical-up-letter-invitation`"
              :value="biogenEventInvitationType.MEDICAL_UP_LETTER"
              color="success"
              data-e2e="button-medical-invitation-and-medical-up-letter-invitation"
            />
            <v-radio
              v-if="form.biogenEvent.eventType === biogenEventType.COMMERCIAL"
              :label="$t`commercial-invitation`"
              :value="biogenEventInvitationType.COMMERCIAL"
              color="success"
            />
          </v-radio-group>
        </v-col>

        <v-col cols="12" v-if="displayQuotaSwitch">
          <v-switch
            v-model="form.congressEvent.hasZoneQuota"
            color="success"
            :class="{ 'NewEvent-sensibleDataBlock': sensibleDataFields.includes('hasZoneQuota') }"
            hide-details
            data-e2e="switch-has-zone-quota"
            dense
          >
            <template v-slot:label>
              <span class="font-weight-bold" :class="{ 'success--text': form.congressEvent.hasZoneQuota }">
                {{ $t`quota-by-zone` }}
              </span>
            </template>
          </v-switch>
        </v-col>
        <v-col v-if="displayQuotaAllocations" cols="12" class="mb-5">
          <slot name="quotaAllocation" />
          <div v-if="errors.has('overZoneQuota', 'hcpSupport')" class="error--text mt-3">
            {{ errors.first('overZoneQuota', 'hcpSupport') }}
          </div>
          <div v-if="errors.has('wrongNewZoneQuota', 'hcpSupport')" class="error--text mt-3">
            {{ errors.first('wrongNewZoneQuota', 'hcpSupport') }}
          </div>
        </v-col>

        <v-col
          v-if="displayCriterias"
          :class="{ 'NewEvent-sensibleDataBlock': sensibleDataFields.includes('criterias') }"
          cols="12"
        >
          <NewEventCriterias v-model="form" :errorMode="errors.has('criterias', 'hcpSupport')" />
          <div v-if="errors.has('criterias', 'hcpSupport')" class="error--text">
            {{ errors.first('criterias', 'hcpSupport') }}
          </div>
        </v-col>

        <v-col cols="12">
          <div class="mb-2 black--text">{{ $t`import-your-local-hcp-lvc` }}</div>
          <v-col cols="12" md="5" class="pa-0" data-e2e="container-input-lvc-local">
            <base-input-file v-model="form.gathering.lvcLocal" hide-details />
          </v-col>
        </v-col>
        <v-col cols="12">
          <div class="mb-2 black--text">{{ $t`import-your-no-local-hcp-lvc` }}</div>
          <v-col cols="12" md="5" class="pa-0" data-e2e="container-input-lvc-global">
            <base-input-file v-model="form.gathering.lvcGlobal" hide-details />
          </v-col>
        </v-col>
        <v-col cols="12">
          <div class="mb-2 black--text">{{ $t`import-your-military-lvc` }}</div>
          <v-col cols="12" md="5" class="pa-0" data-e2e="container-input-lvc-military">
            <base-input-file v-model="form.gathering.lvcMilitary" hide-details />
          </v-col>
        </v-col>

        <v-col v-if="!isCongressEvent" cols="12" md="4">
          <div class="mb-1 black--text">{{ $t`invitation-end-date` }}*</div>
          <v-menu ref="invitationEndDate" v-model="menu1" offset-y max-width="100%">
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="invitationEndDateFormatted"
                v-on="on"
                v-validate="'required|date_format:dd/MM/yyyy|before:beginDate'"
                data-e2e="input-invitation-end-date"
                data-vv-name="invitationEndDate"
                :data-vv-as="$t`invitation-end-date`"
                data-vv-scope="hcpSupport"
                :error-messages="errors.collect('invitationEndDate', 'hcpSupport')"
                placeholder="25/11/2020"
                persistent-hint
                :hint="$t`hint-invitation-end-date`"
                append-icon="mdi-calendar"
                readonly
                outlined
                dense
              />
            </template>
            <v-date-picker
              @click:date="date => chooseDate(date, 'biogenEvent', 'invitationEndDate')"
              :value="invitationEndDatePickerFormatted"
              data-e2e="date-picker-invitation-end-date"
              first-day-of-week="1"
              locale="fr"
              no-title
            />
          </v-menu>
          <div v-if="invitationEndDateExceeded" class="orange--text font-weight-medium">
            {{ $t('new-event.hcp-support.invitation-end-date-exceeded') }}
          </div>
          <input ref="beginDate" :value="beginDateFormatted" type="hidden" />
        </v-col>
      </v-row>
    </form>
    <input ref="lvcFile" type="file" accept=".pdf" class="d-none" @change="convertToFile('lvc')" />
  </div>
</template>

<script>
import moment from 'moment'

import { toBase64 } from '@/services/file'
import { biogenEventInvitationType, biogenEventType, criterias } from '@/const/shared'
import NewEventCriterias from '@/components/congrex/NewEventCriterias'

export default {
  name: 'NewEventHcpSupport',

  components: { NewEventCriterias },

  inject: ['$validator'],

  model: {
    prop: 'form',
    event: 'change'
  },

  props: {
    form: Object,
    isCongressEvent: Boolean,
    sensibleDataFields: {
      type: Array,
      default: () => []
    },
    totalFilled: Number
  },

  data() {
    return {
      biogenEventInvitationType: biogenEventInvitationType,
      displayOtherQuota: Boolean(this.$get(this.form, 'congressEvent.otherQuota')),
      lvcDocument: '',
      menu1: false
    }
  },

  computed: {
    beginDateFormatted() {
      return this.form.gathering.beginDate ? moment(this.form.gathering.beginDate).format('DD/MM/YYYY') : ''
    },
    biogenEventType() {
      return biogenEventType
    },
    criterias() {
      return Object.keys(criterias).map(key => ({ label: criterias[key], value: key }))
    },
    displayCriterias() {
      return this.isCongressEvent
    },
    displayQuotaAllocations() {
      return (
        this.isCongressEvent &&
        this.displayQuotaSwitch &&
        this.form.gathering.businessUnitUuids.length > 0 &&
        this.form.congressEvent.hasZoneQuota
      )
    },
    displayQuotaSwitch() {
      return (
        this.isCongressEvent &&
        this.form.gathering.businessUnitUuids &&
        this.form.gathering.businessUnitUuids.length > 0
      )
    },
    invitationEndDateExceeded() {
      return moment(this.form.biogenEvent.invitationEndDate).isBefore()
    },
    invitationEndDateFormatted() {
      return this.form.biogenEvent.invitationEndDate
        ? moment(this.form.biogenEvent.invitationEndDate).format('DD/MM/YYYY')
        : ''
    },
    invitationEndDatePickerFormatted() {
      return this.form.biogenEvent.invitationEndDate
        ? moment(this.form.biogenEvent.invitationEndDate).format('YYYY-MM-DD')
        : ''
    },
    solicitationEndDateExceeded() {
      return moment(this.form.congressEvent.solicitationEndDate).isBefore()
    },
    solicitationEndDateFormatted() {
      return this.form.congressEvent.solicitationEndDate
        ? moment(this.form.congressEvent.solicitationEndDate).format('DD/MM/YYYY')
        : ''
    },
    solicitationEndDatePickerFormatted() {
      return this.form.congressEvent.solicitationEndDate
        ? moment(this.form.congressEvent.solicitationEndDate).format('YYYY-MM-DD')
        : ''
    }
  },

  watch: {
    'form.gathering.beginDate': function(newValue) {
      let beginDate = this.form.gathering.beginDate

      if (!beginDate) return

      if (typeof newValue === 'string') {
        beginDate = new Date(beginDate)
      }
      const newDate = new Date(beginDate.getTime())
      // Set date to 6 weeks earlier
      newDate.setDate(newDate.getDate() - 6 * 7)

      if (!this.isCongressEvent) {
        this.$set(this.form.biogenEvent, 'invitationEndDate', newDate)
      }
    },
    'form.biogenEvent.eventType': function(newValue) {
      if (newValue === biogenEventType.MEDICAL) {
        this.$set(this.form.biogenEvent, 'invitationType', biogenEventInvitationType.MEDICAL)
      } else if (newValue === biogenEventType.COMMERCIAL) {
        this.$set(this.form.biogenEvent, 'invitationType', biogenEventInvitationType.COMMERCIAL)
      }
    }
  },

  methods: {
    chooseDate(stringDate, eventType, value) {
      const date = new Date(stringDate)
      this.$set(this.form[eventType], value, date)
    },
    async convertToFile(name) {
      const file = this.$refs[`${name}File`].files[0]
      this.updateFile(file, name)
    },
    async updateFile(file, name) {
      if (!file) {
        this.form.congressEvent[name] = ''
        return
      }

      const base64 = await toBase64(file)
      this.$emit('change', { ...this.form, [name]: base64 })
      this[`${name}Document`] = file
    },
    validate() {
      if ((!this.serf.signature && !this.serf.scan) || this.necessaryFieldsFill < 2) {
        this.isValid = false
      } else {
        this.$validator.validateAll().then(isValid => (this.isValid = isValid))
      }
    }
  }
}
</script>
