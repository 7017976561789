<template>
  <div class="px-7 py-5">
    <div v-if="virtualSession">
      <virtual-session-tile :virtual-session="virtualSession" />
    </div>
    <div v-else>
      <event-validation-header
        :gathering="gathering"
        :is-congress="isCongress"
        :can-validate="canValidate"
        @refresh="$apollo.queries.gathering.refetch()"
      />

      <informations-tile
        :gathering="mergedSensiblesGathering"
        :show-sensible-changes="showSensibleChanges"
        class="mb-6"
        only-open
      />

      <team-tile :gathering="mergedSensiblesGathering" class="mb-6" only-open />

      <business-unit-tile
        v-for="buUuid of businessUnitUuids"
        :key="buUuid"
        :business-unit-uuid="buUuid"
        :gathering="mergedSensiblesGathering"
        :gathering-business-unit="gatheringBusinessUnitsByBuUuid[buUuid]"
        :raw-gathering="gathering"
        :is-main="gathering.mainBusinessUnitUuid === buUuid"
        :show-sensible-changes="showSensibleChanges"
        class="mb-6"
        only-open
      />

      <documents-tile :gathering="mergedSensiblesGathering" class="mb-6" only-open />

      <historic-tile v-if="showHistoric" :gathering="mergedSensiblesGathering" only-open />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import {
  GET_ALL_SETTINGS_GATHERING_WITH_VALIDATION,
  GET_ALL_SETTINGS_GATHERING_WITH_VALIDATION_NO_HISTORIC
} from '@/graphql/Gatherings/GetGathering'
import { mergeSensibleChanges } from '@/services/gathering/mergeSensibleChanges'

import EventValidationHeader from '@/components/congrex/gathering/EventValidationHeader'
import InformationsTile from '@/components/mybb/gathering/InformationsTile'
import TeamTile from '@/components/mybb/gathering/TeamTile'
import BusinessUnitTile from '@/components/mybb/gathering/BusinessUnitTile'
import DocumentsTile from '@/components/mybb/gathering/DocumentsTile'
import HistoricTile from '@/components/mybb/gathering/HistoricTile'
import { VirtualSessionTile } from '@/components/mybb/virtualSession'

export default {
  name: 'EventInformations',
  components: {
    InformationsTile,
    TeamTile,
    BusinessUnitTile,
    DocumentsTile,
    EventValidationHeader,
    HistoricTile,
    VirtualSessionTile
  },
  props: {
    showSensibleChanges: {
      type: Boolean
    },
    virtualSession: {
      type: Object
    }
  },
  computed: {
    ...mapState({ user: 'myUser' }),
    isCongress() {
      return Boolean(this.gathering) && Boolean(this.gathering.congress)
    },
    businessUnitUuids() {
      if (!this.mergedSensiblesGathering) return []

      const businessUnitUuids = [this.mergedSensiblesGathering.mainBusinessUnitUuid]

      if (this.mergedSensiblesGathering.hasOwnProperty('businessUnitUuids')) {
        businessUnitUuids.push(
          ...this.mergedSensiblesGathering.businessUnitUuids.filter(
            uuid => uuid !== this.mergedSensiblesGathering.mainBusinessUnitUuid
          )
        )
      } else if (this.mergedSensiblesGathering.hasOwnProperty('businessUnits')) {
        businessUnitUuids.push(
          ...this.mergedSensiblesGathering.businessUnits
            .map(bu => bu.businessUnitUuid)
            .filter(uuid => uuid !== this.mergedSensiblesGathering.mainBusinessUnitUuid)
        )
      }

      return businessUnitUuids
    },
    gatheringBusinessUnitsByBuUuid() {
      if (!this.mergedSensiblesGathering) return []

      const gatheringBusinessUnits = Array.from(this.mergedSensiblesGathering.gatheringBusinessUnits)
      const mainBuIndex = gatheringBusinessUnits.findIndex(
        bu => bu.businessUnitUuid === this.gathering.mainBusinessUnitUuid
      )
      if (mainBuIndex >= 0) {
        const [mainBu] = gatheringBusinessUnits.splice(mainBuIndex, 1)
        gatheringBusinessUnits.unshift(mainBu)
      }

      return gatheringBusinessUnits.reduce((acc, gbu) => ({ ...acc, [gbu.businessUnitUuid]: gbu }), {})
    },
    mergedSensiblesGathering() {
      if (!this.gathering || !this.showSensibleChanges) return this.gathering

      return mergeSensibleChanges(this.gathering)
    },
    latestSensibleChanges() {
      if (!this.gathering) return null

      return Array.from(this.gathering.gatheringSensibleChanges || [])
        .filter(gsc => gsc.status === this.$const.gatheringSensibleChangeStatus.PENDING)
        .sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt))[0]
    },
    canValidate() {
      if (
        !this.gathering ||
        (this.gathering.status !== this.$const.gatheringStatus.TO_VALIDATE && !this.latestSensibleChanges) ||
        !this.user
      )
        return false

      const gatheringUsers = this.gathering.users

      if (!Array.isArray(gatheringUsers) || (Array.isArray(gatheringUsers) && gatheringUsers.length <= 0)) return false

      const { SUPER_ADMIN, ADMIN } = this.$const.userType

      if (this.user.userTypes.includes(SUPER_ADMIN) || this.user.userTypes.includes(ADMIN)) return true

      if (this.isCongress) {
        const medicalDirector = gatheringUsers.find(
          usr =>
            usr.gatheringUserType === this.$const.gatheringUserType.MEDICAL_DIRECTOR &&
            usr.businessUnitUuid === this.gathering.mainBusinessUnitUuid &&
            usr.userUuid === this.user.userUuid
        )

        return Boolean(medicalDirector)
      } else {
        const projectManager = gatheringUsers.find(
          usr =>
            usr.gatheringUserType === this.$const.gatheringUserType.PROJECT_MANAGER &&
            usr.businessUnitUuid === this.gathering.mainBusinessUnitUuid &&
            usr.userUuid === this.user.userUuid
        )

        return Boolean(projectManager)
      }
    },
    showHistoric() {
      if (!this.gathering || !this.user) return false

      const gatheringUsers = this.gathering.users

      if (!Array.isArray(gatheringUsers) || (Array.isArray(gatheringUsers) && gatheringUsers.length <= 0)) return false

      const { SUPER_ADMIN } = this.$const.userType

      if (this.user.userTypes.includes(SUPER_ADMIN)) return true

      if (this.isCongress) {
        const medicalDirector = gatheringUsers.find(
          usr =>
            usr.gatheringUserType === this.$const.gatheringUserType.MEDICAL_DIRECTOR &&
            usr.businessUnitUuid === this.gathering.mainBusinessUnitUuid &&
            usr.userUuid === this.user.userUuid
        )

        return Boolean(medicalDirector)
      } else {
        const projectManager = gatheringUsers.find(
          usr =>
            usr.gatheringUserType === this.$const.gatheringUserType.PROJECT_MANAGER &&
            usr.businessUnitUuid === this.gathering.mainBusinessUnitUuid &&
            usr.userUuid === this.user.userUuid
        )

        return Boolean(projectManager)
      }
    }
  },
  apollo: {
    gathering: {
      query() {
        return this.showHistoric
          ? GET_ALL_SETTINGS_GATHERING_WITH_VALIDATION
          : GET_ALL_SETTINGS_GATHERING_WITH_VALIDATION_NO_HISTORIC
      },
      variables() {
        return { gatheringUuid: this.$route.params.gatheringUuid }
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`mybb.gatheringDetails.${key}`, params)
    }
  }
}
</script>
