<template>
  <v-container fluid class="EventList pa-0">
    <div class="toolbar mb-8 px-4 pt-2">
      <div class="font-weight-bold d-block mb-3">{{ $t`search-an-event` }}</div>
      <v-row align="center" class="mb-1">
        <v-col cols="12" md="5" class="pt-0">
          <v-text-field v-model="query.searchText" clearable outlined dense hide-details />
        </v-col>
        <v-col class="pt-0 flex-grow-0">
          <v-btn @click="toggleFilters" text>
            <v-icon color="congrex-primary" left size="25">mdi-tune</v-icon>
            <span class="text-capitalize">{{ $t`filters` }}</span>
          </v-btn>
        </v-col>
        <v-col class="pt-0 flex-grow-0">
          <v-btn v-if="displayClearFilters" @click="clearFilters" color="primary" text small class="text-none">{{
            $t`clear-filters`
          }}</v-btn>
        </v-col>
        <template v-if="hasRight($const.right.confirmSolicitation)">
          <v-spacer />
          <v-col class="pt-0">
            <v-btn :to="{ name: 'SolicitationsManagement' }" class="body-2 text-none" color="primary" depressed>
              <v-icon left small>mdi-clipboard-text-outline</v-icon>
              {{ $t`button-solicitation-management` }}
            </v-btn>
          </v-col>
        </template>
      </v-row>

      <v-expansion-panels :value="displayFilters" readonly accordion class="expansion-panels mt-1">
        <v-expansion-panel>
          <v-expansion-panel-content eager class="expansion-content">
            <v-row no-gutters dense>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="query.rocUuids"
                  :items="rocUsers"
                  :menu-props="{ offsetY: true, 'min-width': '180px' }"
                  :label="'Responsable ROC'"
                  height="40"
                  class="mr-2"
                  hide-details
                  multiple
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row class="my-2" align="center" no-gutters dense>
              <v-col
                v-if="hasRight($const.right.readBiogenEvent) && hasRight($const.right.readCongress) && !isAbm"
                cols="12"
                md="2"
                sm="6"
              >
                <v-select
                  v-model="eventTypeSelected"
                  :items="eventTypesComputed"
                  :menu-props="{ offsetY: true, 'min-width': '260px' }"
                  :label="$t('event-list.filter.event-type-label')"
                  class="mr-2"
                  hide-details
                  multiple
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  v-model="query.gatheringFormat"
                  :items="gatheringFormatComputed"
                  :menu-props="{ offsetY: true, 'min-width': '200px' }"
                  :label="$t('event-list.gathering-format.selectLabel')"
                  class="mr-2"
                  hide-details
                  multiple
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  v-model="query.international"
                  :items="typesComputed"
                  :menu-props="{ offsetY: true, 'min-width': '200px' }"
                  :label="$t('event-list.localisation-label')"
                  class="mr-2"
                  hide-details
                  multiple
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-menu
                  ref="rangeDate"
                  v-model="menuDatePicker"
                  :close-on-content-click="false"
                  offset-y
                  max-width="100%"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="rangeDateFormatted"
                      v-on="on"
                      :placeholder="$t`dates`"
                      append-icon="mdi-calendar"
                      class="mr-2"
                      hide-details
                      readonly
                      outlined
                      dense
                      @click:append="on.click"
                    />
                  </template>
                  <v-date-picker
                    v-model="rangeDate"
                    color="congrex-primary"
                    range
                    locale="fr"
                    no-title
                    first-day-of-week="1"
                  >
                    <v-spacer />
                    <v-btn text color="congrex-primary" @click="clearRangeDate">{{ $t`clear` }}</v-btn>
                    <v-btn text color="congrex-primary" @click="chooseRangeDate">{{ $t`validate` }}</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="d-flex justify-center justify-md-start mb-4 mb-md-0" md="3">
                <v-switch
                  v-if="hasRight($const.right.searchArchivedGatherings)"
                  @change="toggleArchived"
                  class="pl-5 pt-0 mt-0"
                  color="success"
                  hide-details
                  :label="$t('event-list.toggle-archived-label')"
                  value="false"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <!-- LEGACY - Display the button of event creation -->
    <!-- <ModalCreateEvent v-if="hasRight($const.right.createBiogenEvent) || hasRight($const.right.createCongress)">
      <template v-slot:default="{ activate }">
        <div class="text-center">
          <v-btn
            @click="activate.click"
            class="text-none"
            color="primary"
            data-e2e="modal-create-event-button"
            depressed
            rounded
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t('event-list.create-event-button') }}
          </v-btn>
        </div>
      </template>
    </ModalCreateEvent> -->

    <v-container fluid style="padding-bottom: 8px">
      <v-container class="relative">
        <v-row v-if="!eventsLoaded" justify="center">
          <v-progress-circular color="congrex-primary" size="64" indeterminate />
        </v-row>
        <vue-events-by-category
          v-else
          :events="events"
          :virtual-sessions="virtualSessions"
          @refresh="$apollo.queries.events.refetch()"
        />
      </v-container>

      <v-container v-if="eventsLoaded && events.length === 0">
        <v-row justify="center" class="text-center">
          <v-col cols="12">
            <v-alert text color="primary">
              {{ $t`no-result-found` }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import _sortBy from 'lodash/sortBy'
import _upperFirst from 'lodash/upperFirst'
import moment from 'moment'
import { mapGetters } from 'vuex'

import { hasOneOfType } from '@/services/user'
import { nationalityType, gatheringFilterType, gatheringPeriod, userType } from '@/const/shared'
import { GET_MYBB_GATHERINGS, GET_GATHERINGS_CONFIRMED_INVITATIONS } from '@/graphql/Gatherings/GetGatherings'
import { LIST_VIRTUAL_SESSION_ITEM_CONGREX } from '@/graphql/VirtualSession'
// import ModalCreateEvent from '@/components/congrex/ModalCreateEvent'
import EventsByCategory from '@/components/congrex/EventsByCategory'
import { GET_MANAGERS } from '@/graphql/User/SearchUsers'

const queryInit = {
  buUuids: [],
  gatheringFormat: [],
  international: [],
  limit: 1000,
  rocUuids: [],
  searchText: '',
  type: 'ALL',
  when: [],
  isCongrexBo: null
}

export default {
  name: 'EventList',

  components: {
    'vue-events-by-category': EventsByCategory
    // ModalCreateEvent
  },

  data() {
    return {
      displayFilters: null,
      events: [],
      eventTypesComputed: Object.values(gatheringFilterType)
        .slice(1)
        .map(value => ({
          text: this.$t(`event-list.filter.event-type.${value}`),
          value: value
        })),
      eventTypeSelected: [],
      eventsLoaded: false,
      menuDatePicker: false,
      query: { ...queryInit },
      rangeDate: [],
      rocUsers: []
    }
  },

  apollo: {
    events: {
      query: GET_MYBB_GATHERINGS,
      variables() {
        this.eventsLoaded = false

        if (!this.query.dateFrom) {
          // eslint-disable-next-line no-unused-vars
          const { dateTo, dateFrom, ...withoutDate } = this.query
          return withoutDate
        }

        return this.query
      },
      update: data =>
        data.gatherings.map(event => {
          if (event.congress) {
            event.congress.zoneCongresses = _sortBy(event.congress.zoneCongresses, [
              'zone.businessUnit.label',
              'zone.label'
            ])
          }

          return event
        }),
      async result() {
        this.eventsLoaded = true

        let variables = { type: this.$const.gatheringFilterType.BIOGEN_EVENT }
        if (!this.query.dateFrom) {
          // eslint-disable-next-line no-unused-vars
          const { dateTo, dateFrom, ...withoutDate } = this.query
          variables = { ...withoutDate, ...variables }
        } else {
          variables = { ...this.query, ...variables }
        }

        const {
          data: { gatherings }
        } = await this.$apollo.query({
          query: GET_GATHERINGS_CONFIRMED_INVITATIONS,
          variables
        })
        this.updateEvents(gatherings)
      },
      debounce: 300,
      deep: true
    },
    virtualSessions: {
      query: LIST_VIRTUAL_SESSION_ITEM_CONGREX
    },
    rocUsers: {
      query: GET_MANAGERS,
      variables() {
        return { limit: 1000, userTypes: [this.$const.userType.ROC] }
      },
      update: ({ searchUsers }) =>
        _sortBy(
          searchUsers.rows.map(user => ({
            text: `${_upperFirst(user.firstName)} ${_upperFirst(user.lastName)}`,
            value: user.userUuid
          })),
          ['text']
        )
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    bus() {
      return _sortBy(this.$store.state.bus.map(bu => ({ value: bu.businessUnitUuid, text: bu.label })), ['text'])
    },
    displayClearFilters() {
      return JSON.stringify(this.query) !== JSON.stringify(queryInit)
    },
    gatheringFormatComputed() {
      return Object.keys(this.$const.gatheringFormat).map(key => ({
        text: this.$t(`event-list.gathering-format.${key}`),
        value: key
      }))
    },
    isAbm() {
      return hasOneOfType(userType.ABM)
    },
    userType() {
      return this.$store.state.myUser.userTypes
    },
    rangeDateFormatted() {
      if (this.rangeDate.length < 2) return ''

      const rangeDate = _sortBy(this.rangeDate)
      return `${moment(rangeDate[0]).format('DD/MM/YYYY')} - ${moment(rangeDate[1]).format('DD/MM/YYYY')}`
    },
    typesComputed() {
      return Object.keys(nationalityType).map(key => ({
        text: this.$t(`nationality-type-${key.toLowerCase()}`),
        value: key
      }))
    }
  },

  watch: {
    eventTypeSelected(value) {
      if (value.length === 1) {
        this.$set(this.query, 'type', value.join())
      } else {
        this.$set(this.query, 'type', this.$const.gatheringFilterType.ALL)
      }
    }
  },

  methods: {
    chooseRangeDate() {
      const rangeDate = this.rangeDate.sort()
      this.menuDatePicker = false
      this.$set(this.query, 'dateFrom', new Date(rangeDate[0]))
      this.$set(this.query, 'dateTo', new Date(rangeDate[1]))
    },
    clearFilters() {
      this.clearRangeDate()
      this.$set(this, 'query', { ...queryInit })
    },
    clearRangeDate() {
      this.query.dateFrom = ''
      this.query.dateTo = ''
      this.rangeDate = []
      this.menuDatePicker = false
    },
    toggleArchived(value) {
      if (value === 'false') {
        this.query.when.push(gatheringPeriod.ARCHIVED)
      } else {
        const index = this.query.when.indexOf(gatheringPeriod.ARCHIVED)
        if (index > -1) this.query.when.splice(index, 1)
      }
    },
    toggleFilters() {
      if (this.displayFilters === 0) {
        this.displayFilters = null
      } else {
        this.displayFilters = 0
      }
    },
    updateEvents(confirmedInvitationsGatherings) {
      this.events.forEach((event, index) => {
        if (event.biogenEvent) {
          const confirmed = confirmedInvitationsGatherings.find(
            confirmed => confirmed.gatheringUuid === event.gatheringUuid
          )

          this.$set(this.events[index].biogenEvent, 'confirmedInvitations', confirmed.biogenEvent.confirmedInvitations)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.EventList {
  .expansion-content .v-select__selections {
    flex-wrap: nowrap;
  }

  .expansion-panels {
    :before {
      box-shadow: none;
    }
    .v-expansion-panel-header,
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }
}
</style>

<style scoped lang="scss">
.EventList {
  button:focus {
    outline: 0;
  }

  .toolbar {
    position: sticky;
    top: 97px; // 50px for app bar + 48px for tabs (-1px to prevent gaps)
    z-index: 1;
    background: white;
    box-shadow: 0 5px 7px #a0a0a033;
    border-bottom: 1px solid #a0a0a04a;

    .hide-filters-text {
      font-size: 0.8em;
    }
    .container-rounded-buttons {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
      padding: 1px;
      color: var(--v-congrex-primary-base);
      .rounded-buttons {
        padding: 1px;
        border: 1px solid var(--v-congrex-primary-base);
        border-radius: 10px;
        cursor: pointer;
        & > * {
          padding: 6px 16px;
          &.active {
            background: var(--v-congrex-primary-base);
            color: white;
          }
          &:first-child,
          blop {
            border-radius: 10px 0 0 10px;
          }
          &:last-child {
            border-radius: 0 10px 10px 0;
          }
        }
      }
    }
  }
  .fixed-button {
    position: fixed;
    z-index: 8;
    bottom: 30px;
    right: 30px;
  }
}
</style>
