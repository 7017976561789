/**
 * Give an array with all dates between start and end (includes)
 * @param {string|Date} start
 * @param {string|Date} end
 * @returns {Array}
 */
export function dateRange(start, end) {
  const startDate = new Date(start)
  const endDate = new Date(end)

  if (isNaN(startDate.getMonth()) || isNaN(endDate.getMonth())) {
    console.error('"start" and "end" params have to date')
    return []
  }

  const range = []
  const date = new Date(startDate)

  while (date <= endDate) {
    range.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }

  return range
}
