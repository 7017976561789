<template>
  <v-card>
    <div v-if="zonesByBu.length > 0">
      <v-card-title>{{ $t('choose-solicitation-select-zone.title') }}</v-card-title>
      <v-card-text>{{ $t('choose-solicitation-select-zone.sub-text') }}</v-card-text>
      <v-card-text>
        <div v-for="zoneBu in zonesByBu" :key="zoneBu.zoneUuid" class="my-6">
          <v-alert color="congrex-primary" dark dense>{{ zoneBu.bu.label }}</v-alert>
          <v-radio-group v-model="zoneUuid" :mandatory="false">
            <v-radio
              v-for="zone in zoneBu.zones"
              :key="zone.zoneUuid"
              class="pl-md-5"
              color="congrex-primary"
              :value="zone.zoneUuid"
            >
              <template v-slot:label>
                <div class="d-flex" v-cy="$cy.congrex.solicitation.validation.zoneQuota.zone(zone.label)">
                  <div>{{ zone.label }}</div>
                  <div class="ml-5">
                    {{
                      $tc('choose-solicitation-select-zone.rest', zone.zoneQuota - zone.filledZoneQuota, {
                        value: zone.zoneQuota - zone.filledZoneQuota
                      })
                    }}
                  </div>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
        <v-textarea
          v-model="localComment"
          @input="$emit('change', $event)"
          auto-grow
          :comment="comment"
          v-cy="$cy.congrex.solicitation.validation.zoneQuota.comment"
          :data-vv-as="$t('choose-solicitation-select-zone.label-name')"
          data-vv-name="comment"
          :label="$t('choose-solicitation-select-zone.label-comment')"
          outlined
          v-validate="'required'"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn @click="$emit('cancel')" depressed text color="error">{{
          $t('choose-solicitation-select-zone.cancel-button')
        }}</v-btn>
        <v-btn
          @click="$emit('confirm', zoneUuid)"
          depressed
          :disabled="!zoneUuid || !comment"
          text
          color="success"
          v-cy="$cy.congrex.solicitation.validation.zoneQuota.submit"
        >
          {{ $t('choose-solicitation-select-zone.confirm-button') }}
        </v-btn>
      </v-card-actions>
    </div>
    <div v-else>
      <v-card-title>{{ $t('choose-solicitation-select-zone.no-zone-title') }}</v-card-title>
      <v-card-text>{{ $t('choose-solicitation-select-zone.no-zone-sub-text') }}</v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn @click="$emit('cancel')" depressed text color="error">{{
          $t('choose-solicitation-select-zone.no-zone-close')
        }}</v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { GET_QUOTA_BY_ZONE } from '@/graphql/Solicitation/Solicitation'

export default {
  name: 'ChooseSolicitationZone',

  model: {
    prop: 'comment',
    event: 'change'
  },

  props: {
    comment: {
      type: String,
      required: true
    },
    solicitationUuid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      localComment: '',
      zoneUuid: ''
    }
  },

  computed: {
    zonesByBu() {
      if (!this.$get(this.solicitation, 'congress.zoneCongresses')) return []

      const congressZones = this.solicitation.congress.zoneCongresses
      const congressZoneUuids = congressZones.map(zone => zone.zoneUuid)
      return this.$store.state.bus
        .filter(bu => bu.businessUnitUuid === this.solicitation.businessUnitUuid)
        .filter(bu => bu.zones.some(zone => congressZoneUuids.includes(zone.zoneUuid)))
        .map(bu => {
          const zones = bu.zones
            .map(zone => {
              const zoneUuid = zone.zoneUuid

              const congressZone = this.solicitation.congress.zoneCongresses.find(z => z.zoneUuid === zoneUuid)
              const statZone = this.solicitation.congress.stats.zones.find(z => z.zoneUuid === zoneUuid)
              const label = bu.zones.find(buZone => buZone.zoneUuid === zoneUuid).label

              return {
                zoneQuota: this.$get(statZone, 'zoneQuota', 0),
                filledZoneQuota: this.$get(statZone, 'filledZoneQuota', 0),
                ...congressZone,
                label
              }
            })
            .filter(zone => zone.zoneQuota - zone.filledZoneQuota > 0)

          return { bu, zones }
        })
        .filter(buZone => buZone.zones.length > 0)
    }
  },

  apollo: {
    solicitation: {
      query: GET_QUOTA_BY_ZONE,
      variables() {
        return { solicitationUuid: this.solicitationUuid }
      }
    }
  }
}
</script>
