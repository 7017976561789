import gql from 'graphql-tag'

export const GET_GUEST = gql`
  query guest($eveniumEventId: String!, $eveniumGuestId: String!, $gatheringUuid: GUID!) {
    guest(eveniumEventId: $eveniumEventId, eveniumGuestId: $eveniumGuestId) {
      contactId
      email
      firstName
      gender
      lastName
      status
      transportWaybackDate
      transportWaybackType
      transportWaytoDate
      transportWaytoType
      transportStatus
      category {
        id
        label
      }
      fields {
        name
        value
      }
      healthCareProfessional {
        healthCareProfessionalUuid
        hcpGatheringDates(gatheringUuid: $gatheringUuid) {
          signatureDate
        }
      }
      event {
        gathering {
          beginDate
          city
          endDate
        }
      }
      solicitation {
        solicitationUuid
        confirmationDate
      }
    }
  }
`
