<template>
  <div>
    <div class="text-center mb-5">
      <!-- Validated -->
      <span v-if="isValidated">
        <mybb-text weight="bold" color="primary">
          {{ t('validated-text', validationInformations) }}
        </mybb-text>
      </span>

      <!-- Ask corrections -->
      <span v-else-if="correctionsAreRequired || canValidate">
        <!-- CTAs -->
        <div v-if="gathering.status !== $const.gatheringStatus.CORRECTION" class="mb-4">
          <mybb-btn
            :loading="loading"
            class="mr-10"
            color="mybb-warning"
            @click="askCorrectionModal = true"
            v-cy="$cy.congrex.gathering.validation.askCorrection"
          >
            {{ t('ask-correction') }}
          </mybb-btn>
          <mybb-btn :loading="loading" @click="validateModal = true" v-cy="$cy.congrex.gathering.validation.validate">
            {{ t('validate') }}
          </mybb-btn>
        </div>

        <div v-if="correctionsAreRequired">
          <mybb-text weight="bold" color="mybb-warning">
            {{ t('correction-text', correctionInformations) }}
          </mybb-text>
        </div>
      </span>
    </div>

    <!-- Validate modal -->
    <modal v-model="validateModal" :title="vmt('title')">
      <mybb-text>{{ vmt('text') }}</mybb-text>
      <v-checkbox
        v-model="doubleConfirmation"
        :label="vmt('checkbox')"
        dense
        v-cy="$cy.congrex.gathering.validation.modal.doubleConfirmation"
      />

      <div class="text-center mt-10">
        <mybb-btn
          :disabled="loading"
          color="mybb-grey"
          class="mr-10"
          @click="validateModal = false"
          v-cy="$cy.congrex.gathering.validation.modal.cancel"
        >
          {{ vmt('cancel') }}
        </mybb-btn>
        <mybb-btn
          :loading="loading"
          :disabled="!doubleConfirmation || loading"
          @click="validate"
          v-cy="$cy.congrex.gathering.validation.modal.submit"
        >
          {{ vmt('confirm') }}
        </mybb-btn>
      </div>
    </modal>

    <!-- Correction modal -->
    <modal v-model="askCorrectionModal" :title="acmt('title')">
      <mybb-text>{{ acmt('text') }}</mybb-text>
      <mybb-text class="my-4" weight="bold">{{ acmt('highlight') }}</mybb-text>

      <mybb-textarea v-model="comment" :label="acmt('comment')" icon="mdi-comment-text" />

      <div class="text-center mt-10">
        <mybb-btn :disabled="loading" color="mybb-grey" class="mr-10" @click="askCorrectionModal = false">
          {{ acmt('cancel') }}
        </mybb-btn>
        <mybb-btn :loading="loading" :disabled="!comment" color="mybb-warning" @click="askCorrection">
          {{ acmt('confirm') }}
        </mybb-btn>
      </div>
    </modal>
  </div>
</template>

<script>
import dateFormat from 'date-fns/format'

import { VALIDATE_ALL_GATHERING_SECTIONS, ASK_CORRECTION_GATHERING } from '@/graphql/Gatherings/ValidateGathering'
import Modal from '@/components/mybb/Modal'

export default {
  name: 'EventValidationHeader',
  components: { Modal },
  props: {
    gathering: {
      type: Object,
      validator(prop) {
        return !prop || (prop && prop.hasOwnProperty('gatheringUuid') && prop.hasOwnProperty('users'))
      }
    },
    isCongress: {
      type: Boolean
    },
    canValidate: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      comment: '',
      validateModal: false,
      askCorrectionModal: false,
      doubleConfirmation: false
    }
  },
  computed: {
    latestValidation() {
      if (!this.gathering) return null

      return (
        Array.from(this.gathering.gatheringValidations || [])
          // Only the global section is trully used for now
          .filter(validation => validation.section === this.$const.gatheringValidationSection.GLOBAL)
          .sort((a, b) => new Date(a.date) - new Date(b.date))[0]
      )
    },
    latestSensibleChanges() {
      if (!this.gathering) return null

      return Array.from(this.gathering.gatheringSensibleChanges || [])
        .filter(gsc => gsc.status === this.$const.gatheringSensibleChangeStatus.PENDING)
        .sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt))[0]
    },
    isValidated() {
      const { CORRECTION, DRAFT, TO_VALIDATE } = this.$const.gatheringStatus

      if (!this.gathering || !this.latestValidation) return false

      if ([CORRECTION, DRAFT, TO_VALIDATE].includes(this.gathering.status)) return false

      const hasPendingChanges = this.latestSensibleChanges
        ? new Date(this.latestValidation.date) < new Date(this.latestSensibleChanges.updatedAt)
        : false

      return !hasPendingChanges && this.latestValidation.status === this.$const.gatheringValidationStatus.VALIDATED
    },
    validationInformations() {
      if (!this.isValidated || !this.latestValidation) return {}

      const {
        validator: { firstName, lastName },
        date
      } = this.latestValidation

      return {
        firstName,
        lastName,
        date: dateFormat(new Date(date), 'dd/MM/yy')
      }
    },
    correctionsAreRequired() {
      const { DRAFT, TO_VALIDATE } = this.$const.gatheringStatus

      if (!this.gathering || !this.latestValidation) return false

      if ([DRAFT, TO_VALIDATE].includes(this.gathering.status)) return false

      const hasPendingChanges = this.latestSensibleChanges
        ? new Date(this.latestValidation.date) < new Date(this.latestSensibleChanges.updatedAt)
        : false

      return (
        !hasPendingChanges && this.latestValidation.status === this.$const.gatheringValidationStatus.REQUIRE_CORRECTION
      )
    },
    correctionInformations() {
      if (!this.correctionsAreRequired || !this.latestValidation) return {}

      const {
        validator: { firstName, lastName },
        date
      } = this.latestValidation

      return {
        firstName,
        lastName,
        date: dateFormat(new Date(date), 'dd/MM/yy')
      }
    }
  },
  methods: {
    t(key, params) {
      return this.$t(`event-details.${key}`, params)
    },
    vmt(key, params) {
      return this.t(`validate-modal.${key}`, params)
    },
    acmt(key, params) {
      return this.t(`correction-modal.${key}`, params)
    },
    async validate() {
      if (this.loading) return
      this.loading = true

      try {
        await this.$apollo.mutate({
          mutation: VALIDATE_ALL_GATHERING_SECTIONS,
          variables: {
            gatheringUuid: this.gathering.gatheringUuid
          }
        })

        this.validateModal = false
        this.$emit('refresh')
      } catch (error) {
        this.$bus.$emit('showSnackBarGlobal', { text: error.message, backgroundColor: 'mybb-error' })
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async askCorrection() {
      if (this.loading) return
      this.loading = false

      try {
        await this.$apollo.mutate({
          mutation: ASK_CORRECTION_GATHERING,
          variables: {
            gatheringUuid: this.gathering.gatheringUuid,
            comment: this.comment
          }
        })

        this.askCorrectionModal = false
        this.comment = ''
        this.$emit('refresh')
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    validateModal(val, old) {
      if (old && !val) {
        this.doubleConfirmation = false
      }
    },
    askCorrectionModal(val, old) {
      if (old && !val) {
        this.doubleConfirmation = false
      }
    }
  }
}
</script>
