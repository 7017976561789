<template>
  <div class="Tabs">
    <v-overlay v-if="!gathering && !virtualSession">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <template v-else>
      <base-banner
        :imageUrl="bannerSrcImg"
        :title="name"
        :titleLogo="isVirtual ? 'mdi-monitor' : null"
        :subTitle="subtitle"
        :returnRoute="{ name: 'Congrex' }"
        :gathering="gathering"
        :currentParticipant="currentParticipant"
        @refresh="$apollo.queries.gathering.refetch()"
      />

      <v-tabs v-model="tab" grow show-arrows>
        <v-tab v-if="isCongressEvent" data-e2e="button-tab-solicitations" href="#solicitations">
          {{ $t`solicitations` }}
        </v-tab>
        <v-tab v-else data-e2e="button-tab-invitations" href="#invitations">{{ $t`invitations` }}</v-tab>
        <v-tab data-e2e="button-tab-logistic" href="#logistic">{{ $t('tabs.participant') }}</v-tab>
        <v-tab v-if="displaySponsorshipTab" data-e2e="button-tab-sponsorship" href="#sponsorship">
          {{ $t`sponsorship` }}
        </v-tab>
        <v-tab data-e2e="button-tab-general-information" href="#generalInformations">
          {{ $t`general-information` }}
        </v-tab>
        <v-tab
          v-if="hasRight($const.right.readHistoricalRecord) && !virtualSession"
          data-e2e="button-tab-historical"
          href="#historical"
        >
          {{ $t`historical` }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="solicitations" v-if="isCongressEvent">
          <vue-solicitation-list />
        </v-tab-item>
        <v-tab-item value="invitations" v-else>
          <vue-invitation-list :virtual-session="virtualSession" />
        </v-tab-item>
        <v-tab-item value="logistic">
          <!-- <vue-logistic /> -->
          <participants :gathering="gathering" :virtual-session="virtualSession" />
        </v-tab-item>
        <v-tab-item v-if="displaySponsorshipTab" value="sponsorship">
          <vue-sponsorship />
        </v-tab-item>
        <v-tab-item value="generalInformations">
          <vue-event-general :show-sensible-changes="showSensibleChanges" :virtual-session="virtualSession" />
        </v-tab-item>
        <v-tab-item v-if="hasRight($const.right.readHistoricalRecord)" value="historical">
          <vue-event-historical />
        </v-tab-item>
      </v-tabs-items>
      <v-btn
        v-if="tab === 0"
        @click="openDialogHcpList"
        class="fixed-button"
        fab
        dark
        large
        color="congrex-primary"
        fixed
        right
      >
        <v-icon dark>mdi-account-plus-outline</v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import moment from 'moment'

import EventGeneral from '@/components/congrex/tabs/EventInformations'
import Participants from '@/components/congrex/tabs/Participants'
// import Logistique from '@/components/congrex/tabs/Logistique'
import Historical from '@/components/congrex/tabs/Historical'
import { VIRTUAL_SESSION_CONGREX } from '@/graphql/VirtualSession'
import { GET_ALL_SETTINGS_GATHERING } from '@/graphql/Gatherings/GetGathering'
import Solicitations from '@/components/congrex/tabs/Solicitations'
import InvitationList from '@/components/congrex/tabs/InvitationList'
import Sponsorship from '@/components/congrex/tabs/Sponsorship'

export default {
  name: 'Tabs',

  components: {
    'vue-event-general': EventGeneral,
    'vue-event-historical': Historical,
    'vue-invitation-list': InvitationList,
    Participants,
    // 'vue-logistic': Logistique,
    'vue-solicitation-list': Solicitations,
    'vue-sponsorship': Sponsorship
  },

  data() {
    return {
      tab: this.$route.query.tab
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    ...mapState({ user: 'myUser' }),
    isVirtualSession() {
      return [true, 'true'].includes(this.$route.query.virtualSession)
    },
    name() {
      if (!this.gathering && !this.virtualSession) return null

      return this.gathering ? this.gathering.name : this.virtualSession.name
    },
    isVirtual() {
      if (!this.gathering && !this.virtualSession) return null

      return this.gathering ? this.gathering.isVirtual : true
    },
    bannerSrcImg() {
      if (this.isCongressEvent) {
        return require('@/assets/solicitation-background.jpg')
      }

      return require('@/assets/banner-biogen-event.jpg')
    },
    beginDate() {
      if (!this.gathering && !this.virtualSession) return

      const date = this.gathering ? this.gathering.beginDate : this.virtualSession.beginDate

      return new Date(date).getDate()
    },
    displaySponsorshipTab() {
      return (
        this.hasRight(this.$const.right.readAllSponsorshipProofs) &&
        this.$get(this.gathering, 'congress.sponsorships.length') > 0
      )
    },
    endDate() {
      if (!this.gathering && !this.virtualSession) return

      const date = this.gathering ? this.gathering.endDate : this.virtualSession.endDate

      return moment(date).format('DD MMMM YYYY')
    },
    isCongressEvent() {
      if (!this.gathering) return

      return Boolean(this.gathering.congress)
    },
    subtitle() {
      if (!this.gathering && !this.virtualSession) return

      return this.isVirtual
        ? this.$t('tabs.virtualEventViewBannerSubtitle', {
            beginDate: this.beginDate,
            endDate: this.endDate
          })
        : this.$t('tabs.eventViewBannerSubtitle', {
            beginDate: this.beginDate,
            city: this.gathering.city || this.gathering.location,
            country: this.gathering.country,
            endDate: this.endDate
          })
    },
    showSensibleChanges() {
      const { SUPER_ADMIN, ADMIN, DM, SUPER_DM, CP } = this.$const.userType
      const { userTypes } = this.$store.state.myUser

      if (userTypes.includes(SUPER_ADMIN) || userTypes.includes(ADMIN)) return true

      if (this.isCongressEvent) return [DM, SUPER_DM].some(type => userTypes.includes(type))
      else return userTypes.includes(CP)
    },
    currentParticipant() {
      return (
        this.gathering && this.gathering.participants.find(participant => participant.userUuid === this.user.userUuid)
      )
    }
  },

  apollo: {
    gathering: {
      query: GET_ALL_SETTINGS_GATHERING,
      variables() {
        return {
          gatheringUuid: this.$route.params.gatheringUuid
        }
      },
      skip() {
        return this.isVirtualSession
      }
    },
    virtualSession: {
      query: VIRTUAL_SESSION_CONGREX,
      variables() {
        return {
          virtualSessionUuid: this.$route.params.gatheringUuid
        }
      },
      skip() {
        return !this.isVirtualSession
      }
    }
  },

  methods: {
    ...mapMutations({
      setDialogHcpList: 'SET_DIALOG_HCP_LIST',
      setEventSelected: 'SET_EVENT_SELECTED'
    }),
    openDialogHcpList() {
      this.setDialogHcpList({
        congressUuid: this.event.congress.congressUuid,
        display: true,
        name: this.gathering.name,
        gatheringUuid: this.gathering.gatheringUuid,
        beginDate: this.gathering.beginDate,
        endDate: this.gathering.endDate
      })
    }
  },

  watch: {
    tab(value, oldValue) {
      if (value === oldValue || value === this.$route.query.tab) return

      this.$router.replace({ ...this.$route, query: { ...this.$route.query, tab: value } })
    }
  }
}
</script>

<style lang="scss">
.Tabs {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 13vh;
    color: white;
    background-size: cover;
  }
  .v-tab {
    text-transform: unset !important;
  }
  .fixed-button {
    bottom: 50px !important;
  }
}
</style>
