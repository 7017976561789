<template>
  <div>
    <div class="title">{{ $t`team-project` }}</div>
    <v-divider class="mb-8" />
    <form>
      <v-row class="body-2 grey--text">
        <v-col cols="12">
          <div class="mb-1 black--text">{{ $t`select-concern-bu` }}* :</div>
        </v-col>
        <v-col
          align-content="center"
          class="d-flex py-0"
          :class="{ 'NewEvent-sensibleDataBlock pt-2': sensibleDataFields.includes('businessUnitUuids') }"
          cols="12"
        >
          <v-col v-for="bu in busComputed" :key="bu.businessUnitUuid" cols="3" class="pt-0">
            <v-switch
              v-model="form.gathering.businessUnitUuids"
              :data-e2e="`switch-bu-${bu.label.toLowerCase()}`"
              :value="bu.businessUnitUuid"
              color="success"
              dense
              hide-details
              class="mt-0"
            >
              <template v-slot:label>
                <span
                  class="font-weight-bold"
                  :class="{
                    'success--text': form.gathering.businessUnitUuids.includes(bu.businessUnitUuid),
                    'error--text': errors.has('bus', 'teamProject')
                  }"
                >
                  {{ bu.label }}
                </span>
              </template>
            </v-switch>
          </v-col>
        </v-col>
        <v-col cols="12">
          <span v-if="errors.has('bus', 'teamProject')" class="error--text">
            {{ errors.first('bus', 'teamProject') }}
          </span>
        </v-col>
        <v-col cols="12" md="6">
          <div class="mb-1 black--text">{{ $t`product-owner` }}{{ teamMandatory.includes('CP') ? '*' : '' }}</div>
          <v-select
            v-model="form.gathering.projectManagerUuid"
            :items="cpsComputed"
            v-validate="teamMandatory.includes('CP') && 'required'"
            data-vv-name="productOwner"
            :data-vv-as="$t`product-owner`"
            data-vv-scope="teamProject"
            :error-messages="errors.collect('productOwner', 'teamProject')"
            class="text-capitalize"
            dense
            outlined
            data-e2e="select-project-manager"
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="mb-1 black--text">{{ $t`marketing-owner` }}</div>
          <v-select
            :items="rmsComputed"
            v-model="form.gathering.inChargeMarketingUuid"
            data-vv-name="marketingOwner"
            :data-vv-as="$t`marketing-owner`"
            data-vv-scope="teamProject"
            :error-messages="errors.collect('marketingOwner', 'teamProject')"
            dense
            outlined
            data-e2e="select-in-charge-marketing"
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="mb-1 black--text">{{ $t`medical-owner` }}</div>
          <v-select
            :items="rmclsComputed"
            v-model="form.gathering.inChargeMedicalUuid"
            data-vv-name="medicalOwner"
            :data-vv-as="$t`medical-owner`"
            data-vv-scope="teamProject"
            :error-messages="errors.collect('medicalOwner', 'teamProject')"
            dense
            outlined
            data-e2e="select-in-charge-medical"
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="mb-1 black--text">{{ $t`roc-owner` }}{{ teamMandatory.includes('ROC') ? '*' : '' }}</div>
          <v-select
            :items="rocsComputed"
            v-model="form.gathering.inChargeRocUuid"
            v-validate="teamMandatory.includes('ROC') && 'required'"
            data-vv-name="rocOwner"
            :data-vv-as="$t`roc-owner`"
            data-vv-scope="teamProject"
            :error-messages="errors.collect('rocOwner', 'teamProject')"
            dense
            outlined
            data-e2e="select-in-charge-roc"
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="mb-1 black--text">{{ $t`mci-contact` }}{{ teamMandatory.includes('MCI') ? '*' : '' }}</div>
          <v-select
            :items="mcisComputed"
            v-model="form.gathering.inChargeMciUuid"
            v-validate="teamMandatory.includes('MCI') && 'required'"
            data-vv-name="mciContact"
            :data-vv-as="$t`mci-contact`"
            data-vv-scope="teamProject"
            :error-messages="errors.collect('mciContact', 'teamProject')"
            dense
            outlined
            data-e2e="select-in-charge-mci"
          />
        </v-col>
        <v-col v-if="isCongressEvent" cols="12" md="6">
          <div class="mb-1 black--text">{{ $t`medical-director` }}{{ teamMandatory.includes('DM') ? '*' : '' }}</div>
          <v-select
            :items="dmsComputed"
            v-model="form.congressEvent.medicalDirectorUuid"
            v-validate="teamMandatory.includes('DM') && 'required'"
            data-vv-name="medicalDirector"
            :data-vv-as="$t`medical-director`"
            data-vv-scope="teamProject"
            :error-messages="errors.collect('medicalDirector', 'teamProject')"
            :hint="$t`solicitations-approver`"
            dense
            outlined
            persistent-hint
            data-e2e="select-medical-director"
          />
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import _capitalize from 'lodash/capitalize'
import _orderBy from 'lodash/orderBy'

import { GET_USERS_TYPE } from '@/graphql/User/GetUsers'
import { userType } from '@/const/shared'

export default {
  name: 'NewEventTeamProject',
  inject: ['$validator'],

  model: {
    prop: 'form',
    event: 'change'
  },

  props: {
    form: Object,
    isCongressEvent: Boolean,
    sensibleDataFields: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      cps: null,
      dms: null,
      mcis: null,
      rmcls: null,
      rms: null,
      rocs: null,
      switchs: {}
    }
  },

  computed: {
    busComputed() {
      return _orderBy(this.$store.state.bus, 'label')
    },
    cpsComputed() {
      if (!this.cps) return []

      const cps = [...this.cps]

      if (!this.teamMandatory.includes('CP')) {
        cps.unshift({ text: this.$t('new-event.team-project.no-value'), value: null })
      }

      if (this.cps && !this.dms) return cps

      return [...cps, ...this.dms]
    },
    rmclsComputed() {
      if (!this.rmcls) return []

      const rmcls = [...this.rmcls]

      if (!this.teamMandatory.includes('RMCL')) {
        rmcls.unshift({ text: this.$t('new-event.team-project.no-value'), value: null })
      }

      if (this.rmcls && !this.dms) return rmcls

      return [...rmcls, ...this.dms]
    },
    teamMandatory() {
      if (this.isCongressEvent) {
        if (this.form.gathering.isVirtual) {
          return ['DM', 'ROC']
        }

        return ['DM', 'MCI', 'ROC']
      }

      if (this.form.gathering.isVirtual) {
        return ['CP', 'ROC']
      }

      return ['CP', 'MCI', 'ROC']
    },
    rmsComputed() {
      if (!this.rms) return []

      const rms = [...this.rms]

      if (!this.teamMandatory.includes('RM')) {
        rms.unshift({ text: this.$t('new-event.team-project.no-value'), value: null })
      }

      return rms
    },
    rocsComputed() {
      if (!this.rocs) return []

      const rocs = [...this.rocs]

      if (!this.teamMandatory.includes('ROC')) {
        rocs.unshift({ text: this.$t('new-event.team-project.no-value'), value: null })
      }

      return rocs
    },
    mcisComputed() {
      if (!this.mcis) return []

      const mcis = [...this.mcis]

      if (!this.teamMandatory.includes('MCI')) {
        mcis.unshift({ text: this.$t('new-event.team-project.no-value'), value: null })
      }

      return mcis
    },
    dmsComputed() {
      if (!this.dms) return []

      const dms = [...this.dms]

      if (!this.teamMandatory.includes('DM')) {
        dms.unshift({ text: this.$t('new-event.team-project.no-value'), value: null })
      }

      return dms
    }
  },

  apollo: {
    cps: {
      query: GET_USERS_TYPE,
      variables: { query: { userTypes: [userType.CP], limit: 1000 } },
      update(data) {
        return this.computeUsers(data.searchUsers.rows)
      }
    },
    rmcls: {
      query: GET_USERS_TYPE,
      variables: { query: { userTypes: [userType.RMCL], limit: 1000 } },
      update(data) {
        return this.computeUsers(data.searchUsers.rows)
      }
    },
    rms: {
      query: GET_USERS_TYPE,
      variables: { query: { userTypes: [userType.RM], limit: 1000 } },
      update(data) {
        return this.computeUsers(data.searchUsers.rows)
      }
    },
    rocs: {
      query: GET_USERS_TYPE,
      variables: { query: { userTypes: [userType.ROC], limit: 1000 } },
      update(data) {
        return this.computeUsers(data.searchUsers.rows)
      }
    },
    mcis: {
      query: GET_USERS_TYPE,
      variables: { query: { userTypes: [userType.MCI], limit: 1000 } },
      update(data) {
        return this.computeUsers(data.searchUsers.rows)
      }
    },
    dms: {
      query: GET_USERS_TYPE,
      variables: { query: { userTypes: [userType.DM], limit: 1000 } },
      update(data) {
        return this.computeUsers(data.searchUsers.rows)
      }
    }
  },

  watch: {
    switchs: {
      deep: true,
      handler() {
        const keys = Object.keys(this.switchs)
        const bus = keys.filter(v => this.switchs[v])
        this.$emit('change', { ...this.form, gathering: { ...this.form.gathering, businessUnitUuids: bus } })
      }
    }
  },

  methods: {
    computeUsers(users) {
      return users.map(user => ({
        text: `${_capitalize(user.firstName)} ${_capitalize(user.lastName)}`,
        value: user.userUuid
      }))
    }
  }
}
</script>
