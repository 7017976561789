$<template>
  <div :class="{ 'error--text': errorMode }">
    <div class="black--text">{{ $t`choose-eligibility-criteria` }}*</div>
    <v-container class="py-0">
      <v-row>
        <v-col class="subtitle-2 pb-0">{{ $const.criterias.MSL_TARGET }}</v-col>
      </v-row>
      <v-row>
        <v-col class="flex-grow-0 pt-0 text-no-wrap">
          <v-checkbox
            v-model="form.congressEvent.criterias"
            :label="$t('criteria-values.MSL_TARGET')"
            class="mt-0"
            color="success"
            data-e2e="button-criteria-msl-target"
            hide-details
            value="MSL_TARGET"
          />
        </v-col>
      </v-row>
    </v-container>

    <div v-for="bu in bus" :key="bu.businessUnitUuid">
      <v-container v-if="form.gathering.businessUnitUuids.includes(bu.businessUnitUuid)" class="py-0">
        <v-row>
          <v-col class="subtitle-2 pb-0">{{ bu.label }}</v-col>
        </v-row>
        <v-row>
          <v-col v-for="(target, i) in targetComputed[bu.label]" :key="i" class="flex-grow-0 pt-0">
            <v-checkbox
              v-model="form.congressEvent.criterias"
              :data-e2e="`button-criteria-${_kebabCase(target)}`"
              :label="target.split('_').length > 1 ? target.split('_')[1] : target.split('-').join(' ')"
              :value="target"
              class="mt-0 text-no-wrap"
              color="success"
              hide-details
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import _sortBy from 'lodash/sortBy'
import _difference from 'lodash/difference'
import _kebabCase from 'lodash/kebabCase'

export default {
  name: 'NewEventCriterias',

  model: {
    prop: 'form',
    event: 'change'
  },

  props: {
    errorMode: Boolean,
    form: {
      type: Object,
      required: true
    }
  },

  computed: {
    bus() {
      return _sortBy(this.$store.state.bus, 'label')
    },
    targetComputed() {
      const targets = { ...this.$const.targetBusinnesUnit }

      targets.SEP = [targets.SEP[0], targets.SEP[2], targets.SEP[1], ...targets.SEP.slice(3)]

      return targets
    }
  },

  watch: {
    'form.gathering.businessUnitUuids'(current, prev) {
      if (current.length < prev.length) {
        const [missingBuUuid] = _difference(prev, current)
        const bu = this.$store.state.bus.find(el => el.businessUnitUuid === missingBuUuid)
        const keyValueInverted = {}

        for (const key in this.$const.businessUnitLabel) {
          keyValueInverted[this.$const.businessUnitLabel[key]] = key
        }

        let restValues = []

        if (bu.label === 'Biosimilaires') {
          restValues = this.form.congressEvent.criterias.filter(
            el =>
              el.split('_')[0] !== keyValueInverted[bu.label] &&
              el.split('-')[0] !== keyValueInverted[bu.label] &&
              el.split('-')[0] !== 'DERM' &&
              el.split('-')[0] !== 'IBD' &&
              el.split('-')[0] !== 'RA'
          )
        } else {
          restValues = this.form.congressEvent.criterias.filter(
            el => el.split('_')[0] !== keyValueInverted[bu.label] && el.split('-')[0] !== keyValueInverted[bu.label]
          )
        }

        this.$set(this.form.congressEvent, 'criterias', restValues)
      }
    }
  },

  methods: {
    _kebabCase
  }
}
</script>
