import gql from 'graphql-tag'

export const GET_SPONSORSHIPPROOFS = gql`
  query sponsorshipProofs($gatheringUuid: GUID!) {
    sponsorshipProofs(gatheringUuid: $gatheringUuid) {
      congressUuid
      downloadUrl
      filename
      label
      mimeType
      s3Key
      s3ThumbnailKey
      sponsorshipProofUuid
      thumbnailUrl
      url
      userUuid
      createdAt
      updateAt
    }
  }
`
