<template>
  <div>
    <v-card-title>{{ $t(translationPrefix + '.title') }}</v-card-title>
    <v-card-subtitle>{{ $t(translationPrefix + '.subtitle') }}</v-card-subtitle>
    <v-card-text>
      <div class="d-flex justify-center my-10">
        <div class="mx-4 text-center">
          <div class="mb-2 subtitle-2">{{ $t(translationPrefix + '.initial-quota') }}</div>
          <v-chip x-large outlined class="px-6">{{ doctorContext ? doctorQuota : otherDisciplinesQuota }}</v-chip>
        </div>
        <div class="mx-4 text-center">
          <div class="mb-2 subtitle-2">{{ $t(translationPrefix + '.new-quota') }}</div>
          <v-chip x-large outlined class="px-6" close-icon="px-6" color="success">{{
            doctorContext ? doctorQuota + 1 : otherDisciplinesQuota + 1
          }}</v-chip>
        </div>
      </div>
      <v-textarea
        v-model="localComment"
        @input="$emit('change', $event)"
        auto-grow
        :comment="comment"
        v-cy="$cy.congrex.solicitation.validation.globalQuota.comment"
        :data-vv-as="$t(translationPrefix + '.label-name')"
        data-vv-name="comment"
        :error-messages="errors.collect('comment')"
        :label="$t(translationPrefix + '.label-comment')"
        outlined
        v-validate="'required'"
      />
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn @click="$emit('cancel')" data-e2e="button-cancel-increase" depressed text color="error">{{
        $t('force-solicitation-validation.cancel-button')
      }}</v-btn>
      <v-btn
        @click="confirm"
        v-cy="$cy.congrex.solicitation.validation.globalQuota.confirm"
        depressed
        :disabled="!localComment"
        text
        color="success"
        >{{ $t('force-solicitation-validation.confirm-button') }}</v-btn
      >
    </v-card-actions>
  </div>
</template>

<script>
import { GET_SOLICITATION_QUOTAS } from '@/graphql/Solicitation/Solicitation'

export default {
  name: 'IncreaseGlobalQuota',

  $_veeValidate: {
    validator: 'new'
  },

  model: {
    prop: 'comment',
    event: 'change'
  },

  props: {
    doctorContext: {
      default: true,
      type: Boolean
    },
    solicitationUuid: {
      type: String,
      required: true
    },
    comment: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      localComment: '',
      translationPrefix: this.doctorContext ? 'increase-global-quota' : 'increase-other-quota'
    }
  },

  computed: {
    doctorQuota() {
      const gatheringBusinessUnits = this.$get(this.solicitation, 'congress.gathering.gatheringBusinessUnits', null)

      if (!gatheringBusinessUnits) return 0

      const gatheringBusinessUnit = gatheringBusinessUnits.find(
        gbu => gbu.businessUnitUuid === this.solicitation.businessUnitUuid
      )

      if (!gatheringBusinessUnit) return 0

      return gatheringBusinessUnit.doctorQuota
    },

    otherDisciplinesQuota() {
      const gatheringBusinessUnits = this.$get(this.solicitation, 'congress.gathering.gatheringBusinessUnits', null)

      if (!gatheringBusinessUnits) return 0

      const gatheringBusinessUnit = gatheringBusinessUnits.find(
        gbu => gbu.businessUnitUuid === this.solicitation.businessUnitUuid
      )

      if (!gatheringBusinessUnit) return 0

      return gatheringBusinessUnit.otherDisciplinesQuota
    }
  },

  apollo: {
    solicitation: {
      query: GET_SOLICITATION_QUOTAS,
      variables() {
        return { solicitationUuid: this.solicitationUuid }
      }
    }
  },

  methods: {
    async confirm() {
      if (!(await this.$validator.validateAll())) return

      this.$emit('confirm')
    }
  }
}
</script>
