<template>
  <div>
    <LoaderTab v-if="!solicitation" />
    <CreateSolicitation v-else @change="changed = $event" :changed="changed" :initSolicitation="solicitation" />
  </div>
</template>

<script>
import CreateSolicitation from '@/views/congrex/CreateSolicitation'
import { GET_SOLICITATION } from '@/graphql/Solicitation/Solicitation'
import LoaderTab from '@/components/congrex/tabs/LoaderTab'

export default {
  name: 'UpdateSolicitation',

  components: { LoaderTab, CreateSolicitation },

  data() {
    return {
      changed: false,
      solicitation: null
    }
  },

  apollo: {
    solicitation: {
      query: GET_SOLICITATION,
      variables() {
        return { solicitationUuid: this.$route.query.solicitationUuid }
      }
    }
  },

  mounted() {
    window.addEventListener('beforeunload', event => {
      if (!this.changed) return
      event.preventDefault()
      event.returnValue = ''
    })
  },

  beforeRouteLeave(to, from, next) {
    if (!this.changed) {
      next()
      return
    }

    // eslint-disable-next-line no-alert
    const answer = window.confirm(this.$t('updateSolicitation.confirmExitPage'))
    if (answer) {
      next()
    } else {
      next(false)
    }
  }
}
</script>
