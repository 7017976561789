import gql from 'graphql-tag'

export const GET_ID_OSEUS_EVENTS = gql`
  query oseusEvents($startAfter: DateTime) {
    oseusEvents(startAfter: $startAfter) {
      oseusEventId
      title
      meetings {
        oseusMeetingId
        startDate
        theme
      }
    }
  }
`
