var templateObject$2 = Object.freeze(["search"]);
var templateObject$1 = Object.freeze(["presence-list"]);
var templateObject = Object.freeze(["guests-list"]);
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Participants"},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t(templateObject))+" "),_c('v-spacer'),(!_vm.isVirtual && _vm.$store.getters.hasRight(_vm.$const.right.signPresence))?_c('v-btn',{staticClass:"mr-7 text-none",attrs:{"to":{ name: 'PresenceList', params: { gatheringUuid: _vm.$route.params.gatheringUuid } },"color":"congrex-primary","dark":"","small":"","depressed":""}},[_vm._v(" "+_vm._s(_vm.$t(templateObject$1))+" ")]):_vm._e(),_c('v-text-field',{staticClass:"search-field",attrs:{"clearable":"","label":_vm.$t(templateObject$2),"placeholder":_vm.$t('logisticPage.searchFieldPlaceholder'),"append-icon":"mdi-magnify","hide-details":"","single-line":""},model:{value:(_vm.searchLastName),callback:function ($$v) {_vm.searchLastName=$$v},expression:"searchLastName"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.searchLastName},scopedSlots:_vm._u([{key:"item.transport",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[(typeof value === 'object')?_c('v-icon',{attrs:{"color":value.color}},[_vm._v(" "+_vm._s(value.icon)+" ")]):_c('mybb-text',[_vm._v(" "+_vm._s(value)+" ")])],1):_vm._e()]}},{key:"item.hosting",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[(typeof value === 'object')?_c('v-icon',{attrs:{"color":value.color}},[_vm._v(" "+_vm._s(value.icon)+" ")]):_c('mybb-text',[_vm._v(" "+_vm._s(value)+" ")])],1):_vm._e()]}},{key:"item.invitationStatus",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":value.color}},[_vm._v(" "+_vm._s(value.icon)+" ")]):_vm._e()]}},{key:"item.presence",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":value.color}},[_vm._v(" "+_vm._s(value.icon)+" ")]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }